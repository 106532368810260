import { THEME_COLOR } from 'constant';
import { HistoryContainer } from 'containers';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { MainLayout } from 'layouts';

export default function HistoryPage() {
  usePWATemplateColor(THEME_COLOR.WHITE);

  return (
    <MainLayout isHideHeader isHideBottomSpace>
      <HistoryContainer />
    </MainLayout>
  );
}
