import React from 'react';
import styles from './styles.module.scss';
import { IoChevronForward } from 'react-icons/io5';
import { Image, Skeleton } from 'antd-mobile';
import { showData } from 'utils';

type Props = {
  cardIcon?: string;
  cardNumber?: string;
  isSkeleton?: boolean;
  isDefault?: boolean;
  onClick?: () => void;
};

function CreditCard({
  cardIcon,
  cardNumber,
  isSkeleton = false,
  isDefault = false,
  onClick,
}: Props) {
  return (
    <div className={styles.cardWrapper} onClick={onClick}>
      {isSkeleton ? (
        <Skeleton
          animated
          style={{
            height: '28px',
          }}
        />
      ) : (
        <>
          <Image className={styles.iconCard} src={cardIcon} />
          <p className="font-sm font-medium color-dark-black">{showData(cardNumber)}</p>
          <IoChevronForward
            className={styles.icon}
            color="var(--primary-text-color)"
            fontSize="20px"
          />
          {isDefault ? <div className={styles.indicator} /> : null}
        </>
      )}
    </div>
  );
}

export default React.memo(CreditCard);
