import { Modal } from 'antd-mobile';
import styles from './styles.module.scss';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { showData } from 'utils';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  confirmTitle?: string;
  confirmContent?: string;
};

function ConfirmModal({
  isOpen,
  onClose,
  onConfirm,
  confirmTitle = '',
  confirmContent = '',
}: Props) {
  const { t } = useTranslation();

  const openModal = () => {
    Modal.confirm({
      className: styles.modal,
      header: (
        <>
          <p className="font-xl color-black font-bold">{showData(confirmTitle)}</p>
        </>
      ),
      confirmText: t('button.yes'),
      cancelText: t('button.no'),
      closeOnMaskClick: true,
      afterClose: onClose,
      onConfirm: onConfirm,
      maskStyle: { background: 'rgba(0, 159, 194, 0.20)' },
      bodyStyle: {
        boxShadow: 'var(--shadow-01)',
      },
      onClose: onClose,
      content: (
        <>
          <p
            className="font-sm color-black font-light"
            style={{
              textAlign: 'center',
            }}
          >
            {showData(confirmContent)}
          </p>
        </>
      ),
    });
  };

  useEffect(() => {
    if (isOpen) {
      openModal();
    }
  }, [isOpen]);

  return <></>;
}

export default React.memo(ConfirmModal);
