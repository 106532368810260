import { useEffect, useRef, useState } from 'react';
import { GeneralService, UserService } from 'services';
import { BookingHistoryType, BookingInfoType, ClubType } from 'types';
import { delayNavigate, isSuccessCode, isVN } from 'utils';
import styles from './styles.module.scss';
import {
  BottomPanel,
  EmptyCard,
  HistoryBookingCard,
  PTBookingCard,
  SecondaryButton,
  TimePicker,
} from 'components';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from 'hooks';
import moment from 'moment';
import ConfirmModal from 'components/modal/ConfirmModal';
import Selection from 'components/input/Selection';
import LoadingDot from 'components/shared/LoadingDot';
import { FloatingPanelRef } from 'antd-mobile';

type Props = {};

export default function BookingTab({}: Props) {
  const { t } = useTranslation();
  const userService = new UserService();
  const generalService = new GeneralService();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: isOpenConfirm, onClose: onCloseConfirm, onOpen: onOpenConfirm } = useDisclosure();
  const { isOpen: isOpenHistory, onClose: onCloseHistory, onOpen: onOpenHistory } = useDisclosure();
  const [listClub, setListClub] = useState<ClubType[]>([]);
  const [selectedClubId, setSelectedClubId] = useState<null | number>(null);
  const [bookingInfo, setBookingInfo] = useState<BookingInfoType>();
  const [time, setTime] = useState({
    hour: '00',
    minute: '00',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingInfo, setIsLoadingInfo] = useState(false);
  const [isLoadingBooking, setIsLoadingBooking] = useState(false);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [rangeTime, setRangeTime] = useState({ from: '', to: '' });
  const [bookingHistory, setBookingHistory] = useState<BookingHistoryType[]>([]);
  const bookingIdRef = useRef('');
  const panelHeight = (window.innerHeight - 45 - 54) * 0.7;
  const ref = useRef<FloatingPanelRef>();

  const getClubsHaveContract = async () => {
    setIsLoading(true);
    const response = await generalService.getClubsHavePtContract();
    const { code, data } = response;
    if (isSuccessCode(code)) {
      setListClub(data);
      if (data?.length > 0) {
        setSelectedClubId(data[0]?.id);
      }
    }
    delayNavigate(() => setIsLoading(false));
  };

  const getBookingInfo = async (clubId: number) => {
    setIsLoadingInfo(true);
    const response = await userService.getNumberOfPtSession(clubId);
    const { code, data } = response;
    if (isSuccessCode(code)) {
      setBookingInfo(data);
    }
    delayNavigate(() => setIsLoadingInfo(false));
  };

  const getListBookingHistory = async () => {
    if (selectedClubId) {
      setIsLoadingHistory(true);
      const response = await userService.getListBookingPTHistory({
        clubId: String(selectedClubId),
      });
      const { code, data } = response;
      if (isSuccessCode(code)) {
        setBookingHistory(data);
      }
      delayNavigate(() => setIsLoadingHistory(false));
    }
  };

  const handleChangeTime = (time: { hour: string; minute: string }) => {
    setTime(time);
  };

  const handleBooking = async () => {
    if (bookingInfo?.currentPtContract) {
      setIsLoadingBooking(true);
      const startDate = moment()
        .set('hour', Number(time.hour))
        .set('minute', Number(time.minute))
        .toISOString();
      const response = await userService.bookingPTContract({
        ptContractId: bookingInfo?.currentPtContract?.id,
        startDate: startDate,
      });
      const { code } = response;
      if (isSuccessCode(code)) {
        await getListBookingHistory();
        onClose();
      }
      delayNavigate(() => setIsLoadingBooking(false));
    }
  };

  const openTimePicker = () => {
    const from = moment().add(5, 'minute');
    const to = moment().add(14, 'minute');
    setRangeTime({
      from: from.format('HH:mm'),
      to: to.format('HH:mm'),
    });
    setTime({
      hour: from.get('hour').toString().padStart(2, '0'),
      minute: from.get('minute').toString().padStart(2, '0'),
    });
    onOpen();
  };

  const openCancelConfirm = (id: string) => () => {
    bookingIdRef.current = id;
    onOpenConfirm();
  };

  const handleRating = (id: string) => async (rate: number) => {
    const response = await userService.handleRating({
      bookingId: id,
      rating: rate,
    });
    const { code } = response;
    if (isSuccessCode(code)) {
      await getListBookingHistory();
    }
  };

  const handleCancelBooking = async () => {
    const response = await userService.cancelBooking({ bookingId: bookingIdRef.current });
    const { code } = response;
    if (isSuccessCode(code)) {
      await getListBookingHistory();
    }
  };

  const handleChangeContract = (clubId: number | null) => {
    setSelectedClubId(clubId);
  };

  const handleOpenBottom = () => {
    ref.current && ref.current.setHeight(panelHeight);
  };

  const handleCloseBottom = () => {
    ref.current && ref.current.setHeight(0);
  };

  useEffect(() => {
    if (isOpenHistory) {
      handleOpenBottom();
    } else {
      handleCloseBottom();
    }
  }, [isOpenHistory]);

  useEffect(() => {
    getListBookingHistory();
    if (selectedClubId) getBookingInfo(selectedClubId);
  }, [selectedClubId]);

  useEffect(() => {
    getClubsHaveContract();
  }, []);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <LoadingDot />
      ) : listClub.length === 0 ? (
        <EmptyCard text={t('paragraph.no-pt-contract')} />
      ) : (
        <>
          <div className={styles.controller}>
            <Selection
              options={(listClub || [])?.map((club) => ({
                id: Number(club?.id),
                title: String(isVN() ? club?.nameVi : club?.nameEn),
              }))}
              selectedId={selectedClubId}
              onChangeCity={handleChangeContract}
              clearable={false}
              iconUrl="/images/icon/building.svg"
            />
          </div>
          <div className={styles.body}>
            <div className={styles.bookingContent}>
              <PTBookingCard
                isSkeleton={isLoadingInfo}
                imageUrl={bookingInfo?.currentPtContract?.ptInfo?.accountDetail?.avatar}
                name={[
                  bookingInfo?.currentPtContract?.ptInfo?.accountDetail?.firstName,
                  bookingInfo?.currentPtContract?.ptInfo?.accountDetail?.lastName,
                ].join(' ')}
                numberSessionLeft={bookingInfo?.totalSessions}
                onBooking={openTimePicker}
              />
            </div>

            <div className={styles.history}>
              <SecondaryButton
                text={t('title.history')}
                variant="high-light-blue"
                style={{
                  width: '100%',
                }}
                onClick={onOpenHistory}
              />
            </div>
          </div>

          <BottomPanel refPanel={ref} anchors={[0, panelHeight]} onClose={onCloseHistory}>
            <div className={styles.historyList}>
              {isLoadingHistory ? (
                <LoadingDot />
              ) : bookingHistory.length > 0 ? (
                bookingHistory?.map((history) => {
                  return (
                    <HistoryBookingCard
                      key={history.id}
                      startTime={history.startDate}
                      endTime={history.endDate}
                      date={history.startDate}
                      status={history.status}
                      rating={history.rating}
                      onDelete={openCancelConfirm(String(history.id))}
                      onRate={handleRating(String(history.id))}
                    />
                  );
                })
              ) : (
                <EmptyCard text={t('paragraph.no-booking-yet')} />
              )}
            </div>
          </BottomPanel>

          <TimePicker
            value={time}
            isOpen={isOpen}
            onClose={onClose}
            onChange={handleChangeTime}
            onConfirm={handleBooking}
            isLoading={isLoadingBooking}
            note={t('title.booking-time-from-to', { from: rangeTime.from, to: rangeTime.to })}
          />
          <ConfirmModal
            isOpen={isOpenConfirm}
            onClose={onCloseConfirm}
            onConfirm={handleCancelBooking}
            confirmTitle={t('title.confirm')}
            confirmContent={t('paragraph.cancel-booking-confirm')}
          />
        </>
      )}
    </div>
  );
}
