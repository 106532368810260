import { useState } from 'react';
import styles from './styles.module.scss';
import { TabWrapper } from 'components';
import { useTranslation } from 'react-i18next';
import { LocalStorage, resetScroll, STORAGE_KEY } from 'utils';
import BookingTab from './BookingTab';
import ExperienceTab from './ExperienceTab';

type Props = {};

const TABS = {
  BOOKING: 'BOOKING',
  EXPERIENCE: 'EXPERIENCE',
};

const Storage = new LocalStorage();

export function PTBookingContainer({}: Props) {
  const previousTab = Storage.getStorageItem(STORAGE_KEY.PT_TAB);
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<string | number>(
    previousTab ? previousTab : TABS.BOOKING,
  );
  const TAB_ITEMS = [
    {
      key: TABS.BOOKING,
      title: t('title.booking'),
    },
    {
      key: TABS.EXPERIENCE,
      title: t('title.experience'),
    },
  ];

  const handleChangeTab = (key: string | number) => {
    setSelectedTab(key);
    Storage.setStorageItem(STORAGE_KEY.PT_TAB, key);
    resetScroll();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <TabWrapper activeKey={selectedTab} items={TAB_ITEMS} onChangeTab={handleChangeTab} />
      </div>
      <div className={styles.content}>
        {selectedTab === TABS.BOOKING && <BookingTab />}
        {selectedTab === TABS.EXPERIENCE && <ExperienceTab />}
      </div>
    </div>
  );
}
