import React from 'react';
import styles from './styles.module.scss';
import { Image, Skeleton } from 'antd-mobile';
import { formatPrice, showData } from 'utils';

type Props = {
  createdDate?: string;
  createdTime?: string;
  name?: string;
  price?: number;
  isSuccess?: boolean;
  onClick?: () => void;
  isSkeleton?: boolean;
};

function TransactionHistoryCard({
  createdDate,
  createdTime,
  name,
  price = 0,
  isSuccess = false,
  isSkeleton = false,
  onClick,
}: Props) {
  return (
    <div className={styles.cardWrapper} onClick={onClick}>
      {isSkeleton ? (
        <Skeleton className={styles.icon} animated />
      ) : (
        <div className={styles.icon}>
          <Image
            src={isSuccess ? '/images/icon/tick-circle.svg' : '/images/icon/circle-menu.svg'}
          />
        </div>
      )}
      {isSkeleton ? (
        <Skeleton
          animated
          style={{
            width: '100%',
            height: 'var(--font-sm)',
          }}
        />
      ) : (
        <div className={styles.time}>
          <p className="font-sm color-black font-light">{showData(createdDate)}</p>
          <p className="font-sm color-black font-light">{showData(createdTime)}</p>
        </div>
      )}
      {isSkeleton ? (
        <Skeleton
          animated
          style={{
            width: '100%',
            height: 'var(--font-md)',
          }}
        />
      ) : (
        <div className={styles.cardInfo}>
          <p
            className="font-md color-black font-bold"
            style={{
              textAlign: 'left',
            }}
          >
            {showData(name)}
          </p>
          <p
            className="font-md color-black font-bold"
            style={{
              textAlign: 'right',
            }}
          >
            {formatPrice(price, true)}
          </p>
        </div>
      )}
    </div>
  );
}

export default React.memo(TransactionHistoryCard);
