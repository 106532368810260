import { Mask } from 'antd-mobile';
import React from 'react';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  videoPath?: string;
};

function VideoModal({ isOpen = false, onClose, videoPath = '' }: Props) {
  return (
    <Mask
      visible={isOpen}
      onMaskClick={onClose}
      destroyOnClose
      disableBodyScroll
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0, 159, 194, 0.20)',
        zIndex: 1000,
      }}
    >
      <video
        width={window.innerWidth}
        height={window.innerWidth * (9 / 16)}
        controls
        autoPlay
        style={{
          background: 'var(--black-color)',
        }}
      >
        <source src={videoPath} type="video/mp4" />
      </video>
    </Mask>
  );
}

export default React.memo(VideoModal);
