/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { BottomSpace, PaymentMethodSelector, SecondaryButton, SvgIcon } from 'components';
import { Skeleton } from 'antd-mobile';
import GlobalService from 'services/global.service';
import { useLocation, useParams } from 'react-router';
import {
  JOI,
  LocalStorage,
  STORAGE_KEY,
  delayNavigate,
  formatPrice,
  generateClubName,
  isSuccessCode,
  isVN,
  showData,
} from 'utils';
import { PaymentPlanType } from 'types/global.type';
import { PLAN_NAME_MAPPING, ROUTES } from 'constant';
import { GeneralService, UserService } from 'services';
import {
  ClubType,
  ContractType,
  CouponType,
  CreateMemberContractReq,
  DetailCouponType,
  ReferralInfoType,
  VN_PAY_RESPONSE,
} from 'types';
import { isNull } from 'lodash';
import PromotionService from 'services/promotion.service';
import { useDispatch } from 'stores';
import { useDirection, useDisclosure, useFormData, useValidation } from 'hooks';
import { setLoadingToast } from 'stores/common';
import { useTranslation } from 'react-i18next';
import { CARD_TYPE, E_PAYMENT_METHOD, PAYMENT_GROUP, PAYMENT_PLAN_TYPE } from 'enum';
import ApplyCodeInput from 'components/input/ApplyCodeInput';
import PaymentService from 'services/payment.service';
import { SavedCardType } from 'types/payment.type';
import queryString from 'query-string';
import ConfirmModal from 'components/modal/ConfirmModal';

const Storage = new LocalStorage();

type ReferralForm = {
  phoneNumber: string;
};

const initReferralForm: ReferralForm = {
  phoneNumber: '',
};

type PromotionForm = {
  promotionCode: string;
};

export function PaymentMembershipContainer() {
  const appliedPromoCode = Storage.getStorageItem(STORAGE_KEY.SAVED_COUPON) ?? '';
  const paymentService = new PaymentService();
  const globalService = new GlobalService();
  const generalService = new GeneralService();
  const userService = new UserService();
  const promotionService = new PromotionService();
  const { paymentPlanId, clubId } = useParams();
  const { t } = useTranslation();
  const { isOpen, onToggle } = useDisclosure(true);
  const { isOpen: isOpenConfirm, onClose: onCloseConfirm, onOpen: onOpenConfirm } = useDisclosure();
  const { goTo } = useDirection();
  const [isLoading, setIsLoading] = useState({
    planDetail: false,
    confirmButton: false,
    listCoupon: true,
  });
  const [selectedMethodId, setSelectedMethodId] = useState(E_PAYMENT_METHOD.QR);
  const [selectedCardId, setSelectedCardId] = useState<number | null>(null);
  const [listSaveCard, setListSavedCard] = useState<SavedCardType[]>([]);
  const [planDetail, setPlanDetail] = useState<PaymentPlanType>();
  const [clubDetail, setClubDetail] = useState<ClubType>();
  const [couponDetail, setCouponDetail] = useState<DetailCouponType | null>(null);
  const [listCoupon, setListCoupon] = useState<CouponType[]>([]);
  const [referralInfo, setReferralInfo] = useState<ReferralInfoType | null>(null);
  const [referralExtendError, setReferralExtendError] = useState('');
  const [promotionExtendError, setPromotionExtendError] = useState('');
  const { formData, onChangeForm, KEY } = useFormData<ReferralForm>(initReferralForm);
  const {
    formData: promotionFormData,
    onChangeForm: onChangePromotionForm,
    KEY: PROMOTION_KEY,
  } = useFormData<PromotionForm>({
    promotionCode: appliedPromoCode,
  });
  const { onValidate, errorMessage } = useValidation<ReferralForm>({
    phoneNumber: JOI.REFERRAL_CODE,
  });
  const [isReferral, setIsReferral] = useState<boolean | null>(null);
  const [isReferralComplete, setIsReferralComplete] = useState<boolean>(false);
  const [isPromotion, setIsPromotion] = useState<boolean | null>(null);
  const [isPromotionComplete, setIsPromotionComplete] = useState<boolean>(false);
  const dispatch = useDispatch();
  const referralRef = useRef('');
  const promotionRef = useRef('');
  const { search } = useLocation();

  const transactionStatus = useMemo(() => {
    const data = queryString.parse(search) as VN_PAY_RESPONSE;

    return data;
  }, [search]);

  const getExistCard = async () => {
    const response = await paymentService.getExistCards();
    const { data, code } = response;

    if (isSuccessCode(code)) {
      const listCard = data as SavedCardType[];
      setListSavedCard(data);
      if (listCard.length > 0) {
        const defaultCard = listCard.find((card) => card.isDefault);
        setSelectedMethodId(E_PAYMENT_METHOD.CARD);
        setSelectedCardId(Number(defaultCard ? defaultCard.id : listCard[0].id));
      }
    }
  };

  const getClubDetail = async () => {
    const response = await generalService.getClubDetail({
      clubId: clubId,
    });
    const { data, code } = response;

    if (isSuccessCode(code)) {
      setClubDetail(data);
    }
  };

  const getPaymentPlanDetail = async () => {
    setIsLoading((prev) => ({ ...prev, planDetail: true }));

    const response = await globalService.getPaymentPlanDetail({
      paymentPlanId: String(paymentPlanId),
      clubId: String(clubId),
    });
    const { data, code } = response;

    if (isSuccessCode(code)) {
      setPlanDetail(data);
    }
    delayNavigate(() => setIsLoading((prev) => ({ ...prev, planDetail: false })));
  };

  const getListCoupons = async () => {
    setIsLoading((prev) => ({ ...prev, listCoupon: true }));
    const response = await promotionService.getListCoupons({
      paymentPlanId: String(paymentPlanId),
    });
    const { code, data } = response;
    if (isSuccessCode(code)) {
      setListCoupon(data);
      if (data?.length > 0 && !appliedPromoCode && planDetail?.type !== PAYMENT_PLAN_TYPE.BASIC) {
        const publicPromotion = data?.filter((promotion: CouponType) => !promotion.isPrivate);
        if (publicPromotion.length > 0)
          onChangePromotionForm(PROMOTION_KEY.PROMOTION_CODE)(publicPromotion[0]?.code);
      }
    }
    setIsLoading((prev) => ({ ...prev, listCoupon: false }));
  };

  const onCheckReferral = async () => {
    const isError = onValidate(formData);
    if (!isError && formData.phoneNumber) {
      if (formData.phoneNumber != referralRef.current) {
        dispatch(setLoadingToast(true));
        const response = await userService.checkReferral({
          phoneNumber: formData.phoneNumber,
        });
        const { code, data } = response;
        if (isSuccessCode(code)) {
          setReferralInfo(data);
          setIsReferral(true);
          setReferralExtendError('');
        } else {
          setReferralInfo(null);
          setIsReferral(false);
          setReferralExtendError(`error-message-code.${code}`);
        }
        setIsReferralComplete(true);
      }
    } else {
      setReferralInfo(null);
      setIsReferral(null);
    }
    referralRef.current = formData.phoneNumber;
    dispatch(setLoadingToast(false));
  };

  const onCheckPromotion = async () => {
    dispatch(setLoadingToast(true));
    if (
      promotionFormData.promotionCode &&
      promotionFormData.promotionCode != promotionRef.current
    ) {
      const existPromo = listCoupon.find(
        (coupon) => coupon.code === promotionFormData.promotionCode,
      );
      if (existPromo) {
        const response = await promotionService.getCouponDetail({
          couponId: existPromo?.id,
          paymentPlanId: String(paymentPlanId),
          clubId: String(clubId),
        });
        const { code, data } = response;
        if (isSuccessCode(code)) {
          setCouponDetail(data);
          setIsPromotion(true);
          setPromotionExtendError('');
        } else {
          setCouponDetail(null);
          setIsPromotion(false);
          setPromotionExtendError(`error-message-code.${code}`);
        }
      } else {
        setCouponDetail(null);
        setIsPromotion(false);
        setPromotionExtendError(`validate-message.promo-code-invalid`);
      }
      setIsPromotionComplete(true);
    }
    dispatch(setLoadingToast(false));
    promotionRef.current = promotionFormData.promotionCode;
  };

  const handleRemoveReferral = () => {
    setIsReferralComplete(false);
    setIsReferral(null);
    setReferralInfo(null);
    onChangeForm(KEY.PHONE_NUMBER)('');
    setReferralExtendError('');
    referralRef.current = '';
  };

  const handleRemovePromotion = () => {
    setIsPromotionComplete(false);
    setIsPromotion(null);
    setCouponDetail(null);
    onChangePromotionForm(PROMOTION_KEY.PROMOTION_CODE)('');
    setPromotionExtendError('');
    promotionRef.current = '';
    Storage.clearStorageItem(STORAGE_KEY.SAVED_COUPON);
  };

  const handleChangeMethodId = (methodId: number) => {
    setSelectedMethodId(methodId);
  };

  const handleChangeCardId = (cardId: number | null) => {
    setSelectedCardId(cardId);
  };

  const handleAddCard = async () => {
    const currentURl = [window.location.origin, window.location.pathname].join('');

    const response = await paymentService.addVNPayAddCardUrl({
      locale: isVN() ? 'vn' : 'en',
      cancelUrl: `${currentURl}?isVNPayResponse=0`,
      cardType: CARD_TYPE.INTERNATIONAL,
      returnUrl: `${currentURl}?isVNPayResponse=1`,
    });
    const { data, code } = response;
    if (isSuccessCode(code)) {
      window.location.href = data?.vnpayUrl;
    }
  };

  const createMemberContract = async () => {
    const reqData: CreateMemberContractReq = {
      paymentMethodGroupId: PAYMENT_GROUP.PAY_IN_COUNTER,
      paymentPlanId: paymentPlanId,
      referralCode: !isNull(referralInfo) ? formData.phoneNumber : undefined,
      couponId: !isNull(couponDetail) ? couponDetail.couponId : undefined,
    };
    setIsLoading((prev) => ({ ...prev, confirmButton: true }));
    const response = await userService.createMemberContract(reqData);
    const { code, data } = response;
    if (isSuccessCode(code)) {
      const contractInfo = data as ContractType;
      if (selectedMethodId === E_PAYMENT_METHOD.CASH) {
        delayNavigate(() => {
          goTo(ROUTES.MEMBERSHIP_PAYMENT_RESULT.replace(':contractId', String(contractInfo.id)))();
        });
      } else if (selectedMethodId === E_PAYMENT_METHOD.CARD) {
        await paymentByCard(data);
      } else if (selectedMethodId === E_PAYMENT_METHOD.QR) {
        await paymentByQR(data);
      }
    }
    setIsLoading((prev) => ({ ...prev, confirmButton: false }));
  };

  const paymentByCard = async (contractInfo: ContractType) => {
    const currentURl = [window.location.origin, window.location.pathname].join('');
    const contractId = contractInfo.id;
    const response = await paymentService.payWithVNPayCard({
      contractId: String(contractId),
      locale: isVN() ? 'vn' : 'en',
      cancelUrl: currentURl,
      returnUrl: [
        window.location.origin,
        ROUTES.MEMBERSHIP_PAYMENT_RESULT.replace(':contractId', String(contractId)),
      ].join(''),
      vnpayTokenId: String(selectedCardId),
    });
    const { code, data } = response;
    if (isSuccessCode(code)) {
      window.location.href = data?.vnpayPaymentUrl;
    }
  };

  const paymentByQR = async (contractInfo: ContractType) => {
    const currentURl = [window.location.origin, window.location.pathname].join('');
    const contractId = contractInfo.id;
    const response = await paymentService.payWithPayOS({
      contractId: String(contractId),
      cancelUrl: currentURl,
      returnUrl: [
        window.location.origin,
        ROUTES.MEMBERSHIP_PAYMENT_RESULT.replace(':contractId', String(contractId)),
      ].join(''),
    });
    const { code, data } = response;
    if (isSuccessCode(code)) {
      window.location.href = data?.checkoutUrl;
    }
  };

  const discountAmount = useMemo(() => {
    if (!couponDetail) return null;

    return (
      Number(couponDetail.afterDiscountJoiningFee) + Number(couponDetail.afterDiscountMemberShip)
    );
  }, [couponDetail]);

  const totalPayment = useMemo(() => {
    return (
      Number(planDetail?.totalPrice) -
      Number(couponDetail?.afterDiscountJoiningFee || 0) -
      Number(couponDetail?.afterDiscountMemberShip || 0)
    );
  }, [planDetail?.totalPrice, couponDetail]);

  useEffect(() => {
    getPaymentPlanDetail();
    getClubDetail();
  }, []);

  useEffect(() => {
    if (planDetail) getListCoupons();
  }, [planDetail]);

  useEffect(() => {
    if ((appliedPromoCode || promotionFormData.promotionCode) && !isLoading.listCoupon) {
      onCheckPromotion();
    }
  }, [isLoading.listCoupon]);

  useEffect(() => {
    if (transactionStatus.isVNPayResponse === '1') {
      setTimeout(async () => {
        await getExistCard();
        setSelectedMethodId(E_PAYMENT_METHOD.CARD);
      }, 500);
    } else {
      getExistCard();
    }
  }, [transactionStatus]);

  return (
    <div className={styles.wrapper}>
      <div
        className="container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <p className={`color-dark-black font-bold font-lg ${styles.title}`}>
          {t('title.apply-promo-referral')}
        </p>
        <div className={styles.blockCode}>
          <ApplyCodeInput
            label={t('title.promo-code')}
            value={promotionFormData.promotionCode}
            isComplete={isPromotionComplete}
            successMessage={isPromotion ? 'validate-message.success-promotion-code' : ''}
            apiErrorMessage={promotionExtendError}
            onChangeValue={onChangePromotionForm(PROMOTION_KEY.PROMOTION_CODE)}
            onApply={onCheckPromotion}
            onRemove={handleRemovePromotion}
          />
          <ApplyCodeInput
            value={formData.phoneNumber}
            label={t('title.referral-code')}
            type="tel"
            isComplete={isReferralComplete}
            errorMessage={
              errorMessage.phoneNumber && `validate-message.${errorMessage.phoneNumber}`
            }
            apiErrorMessage={referralExtendError}
            successMessage={isReferral ? 'validate-message.success-referral-code' : ''}
            onApply={onCheckReferral}
            onChangeValue={onChangeForm(KEY.PHONE_NUMBER)}
            onRemove={handleRemoveReferral}
          />
        </div>
      </div>

      <div
        className="container"
        style={{
          marginTop: '20px',
        }}
      >
        <p className={`color-dark-black font-bold font-lg ${styles.title}`}>
          {t('title.payment-summary')}
        </p>
      </div>
      <div className={styles.paymentInfo}>
        <div className={styles.blockInfo}>
          {isLoading.planDetail ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-md)',
              }}
            />
          ) : (
            <>
              <p className="font-md font-light color-black">{t('title.club')}</p>
              <p className="font-md font-medium color-dark-black">
                {planDetail?.isBlackCard
                  ? t('title.all-club')
                  : `${generateClubName(isVN() ? clubDetail?.nameVi : clubDetail?.nameEn)}`}
              </p>
            </>
          )}
        </div>
        <div className={styles.blockInfo}>
          {isLoading.planDetail ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-md)',
              }}
            />
          ) : (
            <>
              <p className="font-md font-light color-black">{t('title.membership')}</p>
              <p className="font-md font-medium color-dark-black">
                {showData(
                  PLAN_NAME_MAPPING[planDetail?.type as keyof typeof PLAN_NAME_MAPPING] ||
                    (isVN() ? planDetail?.nameVi : planDetail?.nameEn),
                )}
              </p>
            </>
          )}
        </div>

        <div
          style={{
            maxHeight: isOpen ? '300px' : '0px',
            overflow: 'hidden',
            transition: '0.2s linear',
          }}
        >
          <div className={styles.blockInfo}>
            {isLoading.planDetail ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-md)',
                }}
              />
            ) : (
              <>
                <p className="font-md font-light color-black">
                  {planDetail?.duration?.value} {t(`title.${planDetail?.duration?.unit}`)}
                </p>
                <p className="font-md font-medium color-dark-black">
                  {formatPrice(planDetail?.membershipFee || 0)}
                </p>
              </>
            )}
          </div>
          <div className={styles.blockInfo}>
            {isLoading.planDetail ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-md)',
                }}
              />
            ) : (
              <>
                <p className="font-md font-light color-black">{t('title.start-up-fee')}</p>
                <p className="font-md font-medium color-dark-black">
                  {planDetail?.joiningFee == 0
                    ? t('title.free')
                    : formatPrice(planDetail?.joiningFee || 0)}
                </p>
              </>
            )}
          </div>
          {!isNull(discountAmount) && (
            <div className={styles.blockInfo}>
              <p className="font-md font-light color-black">{t('title.promo-code')}</p>
              <p className="font-md font-medium color-orange">
                {!discountAmount ? Number(discountAmount | 0) : `-${formatPrice(discountAmount)}`}
              </p>
            </div>
          )}

          {referralInfo && (
            <div className={styles.blockInfo}>
              <p className="font-md font-light color-black">{t('title.referral-code')}</p>
              <p className="font-md font-medium color-green">
                + {referralInfo?.giftPaymentPlan?.duration?.value}{' '}
                {t(`title.${referralInfo?.giftPaymentPlan?.duration?.unit}`)}
              </p>
            </div>
          )}
        </div>

        <div className={styles.blockInfo}>
          {isLoading.planDetail ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-xxl)',
              }}
            />
          ) : (
            <>
              <p
                className="font-md font-light color-black"
                style={{
                  textTransform: 'uppercase',
                }}
              >
                {t('title.total')}
              </p>
              <p className="font-xxl font-bold color-dark-black">
                {formatPrice(totalPayment || 0)}
              </p>
            </>
          )}
        </div>
        <div className={styles.moreDetail} onClick={onToggle}>
          <p className="color-dark-black font-md font-medium">
            {isOpen ? t('title.hide-details') : t('title.see-details')}
          </p>
          <div
            className={styles.icon}
            style={{
              transform: `rotate(${isOpen ? 180 : 0}deg)`,
              transition: '0.2s linear',
            }}
          >
            <SvgIcon pathFill="var(--primary-text-color)" src="/images/icon/chevron-down.svg" />
          </div>
        </div>
      </div>

      <div className="container">
        <p
          className={`font-sm font-light ${styles.title}`}
          style={{
            margin: '24px 0',
          }}
        >
          {t('paragraph.pr37')}
          <span className="custom-link" onClick={goTo(ROUTES.ACCOUNT_TERM_CONDITION)}>
            {t('paragraph.pr38')}
          </span>
        </p>
        <p className={`color-dark-black font-bold font-lg ${styles.title}`}>
          {t('title.select-payment-method')}
        </p>
        <div className={styles.paymentSelector}>
          <PaymentMethodSelector
            listCard={listSaveCard}
            selectedMethodId={selectedMethodId}
            selectedCardId={selectedCardId}
            onAddNewCard={handleAddCard}
            onChangePaymentMethod={handleChangeMethodId}
            onChangeCard={handleChangeCardId}
          />
        </div>
      </div>
      <div className={styles.controller}>
        <SecondaryButton
          text={t('button.back')}
          variant="outline"
          onClick={goTo(ROUTES.MEMBERSHIP_SELECT.replace(':clubId', String(clubId)), {
            replace: true,
          })}
        />
        <SecondaryButton
          text={t('button.confirm')}
          variant="high-light-blue"
          loading={isLoading.confirmButton}
          onClick={onOpenConfirm}
        />
      </div>

      <ConfirmModal
        confirmTitle={t('title.confirm')}
        confirmContent={t('paragraph.sure-payment')}
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
        onConfirm={createMemberContract}
      />
      <BottomSpace />
    </div>
  );
}
