import { ROUTES, THEME_COLOR } from 'constant';
import { PTPlanContainer } from 'containers';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { SecondaryLayout } from 'layouts';

type Props = {};

export default function PTPlanPage({}: Props) {
  usePWATemplateColor(THEME_COLOR.WHITE);

  return (
    <SecondaryLayout
      title={'title.personal-trainer'}
      headerBackground="var(--blue-bg-color)"
      titleColor="var(--primary-color)"
      arrowColor="var(--primary-color)"
      isHideFooter
      isHideHome
      isWhiteBg
      backUrl={ROUTES.PT_SELECT}
    >
      <PTPlanContainer />
    </SecondaryLayout>
  );
}
