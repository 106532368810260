import { Image } from 'antd-mobile';
import styles from './styles.module.scss';
import { SecondaryButton } from 'components/button';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';

type Props = {};

export default function InstallAndroid({}: Props) {
  const { t } = useTranslation();
  const installPrompt = useRef<any>();

  const handleInstall = async () => {
    if (!installPrompt.current) {
      return;
    }
    await installPrompt.current.prompt();
  };

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      installPrompt.current = event;
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <Image src="/images/logo/logo-new-gym-3.png" />
        </div>
        <SecondaryButton
          variant="high-light-blue"
          iconUrl="/images/icon/download.svg"
          iconPathFill="var(--white-text-color)"
          text={t('button.download')}
          onClick={handleInstall}
        />
      </div>
    </div>
  );
}
