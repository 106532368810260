import styles from './styles.module.scss';
import { PrimaryButton } from 'components';
import { useTranslation } from 'react-i18next';
import { useDirection } from 'hooks';
import { ROUTES } from 'constant';
import { Image } from 'antd-mobile';
import { motion } from 'framer-motion';
import { LocalStorage, STORAGE_KEY } from 'utils';
import { useEffect } from 'react';

type Props = {};

const Storage = new LocalStorage();

export function LandingContainer({}: Props) {
  const { t } = useTranslation();
  const { goTo } = useDirection();

  useEffect(() => {
    if (Storage.getStorageItem(STORAGE_KEY.ACCESS_TOKEN)) {
      goTo(ROUTES.HOME)();
    }
  }, []);

  return (
    <div className={styles.container}>
      <motion.div
        className={styles.logo}
        initial={{ y: 150 }}
        animate={{ y: 0 }}
        transition={{ delay: 0.5, duration: 0.5 }}
      >
        <Image src="/images/logo/logo-new-gym-3.png" width="200px" />
      </motion.div>
      <motion.div
        className={styles.title}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.5 }}
      >
        <p className="color-blue font-bold">{t('title.gym-for-everyone')}</p>
      </motion.div>

      <motion.div
        className={styles.content}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1, duration: 0.5 }}
      >
        <div className={styles.btnGroup}>
          {/* <PrimaryButton variant="outline" text={t('button.explore-the-app')} /> */}
          <PrimaryButton text={t('button.log-in')} onClick={goTo(ROUTES.LOGIN)} />
        </div>
      </motion.div>
    </div>
  );
}
