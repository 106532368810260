import styles from './styles.module.scss';
import { useDirection } from 'hooks';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'constant';
import SvgIcon from 'components/shared/SVGIcon';
import SupportItem from 'components/shared/SupportItem';

type Props = {};

export function SupportContainer({}: Props) {
  const { t } = useTranslation();
  const { goTo } = useDirection();

  const supportItems = [
    {
      text: t('title.facebook-messenger'),
      icon: <SvgIcon pathFill="var(--primary-color)" src="/images/icon/facebook-messenger.svg" />,
    },
    {
      text: t('title.zalo'),
      icon: <SvgIcon src="/images/icon/zalo.svg" style={{ width: '25px', height: '25px' }} />,
    },
    {
      text: t('title.call-member-service'),
      icon: <SvgIcon pathFill="var(--primary-color)" src="/images/icon/call-member-service.svg" />,
    },
    {
      text: t('title.report'),
      icon: <img src="/images/icon-png/report.png" style={{ width: '25px', height: '25px' }} />,
      onClick: goTo(ROUTES.SUPPORT_REPORT),
    },
  ];

  return (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <p className="font-sm color-black">{t('title.how-can-I-help-you')}</p>
        </div>

        <div className={styles.content}>
          {supportItems.map((item, index) => (
            <SupportItem key={index} text={item.text} icon={item.icon} onClick={item.onClick} />
          ))}
        </div>
      </div>
    </div>
  );
}
