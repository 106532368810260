import React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { formatDate, formatPrice, generateClubName, isVN, showData } from 'utils';

export function PtPayment({ data }: { data: any }) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <p>
        {t('notify.hello')} <span className={styles.textBold}>{showData(data?.account?.name)}</span>
        ,
      </p>
      <p className={styles.block}>{t('notify.thank-you-for-workout')}</p>

      <p className={styles.block}>{t('notify.pt-contract-info')}:</p>
      <p>
        - {t('notify.pt-name')}:{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {showData(data?.ptInfo?.name)}
        </span>
      </p>
      <p className={styles.block}>
        - {t('notify.club')}:{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {generateClubName(isVN() ? data?.club?.nameVi : data?.club?.nameEn)}
        </span>
      </p>
      <p>
        - {t('notify.address')}:{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {showData(data?.club?.location)}
        </span>
      </p>
      <p className={styles.block}>
        - {t('notify.pt-plan')}:{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {showData(isVN() ? data?.ptPackage?.nameVi : data?.ptPackage?.nameEn)}
        </span>
      </p>
      <p>
        - {t('notify.total-payment')}:{' '}
        <span className={styles.textBold}>
          {formatPrice(Number(data?.transactionAmount), true)}
        </span>
      </p>

      <p className={styles.block}>
        - {t('notify.payment-method')}:{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {' '}
          {showData(isVN() ? data?.paymentMethod?.nameVi : data?.paymentMethod?.nameEn)}
        </span>
      </p>
      <p className={styles.block}>
        - {t('notify.start-date')}:{' '}
        <span className={styles.textBold}>{formatDate(data?.startDate)}</span>
      </p>
      <p>
        - {t('notify.end-date')}:{' '}
        <span className={styles.textBold}>{formatDate(data?.endDate)}</span>
      </p>
      <p className={styles.block}>{t('notify.welcome')}</p>
    </div>
  );
}
