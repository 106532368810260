import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserContractsAction, userSelector } from 'stores/user';
import { delayNavigate, isMember, isSuccessCode } from 'utils';
import { useDispatch } from 'stores';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { THEME_COLOR } from 'constant';
import { SecondaryLayout } from 'layouts';
import { PlanTimeline } from 'components';
import { ContractType } from 'types';
import { UserService } from 'services';
import { E_CONTRACT_STATUS, PAYMENT_PLAN_TYPE } from 'enum';
import { isEmpty } from 'lodash';
import LoadingDot from 'components/shared/LoadingDot';

export function YourPlanContainer() {
  const [isBlackCard, setIsBlackCard] = useState(false);
  usePWATemplateColor(isBlackCard ? THEME_COLOR.BLACK : THEME_COLOR.MAIN);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userService = new UserService();
  const { selectedContract, userContracts } = useSelector(userSelector);
  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const [isLoadingContract, setIsLoadingContract] = useState(false);
  const [listUserContract, setListUserContract] = useState<ContractType[]>([]);

  const getContractsCurrent = async () => {
    if (isEmpty(userContracts) && isMember()) {
      setIsLoadingProfile(true);
      await dispatch(getUserContractsAction());
      delayNavigate(() => setIsLoadingProfile(false));
    }
  };

  const getListContracts = async () => {
    setIsLoadingContract(true);
    const response = await userService.getContracts({});
    const { code, data } = response;
    if (isSuccessCode(code)) {
      const listContract = data?.data as ContractType[];
      setListUserContract(
        listContract?.filter((contract) => contract.status != E_CONTRACT_STATUS.WAIT_FOR_PAYMENT),
      );
    }
    delayNavigate(() => setIsLoadingContract(false));
  };

  useEffect(() => {
    if (!isEmpty(selectedContract)) {
      setIsBlackCard(Boolean(selectedContract.paymentPlan?.type === PAYMENT_PLAN_TYPE.BLACK_CARD));
    }
  }, [selectedContract]);

  useEffect(() => {
    getListContracts();
    getContractsCurrent();
  }, []);

  return (
    <SecondaryLayout
      isHideFooter
      isHideHome
      isHideBottomSpace
      isHideSlogan
      headerBackground={isBlackCard ? 'var(--black-color)' : 'var(--blue-bg-color)'}
      titleColor={isBlackCard ? 'var(--white-text-color)' : 'var(--primary-color)'}
      arrowColor={isBlackCard ? 'var(--white-text-color)' : 'var(--primary-color)'}
      backgroundColor={isBlackCard ? 'var(--black-color)' : 'var(--blue-bg-color)'}
      title={t('title.your-plan')}
    >
      {isLoadingProfile || isLoadingContract ? (
        <LoadingDot />
      ) : (
        <div
          className={styles.containerWrapper}
          style={{
            backgroundImage: 'url(/images/support-background/sp-bg-01.png)',
          }}
        >
          <div className={`${styles.container} ${isBlackCard ? styles.blackCard : ''}`}>
            <PlanTimeline listContract={listUserContract} isDark={isBlackCard} />
          </div>
        </div>
      )}
    </SecondaryLayout>
  );
}
