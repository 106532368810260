import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { PasscodeInputRef } from 'antd-mobile';
import { useDirection } from 'hooks';
import { OTP_RESENT_DURATION, ROUTES } from 'constant';
import { AuthService } from 'services';
import { useLocation } from 'react-router';
import { delayNavigate, isSuccessCode } from 'utils';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'stores';
import { setLoadingToast } from 'stores/common';
import PinInput from 'components/input/PinInput';
import { useCountdown } from 'usehooks-ts';
import { clearUserProfile } from 'stores/user';

export function VerifyPhoneNumberContainer() {
  const authService = new AuthService();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const location = useLocation();
  const inputCodeRef = useRef<PasscodeInputRef>(null);
  const [count, { startCountdown, resetCountdown }] = useCountdown({
    countStart: OTP_RESENT_DURATION,
    intervalMs: 1000,
  });
  const [isError, setIsError] = useState(false);

  const handleComplete = async (value: string) => {
    if (inputCodeRef.current) inputCodeRef.current.blur();
    dispatch(setLoadingToast(true));
    const response = await authService.verifyOTPChangePhoneNumber({
      otpCode: value,
      phoneNumber: location.state ? location.state?.phoneNumber : '',
    });
    const { code } = response;

    if (isSuccessCode(code)) {
      dispatch(clearUserProfile());
      delayNavigate(goTo(ROUTES.ACCOUNT_INFORMATION, { replace: true }));
    } else {
      setIsError(true);
    }
  };

  const sendOTPCode = async () => {
    dispatch(setLoadingToast(true));
    const response = await authService.sendOTPLogin({
      phoneNumber: location.state?.phoneNumber,
    });
    const { code } = response;
    if (isSuccessCode(code)) {
      resetCountdown();
      startCountdown();
    }
  };

  useEffect(() => {
    if (inputCodeRef.current) inputCodeRef.current.focus();
    if (!location.state?.phoneNumber) delayNavigate(goTo(ROUTES.LADING, { replace: true }));
    startCountdown();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.container}>
        <div className={styles.form}>
          <p
            className={`font-md color-black font-light ${styles.label}`}
            style={{
              whiteSpace: 'wrap',
              lineHeight: 'normal',
            }}
          >
            {t('paragraph.pr35')}
            <span className="color-blue font-bold">{`\n${
              location.state ? location.state?.phoneNumber : ''
            } `}</span>
            {t('paragraph.pr36')}
          </p>
          <div className={styles.input}>
            <PinInput onComplete={handleComplete} isError={isError} />
          </div>
        </div>
        <div className={styles.resendCode}>
          <p
            className="font-sm font-light"
            style={{
              color: 'var(--black-color)',
            }}
          >
            {t('title.did-receive-it')}
          </p>
          {count > 0 ? (
            <p className="color-black font-sm font-light">
              {t('title.get-new-code-in')} 00:{String(count).padStart(2, '0')}
            </p>
          ) : (
            <p className="color-blue font-md font-medium" onClick={sendOTPCode}>
              {t('button.get-new-code')}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
