import React from 'react';
import styles from './styles.module.scss';
import { Image, Skeleton } from 'antd-mobile';
import { formatTime, getImageUrl, showData } from 'utils';
import { useTranslation } from 'react-i18next';

type Props = {
  isSkeleton?: boolean;
  duration?: number;
  beginAt?: string;
  imageUrl?: string;
  onClick?: () => void;
};

function ClassCard({ duration, beginAt, imageUrl = '', isSkeleton = false }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.cardWrapper}>
      {isSkeleton ? (
        <Skeleton
          animated
          style={{
            height: '180px',
            width: '100%',
            borderTopLeftRadius: '18px',
            borderTopRightRadius: '18px',
          }}
        />
      ) : (
        <Image
          className={styles.image}
          src={imageUrl ? getImageUrl(imageUrl) : '/images/logo/app-icon.png'}
        />
      )}
      <div className={styles.moreInfo}>
        <div className={styles.block}>
          <p className="font-md color-white font-medium">{t('title.duration')}</p>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-lg)',
                width: '100px',
              }}
            />
          ) : (
            <p className="font-lg color-white font-light">
              {t('title.minute-holder', { value: showData(duration) })}
            </p>
          )}
        </div>
        <div className={styles.block}>
          <p className="font-md color-white font-medium">{t('title.start-at')}</p>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-lg)',
                width: '100px',
              }}
            />
          ) : (
            <p className="font-lg color-white font-light">{showData(formatTime(beginAt))}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(ClassCard);
