/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from './styles.module.scss';
import { SecondaryButton } from 'components';
import DetailCardInfo from 'components/card/DetailCardInfo';
import ConfirmModal from 'components/modal/ConfirmModal';
import { ROUTES } from 'constant';
import { BANK_INFO_MAPPING } from 'constant/bank';
import { useDirection, useDisclosure } from 'hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import PaymentService from 'services/payment.service';
import { SavedCardType } from 'types/payment.type';
import { delayNavigate, formatFullVPPayReturnCard, isSuccessCode } from 'utils';

type Props = {};

export default function CardDetailContainer({}: Props) {
  const paymentService = new PaymentService();
  const { goTo } = useDirection();
  const { t } = useTranslation();
  const { cardId } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [cardDetail, setCardDetail] = useState<SavedCardType>();
  const [isLoading, setIsLoading] = useState(true);
  const [isCardDefault, setIsCardDefault] = useState(false);

  const getCardDetail = async () => {
    if (cardId) {
      const response = await paymentService.getCardDetail(cardId);
      const { code, data } = response;
      if (isSuccessCode(code)) {
        setCardDetail(data);
      }
      delayNavigate(() => setIsLoading(false));
    }
  };

  const updateDefaultCard = async () => {
    if (cardId && !isCardDefault) {
      const response = await paymentService.updateDefaultCard(cardId);
      const { code } = response;
      if (isSuccessCode(code)) {
        setIsCardDefault(true);
      }
    }
  };

  const handleDeleteCard = async () => {
    if (cardId) {
      const response = await paymentService.deleteCard(cardId);
      const { code } = response;
      if (isSuccessCode(code)) {
        delayNavigate(() =>
          goTo(ROUTES.ACCOUNT_PAYMENT_METHOD, {
            replace: true,
          })(),
        );
      }
    }
  };

  useEffect(() => {
    setIsCardDefault(cardDetail?.isDefault || false);
  }, [cardDetail]);

  useEffect(() => {
    getCardDetail();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <DetailCardInfo
          isSkeleton={isLoading}
          isDefault={isCardDefault}
          iconCard={BANK_INFO_MAPPING[cardDetail?.bankCode as keyof typeof BANK_INFO_MAPPING]?.logo}
          cardNumber={formatFullVPPayReturnCard(cardDetail?.cardNumber || '')}
          onActiveDefault={updateDefaultCard}
        />
        <p
          className="font-xs color-black font-light"
          style={{
            marginTop: '12px',
          }}
        >
          {t('paragraph.how-to-change-card')}
        </p>
      </div>
      <div className={styles.controller}>
        <SecondaryButton
          text={t('button.delete-card')}
          variant="high-light"
          style={{
            width: '100%',
          }}
          onClick={onOpen}
        />
      </div>
      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleDeleteCard}
        confirmTitle={t('button.confirm')}
        confirmContent={t('paragraph.sure-about-delete-card')}
      />
    </div>
  );
}
