import { PullToRefresh } from 'antd-mobile';
import React from 'react';
import LoadingDot from '../LoadingDot';

type Props = {
  children: React.ReactNode;
};

function PullToReload({ children }: Props) {
  return (
    <PullToRefresh
      completeDelay={0}
      completeText={
        <LoadingDot
          styles={{
            mixBlendMode: 'difference',
          }}
        />
      }
      refreshingText={
        <LoadingDot
          styles={{
            mixBlendMode: 'difference',
          }}
        />
      }
      pullingText={
        <LoadingDot
          styles={{
            mixBlendMode: 'difference',
          }}
        />
      }
      canReleaseText={
        <LoadingDot
          styles={{
            mixBlendMode: 'difference',
          }}
        />
      }
      threshold={70}
      onRefresh={async () => {
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }}
    >
      {children}
    </PullToRefresh>
  );
}

export default React.memo(PullToReload);
