import { APIResponse } from 'types';
import { AxiosGW } from './axios.service';
import {
  AddVNPayAddCardUrlReq,
  PayFreezeWithPayOSReq,
  PayFreezeWithVNPayCardReq,
  PayPtWithPayOSReq,
  PayPTWithVNPayCardReq,
  PayWithPayOSReq,
  PayWithVNPayCardReq,
} from 'types/payment.type';

export default class PaymentService {
  async getExistCards() {
    return (await AxiosGW.get(`/api/v1/payment/vnpay/tokens`)).data as APIResponse;
  }

  async addVNPayAddCardUrl(payload: AddVNPayAddCardUrlReq) {
    const { cancelUrl, cardType, locale, returnUrl } = payload;

    return (
      await AxiosGW.post(
        `/api/v1/payment/vnpay/token/create`,
        {
          cancelUrl,
          cardType,
          locale,
          returnUrl,
        },
        {
          isDisableToast: true,
        },
      )
    ).data as APIResponse;
  }

  async payWithVNPayCard(payload: PayWithVNPayCardReq) {
    const { vnpayTokenId, contractId, cancelUrl, locale, returnUrl } = payload;

    return (
      await AxiosGW.post(
        `/api/v1/payment/vnpay/token/${vnpayTokenId}/contract/${contractId}/pay`,
        {
          cancelUrl,
          locale,
          returnUrl,
        },
        {
          isDisableToast: true,
        },
      )
    ).data as APIResponse;
  }

  async payPtWithVNPayCard(payload: PayPTWithVNPayCardReq) {
    const { vnpayTokenId, ptContractId, cancelUrl, locale, returnUrl } = payload;

    return (
      await AxiosGW.post(
        `/api/v1/payment/vnpay/token/${vnpayTokenId}/pt-contract/${ptContractId}/pay`,
        {
          cancelUrl,
          locale,
          returnUrl,
        },
        {
          isDisableToast: true,
        },
      )
    ).data as APIResponse;
  }

  async payFreezeWithVNPayCard(payload: PayFreezeWithVNPayCardReq) {
    const { vnpayTokenId, freezeContractId, cancelUrl, locale, returnUrl } = payload;

    return (
      await AxiosGW.post(
        `/api/v1/payment/vnpay/token/${vnpayTokenId}/freeze-contract/${freezeContractId}/pay`,
        {
          cancelUrl,
          locale,
          returnUrl,
        },
        {
          isDisableToast: true,
        },
      )
    ).data as APIResponse;
  }

  async payWithPayOS(payload: PayWithPayOSReq) {
    const { contractId, cancelUrl, returnUrl } = payload;

    return (
      await AxiosGW.post(
        `/api/v1/payment/payos/contract/${contractId}/pay`,
        {
          cancelUrl,
          returnUrl,
        },
        {
          isDisableToast: true,
        },
      )
    ).data as APIResponse;
  }

  async payFreezeWithPayOS(payload: PayFreezeWithPayOSReq) {
    const { freezeContractId, cancelUrl, returnUrl } = payload;

    return (
      await AxiosGW.post(
        `/api/v1/payment/payos/freeze-contract/${freezeContractId}/pay`,
        {
          cancelUrl,
          returnUrl,
        },
        {
          isDisableToast: true,
        },
      )
    ).data as APIResponse;
  }

  async payPtWithPayOS(payload: PayPtWithPayOSReq) {
    const { ptContractId, cancelUrl, returnUrl } = payload;

    return (
      await AxiosGW.post(
        `/api/v1/payment/payos/pt-contract/${ptContractId}/pay`,
        {
          cancelUrl,
          returnUrl,
        },
        {
          isDisableToast: true,
        },
      )
    ).data as APIResponse;
  }

  async getCardDetail(vnpayTokenId: string | number) {
    return (await AxiosGW.get(`/api/v1/payment/vnpay/token/${vnpayTokenId}/detail`))
      .data as APIResponse;
  }

  async deleteCard(vnpayTokenId: string | number) {
    return (await AxiosGW.delete(`/api/v1/payment/vnpay/token/${vnpayTokenId}/delete`))
      .data as APIResponse;
  }

  async updateDefaultCard(vnpayTokenId: string | number) {
    return (await AxiosGW.put(`/api/v1/payment/vnpay/token/${vnpayTokenId}/default`))
      .data as APIResponse;
  }
}
