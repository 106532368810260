import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import InstallAndroid from './InstallAndroid';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import InstallIOS from './InstallIOS';
import { useLocation } from 'react-router';

function InstallOverlay() {
  const { t } = useTranslation();
  const host = window.location.origin;
  const location = useLocation();

  useEffect(() => {
    if (location.search === '?opened=true') return;
    if (isIOS) {
      window.location.href = `x-safari-${host}?opened=true`;
    } else if (isAndroid) {
      window.location.href = `intent:${host}?opened=true#Intent;package=com.android.chrome;end;`;
    }
  }, []);

  return createPortal(
    <div className={styles.container}>
      {isMobile ? (
        <>
          <p className={styles.slogan}>{t('paragraph.slogan')}</p>
          <div className={styles.body}>{isIOS ? <InstallIOS /> : <InstallAndroid />}</div>
        </>
      ) : (
        <div className={styles.warning}>
          <p className="font-md font-light color-black">
            Vui lòng sử dụng trình duyệt trên điện thoại và truy cập vào đường dẫn
            <span
              style={{
                fontStyle: 'italic',
              }}
            >
              {' '}
              {host}
            </span>
          </p>
          <p className="font-md font-light color-black">
            Please use your phone's browser and access the link
            <span
              style={{
                fontStyle: 'italic',
              }}
            >
              {' '}
              {host}
            </span>
          </p>
        </div>
      )}
    </div>,
    document.body,
  );
}

export default React.memo(InstallOverlay);
