import { ROUTES, THEME_COLOR } from 'constant';
import { PaymentMembershipContainer } from 'containers';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { SecondaryLayout } from 'layouts';
import { useParams } from 'react-router';

type Props = {};

export default function PaymentMembershipPage({}: Props) {
  const { clubId } = useParams();
  usePWATemplateColor(THEME_COLOR.WHITE);

  return (
    <SecondaryLayout
      title="title.payment"
      headerBackground="var(--blue-bg-color)"
      titleColor="var(--primary-color)"
      arrowColor="var(--primary-color)"
      isHideFooter
      isHideHome
      isWhiteBg
      isHideBottomSpace
      backUrl={ROUTES.MEMBERSHIP_SELECT.replace(':clubId', String(clubId))}
    >
      <PaymentMembershipContainer />
    </SecondaryLayout>
  );
}
