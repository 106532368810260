/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import styles from './styles.module.scss';
import { Image, Skeleton } from 'antd-mobile';
import { formatDate, showData } from 'utils';
import { useTranslation } from 'react-i18next';
import { E_PT_CONTRACT_STATUS } from 'enum';

type Props = {
  name?: string;
  endDate?: string;
  isSuccess?: boolean;
  onClick?: () => void;
  totalSession?: number;
  remainingSession?: number;
  isSkeleton?: boolean;
  status: E_PT_CONTRACT_STATUS;
};

function PTTransactionHistoryCard({
  name,
  endDate,
  status,
  isSuccess = false,
  isSkeleton = false,
  totalSession = 0,
  remainingSession = 0,
  onClick,
}: Props) {
  const { t } = useTranslation();

  const statusInfo = useMemo(() => {
    switch (status) {
      case E_PT_CONTRACT_STATUS.CURRENT:
        return {
          title: t('title.active'),
          color: 'var(--sub-high-light-color)',
        };
      case E_PT_CONTRACT_STATUS.FUTURE:
        return {
          title: t('title.future'),
          color: 'var(--primary-color)',
        };
      case E_PT_CONTRACT_STATUS.PAST:
        return {
          title: t('title.expired'),
          color: 'var(--high-light-color)',
        };
      default:
        return { title: '', color: '' };
    }
  }, []);

  return (
    <div className={styles.cardWrapper} onClick={onClick}>
      {isSkeleton ? (
        <Skeleton className={styles.icon} animated />
      ) : (
        <div className={styles.icon}>
          <Image
            src={isSuccess ? '/images/icon/tick-circle.svg' : '/images/icon/circle-menu.svg'}
          />
        </div>
      )}
      <div className={styles.plan}>
        <p className="font-md color-black font-bold">{showData(name)}</p>
        <p
          className="font-sm font-bold"
          style={{
            color: statusInfo?.color,
          }}
        >
          {statusInfo?.title}
        </p>
      </div>
      {isSkeleton ? (
        <Skeleton
          animated
          style={{
            width: '100%',
            height: 'var(--font-sm)',
          }}
        />
      ) : (
        <div className={styles.time}>
          <p className="font-sm color-black font-light">{t('title.end-date')}</p>
          <p className="font-sm color-black font-light">{t('title.remaining-session')}</p>
        </div>
      )}
      {isSkeleton ? (
        <Skeleton
          animated
          style={{
            width: '100%',
            height: 'var(--font-sm)',
          }}
        />
      ) : (
        <div className={styles.cardInfo}>
          <p
            className="font-sm color-black font-bold"
            style={{
              textAlign: 'left',
            }}
          >
            {showData(formatDate(endDate))}
          </p>
          <p
            className="font-sm color-black font-bold"
            style={{
              textAlign: 'right',
            }}
          >
            {showData(remainingSession)}/{showData(totalSession)}
          </p>
        </div>
      )}
    </div>
  );
}

export default React.memo(PTTransactionHistoryCard);
