import { Timeline } from 'antd';
import React from 'react';
import styles from './styles.module.scss';
import PlanCardItem from './PlanCardItem';
import { ContractType } from 'types';
import { PLAN_NAME_MAPPING } from 'constant';
import { formatDate, generateClubName, isVN } from 'utils';
import { E_CONTRACT_STATUS } from 'enum';

type Props = {
  listContract: ContractType[];
  isDark?: boolean;
};

function PlanTimeline({ listContract = [], isDark = false }: Props) {
  return (
    <Timeline
      className={styles.customTimeline}
      items={listContract.map((contract) => {
        return {
          dot: (
            <div
              className={`${styles.indicator} ${
                contract?.status === E_CONTRACT_STATUS.FUTURE
                  ? styles.future
                  : contract?.status === E_CONTRACT_STATUS.CURRENT
                  ? styles.active
                  : styles.expired
              }`}
            />
          ),
          children: (
            <PlanCardItem
              isDark={isDark}
              isActive={contract?.status === E_CONTRACT_STATUS.CURRENT}
              clubName={generateClubName(isVN() ? contract?.club?.nameVi : contract?.club?.nameEn)}
              planName={
                PLAN_NAME_MAPPING[contract?.paymentPlan?.type as keyof typeof PLAN_NAME_MAPPING] ||
                (isVN() ? contract?.paymentPlan?.nameVi : contract?.paymentPlan?.nameEn)
              }
              startDate={formatDate(contract?.startDate)}
              endDate={formatDate(contract?.endDate)}
              status={contract.status}
            />
          ),
        };
      })}
    />
  );
}

export default React.memo(PlanTimeline);
