import { SafeArea } from 'antd-mobile';
import React from 'react';
import { Outlet } from 'react-router';

function BlankLayout() {
  return (
    <div
      style={{
        display: 'flex',
        width: '100vw',
        height: 'var(--app-height)',
        overflow: 'auto',
        flexDirection: 'column',
        background: ' var(#F2FAFC)',
      }}
    >
      <Outlet />
      <div>
        <SafeArea position="bottom" />
      </div>
    </div>
  );
}

export default React.memo(BlankLayout);
