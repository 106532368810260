import { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { SecondaryButton, FreezeContractDetailCard } from 'components';
import { useParams } from 'react-router';
import { delayNavigate, formatDate, formatDateTime, isSuccessCode } from 'utils';
import { useTranslation } from 'react-i18next';
import { useDirection } from 'hooks';
import { ROUTES } from 'constant';
import FreezeService from 'services/freeze.service';
import { FreezeContractType } from 'types';
import { keyBy } from 'lodash';
import { E_FREEZE_PAYMENT_TYPE } from 'enum';

type Props = {
  isHistoryPage?: boolean;
};

export default function PaymentFreezeResultContainer({ isHistoryPage = false }: Props) {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const freezeService = new FreezeService();
  const { freezeContractId } = useParams();
  const [contract, setContract] = useState<FreezeContractType>();
  const [isLoading, setIsLoading] = useState(false);

  const getContractDetail = async () => {
    setIsLoading(true);
    const response = await freezeService.getFreezeContractDetail({
      freezeContractId: String(freezeContractId),
    });
    const { data, code } = response;

    if (isSuccessCode(code)) {
      setContract(data);
    }

    delayNavigate(() => setIsLoading(false));
  };

  const freezeTransaction = useMemo(() => {
    const freeTypeKeys = keyBy(contract?.freezeTransactions, 'paymentType');

    return freeTypeKeys[E_FREEZE_PAYMENT_TYPE.FREEZE_CONTRACT];
  }, [contract?.freezeTransactions]);

  useEffect(() => {
    getContractDetail();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.transactionCard}>
        <FreezeContractDetailCard
          isHideExpand={isHistoryPage}
          isSkeleton={isLoading}
          status={contract?.status}
          paymentDate={freezeTransaction && formatDateTime(freezeTransaction?.purchaseDate)}
          transactionId={freezeTransaction && freezeTransaction?.freezeTransactionPayment?.orderRef}
          startDate={formatDate(contract?.freezeStartDate)}
          endDate={formatDate(contract?.freezeEndDate)}
          totalPayment={Number(contract?.paymentTotal)}
        />
      </div>
      {!isHistoryPage && (
        <div className={styles.controller}>
          <SecondaryButton
            text={t('button.go-to-home')}
            variant="high-light-blue"
            style={{
              width: '100%',
            }}
            onClick={goTo(ROUTES.HOME)}
          />
        </div>
      )}
    </div>
  );
}
