import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { DiscoverCard, SecondaryButton, ContractDetailCard } from 'components';
import { useParams } from 'react-router';
import { ContractType } from 'types/user.type';
import UserService from 'services/user.service';
import {
  delayNavigate,
  formatDateTime,
  formatPrice,
  getVideoUrl,
  isSuccessCode,
  isVN,
} from 'utils';
import { E_CONTRACT_STATUS, E_PAYMENT_TYPE } from 'enum';
import { useTranslation } from 'react-i18next';
import { keyBy } from 'lodash';
import { useDirection, useDisclosure } from 'hooks';
import { EXTERNAL_LINK, PLAN_NAME_MAPPING, ROUTES } from 'constant';
import VideoModal from 'components/modal/VideoModal';

type Props = {
  isHistoryPage?: boolean;
};

export default function PaymentResultContainer({ isHistoryPage = false }: Props) {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const userService = new UserService();
  const { contractId } = useParams();
  const [contract, setContract] = useState<ContractType>();
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const selectedDiscover = useRef('');

  const getContractDetail = async () => {
    setIsLoading(true);
    const response = await userService.getContractDetail(String(contractId));
    const { data, code } = response;

    if (isSuccessCode(code)) {
      setContract(data);
    }

    delayNavigate(() => setIsLoading(false));
  };

  const joiningFeeInfo = useMemo(() => {
    if (contract) {
      const transactionKeyBy = keyBy(contract.contractTransactions, 'paymentType');
      const joiningFeeTransaction = transactionKeyBy[E_PAYMENT_TYPE.JOINING_FEE];
      if (!joiningFeeTransaction)
        return {
          transaction: 0,
          payment: 0,
        };

      return {
        transaction: Number(joiningFeeTransaction.transactionAmount),
        payment: Number(joiningFeeTransaction.paymentTotal),
      };
    }

    return {
      transaction: 0,
      payment: 0,
    };
  }, [contract]);

  const membershipFeeInfo = useMemo(() => {
    if (contract) {
      const transactionKeyBy = keyBy(contract.contractTransactions, 'paymentType');
      const membershipFeeTransaction = transactionKeyBy[E_PAYMENT_TYPE.MEMBERSHIP_FEE];
      if (!membershipFeeTransaction)
        return {
          transaction: 0,
          payment: 0,
        };

      return {
        transaction: Number(membershipFeeTransaction.transactionAmount),
        payment: Number(membershipFeeTransaction.paymentTotal),
      };
    }

    return {
      transaction: 0,
      payment: 0,
    };
  }, [contract]);

  const membershipTransaction = useMemo(() => {
    if (contract) {
      const transactionKeyBy = keyBy(contract.contractTransactions, 'paymentType');
      const membershipFeeTransaction = transactionKeyBy[E_PAYMENT_TYPE.MEMBERSHIP_FEE];

      return membershipFeeTransaction;
    }

    return null;
  }, [contract]);

  const discountAmount = useMemo(() => {
    const discountJoiningFee = joiningFeeInfo.payment - joiningFeeInfo.transaction;
    const discountMembership = membershipFeeInfo.payment - membershipFeeInfo.transaction;

    return discountJoiningFee + discountMembership;
  }, [joiningFeeInfo, membershipFeeInfo]);

  const handleOpenDiscover = (discoverLink: string) => () => {
    onOpen();
    selectedDiscover.current = getVideoUrl(discoverLink);
  };

  useEffect(() => {
    getContractDetail();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.transactionCard}>
        <ContractDetailCard
          isHideExpand={isHistoryPage}
          isSkeleton={isLoading}
          status={contract?.status as E_CONTRACT_STATUS}
          clubName={
            contract?.paymentPlan?.isBlackCard
              ? t('title.all-club')
              : isVN()
              ? contract?.club?.nameVi
              : contract?.club?.nameEn
          }
          membershipName={
            PLAN_NAME_MAPPING[contract?.paymentPlan?.type as keyof typeof PLAN_NAME_MAPPING] ||
            (isVN() ? contract?.paymentPlan?.nameVi : contract?.paymentPlan?.nameEn)
          }
          membershipFee={membershipFeeInfo?.payment}
          duration={`${contract?.paymentPlan?.duration?.value} ${t(
            `title.${contract?.paymentPlan?.duration?.unit}`,
          )}`}
          startupFee={
            joiningFeeInfo.payment === 0
              ? t('title.free')
              : formatPrice(joiningFeeInfo.payment || 0)
          }
          promoDiscount={discountAmount}
          referralInfo={
            contract?.referral &&
            `+${contract?.referral?.referralDuration?.value}
            ${t(`title.${contract?.referral?.referralDuration?.unit}`)}`
          }
          paymentDate={
            contract?.status !== E_CONTRACT_STATUS.WAIT_FOR_PAYMENT
              ? formatDateTime(membershipTransaction?.purchaseDate)
              : undefined
          }
          transactionId={
            contract?.status !== E_CONTRACT_STATUS.WAIT_FOR_PAYMENT
              ? membershipTransaction?.contractTransactionPayment?.orderRef
              : undefined
          }
          totalPayment={membershipFeeInfo?.transaction + joiningFeeInfo?.transaction}
        />
      </div>
      {!isHistoryPage && (
        <>
          <div className={styles.description}>
            {contract?.status === E_CONTRACT_STATUS.WAIT_FOR_PAYMENT ? (
              <>
                <p className="color-dark-black font-bold font-lg">
                  {t('title.proceed-payment-at-counter')}
                </p>
                <p
                  className="color-dark-black font-light font-sm"
                  style={{
                    whiteSpace: 'pre-wrap',
                    marginTop: '12px',
                    lineHeight: 'normal',
                  }}
                >
                  {t('paragraph.pr39')}
                </p>
              </>
            ) : (
              <p
                className="color-dark-black font-light font-sm"
                style={{
                  textAlign: 'center',
                  lineHeight: 'normal',
                }}
              >
                {t('paragraph.pr40')}
              </p>
            )}
          </div>

          <p className="color-dark-black font-bold font-lg">{t('title.welcome-to-tng')}</p>
          <div className={styles.discoverList}>
            <div className={styles.item}>
              <DiscoverCard
                text={t('paragraph.pr41')}
                backgroundUrl="/images/support-background/bg-02.png"
                onClickBtn={handleOpenDiscover(EXTERNAL_LINK.PREPARE_FOR_FIST_VISIT)}
              />
            </div>
            <div className={styles.item}>
              <DiscoverCard
                text={t('paragraph.pr42')}
                backgroundUrl="/images/support-background/bg-01.png"
                onClickBtn={handleOpenDiscover(EXTERNAL_LINK.NOTICE_WHEN_JOIN)}
              />
            </div>
          </div>
          <div className={styles.controller}>
            <SecondaryButton
              text={t('button.go-to-home')}
              variant="high-light-blue"
              style={{
                width: '100%',
              }}
              onClick={goTo(ROUTES.HOME)}
            />
          </div>
        </>
      )}
      <VideoModal isOpen={isOpen} onClose={onClose} videoPath={selectedDiscover.current} />
    </div>
  );
}
