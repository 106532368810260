import { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { ClubType } from 'types';
import { delayNavigate, getDistanceFromLatLonInKm, getLocation, isVN, joinAddress } from 'utils';
import { useDirection } from 'hooks';
import _, { isEmpty, isNull } from 'lodash';
import { useTranslation } from 'react-i18next';
import Selection from 'components/input/Selection';
import { BottomSpace, ClubCard } from 'components';
import { ROUTES } from 'constant';
import { useDispatch, useSelector } from 'stores';
import {
  generalSelector,
  getCityHaveClubAction,
  getListClubAction,
  seDistanceMapping,
  setLocation,
} from 'stores/general';

type Props = {};

export default function PlansTab({}: Props) {
  const { listClub, location, clubDistanceMapping, cityHaveClub } = useSelector(generalSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const [selectedCityId, setSelectedCityId] = useState<null | number>(null);
  const [isLoadingDistance, setIsLoadingDistance] = useState(false);
  const [isLoadingClub, setIsLoadingClub] = useState(false);

  const getListCityHaveClub = async () => {
    if (isEmpty(cityHaveClub)) {
      await dispatch(getCityHaveClubAction());
    }
  };

  const getListClub = async () => {
    if (isEmpty(listClub)) {
      setIsLoadingClub(true);
      await dispatch(getListClubAction());
      delayNavigate(() => setIsLoadingClub(false));
    }
  };

  const handleChangeCity = (cityId: number | null) => {
    setSelectedCityId(cityId);
  };

  const filterClubByCityId = (cityId: number | null) => {
    if (!isNull(cityId)) {
      return sortClub.filter((club) => club.cityId === cityId);
    } else {
      return sortClub;
    }
  };

  const success = (position: GeolocationPosition) => {
    dispatch(
      setLocation({
        lat: position.coords.latitude,
        long: position.coords.longitude,
      }),
    );
    setIsLoadingDistance(false);
  };

  const sortClub = useMemo(() => {
    if (_.isEmpty(clubDistanceMapping)) return listClub;
    else {
      const sortedClubs: ClubType[] = [];
      const clubKeyBy = _.keyBy(listClub, 'id');
      const mappings = _.sortBy(_.valuesIn(clubDistanceMapping), 'distance');
      mappings.forEach((club) => {
        sortedClubs.push(clubKeyBy[club.id]);
      });

      return sortedClubs;
    }
  }, [clubDistanceMapping, listClub]);

  useEffect(() => {
    const result: { id: string | number | undefined; distance: number }[] = [];
    listClub.forEach((clubs) => {
      if (location && clubs.latitude && clubs.longitude && location.lat && location.long) {
        const data = getDistanceFromLatLonInKm(
          location.lat,
          location.long,
          clubs.latitude,
          clubs.longitude,
        );
        result.push({ id: clubs.id, distance: Math.ceil(data * 10) / 10 });
      }
    });
    dispatch(seDistanceMapping(_.keyBy(result, 'id') as any));
  }, [listClub, location]);

  useEffect(() => {
    if (isEmpty(location)) {
      getLocation(
        () => {
          setIsLoadingDistance(true);
        },
        success,
        (positionError: GeolocationPositionError) => {
          setIsLoadingDistance(false);
          console.log('Error: ', positionError);
        },
        () => {
          setIsLoadingDistance(false);
        },
      );
    }
  }, []);

  useEffect(() => {
    getListCityHaveClub();
    getListClub();
  }, []);

  return (
    <div className={styles.tabWrapper}>
      <div className={styles.chipWrapper}>
        <Selection
          options={cityHaveClub.map((city) => ({ id: Number(city.id), title: city.cityName }))}
          selectedId={selectedCityId}
          onChangeCity={handleChangeCity}
        />
      </div>
      <p className={styles.clubNearYouTitle}>{t('title.find-club-near-you')}</p>
      <div className={styles.clubCardList}>
        {isLoadingDistance || isLoadingClub
          ? [1, 2, 3, 4].map((_item, idx) => {
              return (
                <div key={idx} className={styles.clubCard}>
                  <ClubCard isSkeleton />
                </div>
              );
            })
          : filterClubByCityId(selectedCityId).map((club) => (
              <div key={club.id} className={styles.clubCard}>
                <ClubCard
                  clubName={isVN() ? club.nameVi : club.nameEn}
                  clubAddress={joinAddress(club)}
                  clubDistance={clubDistanceMapping[String(club.id)]?.distance}
                  onReviewPlan={goTo(ROUTES.MEMBERSHIP_SELECT.replace(':clubId', String(club.id)))}
                  onSeeDetail={goTo(
                    ROUTES.MEMBERSHIP_CLUB_DETAIL.replace(':clubId', String(club.id)),
                  )}
                />
              </div>
            ))}
        <BottomSpace />
      </div>
    </div>
  );
}
