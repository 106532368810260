import React from 'react';
import { SecondaryButton } from 'components';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReportMethodEnum } from 'enum/report.enum';

type Props = {};

export function SupportReportContainer({}: Props) {
  const { t } = useTranslation();
  const { reportType } = useParams<{ reportType: ReportMethodEnum }>();

  const reportTitle = () => {
    switch (reportType) {
      case ReportMethodEnum.TEMPERATURE:
        return t('title.temperature');
      case ReportMethodEnum.MACHINE:
        return t('title.machine');
      case ReportMethodEnum.EMPLOYEE_ATTITUDE:
        return t('title.employee-attitude');
      case ReportMethodEnum.CLEAN_QUALITY:
        return t('title.clean-quality');
      case ReportMethodEnum.ERROR_MEMBERSHIP:
        return t('title.error-membership');
      case ReportMethodEnum.OTHERS:
        return t('title.others-report');
    }
  };

  return (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.typein}>
            <p className="font-xxl">{t('title.let-us-know')}</p>
            <textarea
              className={`font-sm font-medium ${styles.textarea}`}
              placeholder={t('title.type-in')}
            />
          </div>
          <div className={styles.btnGroup}>
            <SecondaryButton text={t('title.done')} />
          </div>
        </div>
        <div className={styles.reportContent}>
          <p className="font-xl font-medium">{reportTitle()}</p>
        </div>
      </div>
    </div>
  );
}
