import { ROUTES, THEME_COLOR } from 'constant';
import { PTPaymentContainer } from 'containers';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { SecondaryLayout } from 'layouts';
import { useParams } from 'react-router';

type Props = {};

export default function PTPaymentPage({}: Props) {
  const { ptId } = useParams();

  usePWATemplateColor(THEME_COLOR.WHITE);

  return (
    <SecondaryLayout
      title={'title.payment'}
      headerBackground="var(--blue-bg-color)"
      titleColor="var(--primary-color)"
      arrowColor="var(--primary-color)"
      isHideFooter
      isHideHome
      isWhiteBg
      isHideBottomSpace
      backUrl={ROUTES.PT_PLAN.replace(':ptId', String(ptId))}
    >
      <PTPaymentContainer />
    </SecondaryLayout>
  );
}
