import React, { useMemo } from 'react';
import styles from './styles.module.scss';
import { showData } from 'utils';
import { useTranslation } from 'react-i18next';
import { CONTRACT_STATUS } from 'types';
import { E_CONTRACT_STATUS } from 'enum';

type Props = {
  clubName?: string;
  planName?: string;
  startDate?: string;
  endDate?: string;
  status?: CONTRACT_STATUS;
  isActive?: boolean;
  isDark?: boolean;
};

function PlanCardItem({
  clubName,
  planName,
  startDate,
  endDate,
  status,
  isActive = false,
  isDark = false,
}: Props) {
  const { t } = useTranslation();

  const statusInfo = useMemo(() => {
    switch (status) {
      case E_CONTRACT_STATUS.CURRENT:
        return {
          title: t('title.active'),
          color: 'var(--sub-high-light-color)',
        };
      case E_CONTRACT_STATUS.FUTURE:
        return {
          title: t('title.future'),
          color: 'var(--primary-color)',
        };
      case E_CONTRACT_STATUS.PAST:
        return {
          title: t('title.expired'),
          color: 'var(--high-light-color)',
        };
      default:
        return { title: '', color: '' };
    }
  }, [status]);

  return (
    <div className={`${styles.cardWrapper} ${isActive ? styles.active : ''}`}>
      <div className={styles.infoClub}>
        <p
          className="font-sm font-bold"
          style={{
            color: isActive || isDark ? 'var(--white-text-color)' : 'var(--primary-text-color)',
          }}
        >
          {showData(clubName)}
        </p>
      </div>
      <div className={styles.info}>
        <p
          className="font-xs font-medium font-bold"
          style={{
            color: statusInfo?.color,
            textDecoration: 'underline',
          }}
        >
          {showData(statusInfo?.title)}
        </p>
        <p
          className="font-md font-bold"
          style={{
            color: isActive || isDark ? 'var(--white-text-color)' : 'var(--primary-text-color)',
          }}
        >
          {showData(planName)}
        </p>
      </div>
      <div className={styles.title}>
        <p
          className="font-xs font-medium"
          style={{
            color: isActive || isDark ? 'var(--white-text-color)' : 'var(--primary-text-color)',
          }}
        >
          {t('title.start-date')}
        </p>
        <p
          className="font-xs font-medium"
          style={{
            color: isActive || isDark ? 'var(--white-text-color)' : 'var(--primary-text-color)',
          }}
        >
          {t('title.end-date')}
        </p>
      </div>
      <div className={styles.time}>
        <p
          className="font-xs font-light"
          style={{
            color: isActive || isDark ? 'var(--white-text-color)' : 'var(--primary-text-color)',
          }}
        >
          {showData(startDate)}
        </p>
        <p
          className="font-xs font-light"
          style={{
            color: isActive || isDark ? 'var(--white-text-color)' : 'var(--primary-text-color)',
          }}
        >
          {showData(endDate)}
        </p>
      </div>
    </div>
  );
}

export default React.memo(PlanCardItem);
