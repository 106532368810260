import { Button, ButtonProps } from 'antd-mobile';
import React from 'react';
import styles from './styles.module.scss';
import SvgIcon from 'components/shared/SVGIcon';

type Props = {
  text?: string;
  variant?: 'solid' | 'outline' | 'outline-orange' | 'high-light' | 'high-light-blue' | 'link';
  iconUrl?: string;
  iconPathFill?: string;
};

function SecondaryButton({
  text,
  variant = 'solid',
  iconUrl = '',
  iconPathFill = '',
  ...nest
}: Props & ButtonProps) {
  return (
    <Button
      className={`${styles.wrapper} ${
        variant === 'solid'
          ? styles.solid
          : variant === 'high-light'
          ? styles.highLight
          : variant === 'high-light-blue'
          ? styles.highLightBlue
          : variant === 'outline-orange'
          ? styles.outlineOrange
          : variant === 'link'
          ? styles.link
          : styles.outline
      }`}
      {...nest}
    >
      <div
        style={{
          display: 'flex',
          gap: 'var(--space-ultra-small)',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {iconUrl && (
          <SvgIcon
            pathFill={iconPathFill}
            src={iconUrl}
            style={{
              width: 16,
              height: 16,
            }}
          />
        )}
        <span>{text}</span>
      </div>
    </Button>
  );
}

export default React.memo(SecondaryButton);
