import React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { showData } from 'utils';

export function PtRegistration({ data }: { data: any }) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <p>
        {t('notify.hello')} <span className={styles.textBold}>{showData(data?.account?.name)}</span>
        ,
      </p>
      <p className={styles.block}>{t('notify.thank-you-for-workout')}</p>
      <p className={styles.block}>
        {t('notify.confirm-select-pt')}{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {showData(data?.pt?.name)}
        </span>
        .
      </p>
      <p className={styles.block}>{t('notify.confirm-with-pt')}</p>
    </div>
  );
}
