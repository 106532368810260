import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { isVN, showData } from 'utils';

export function DiscountExpiredSoon({ data }: { data?: any }) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <p>
        {t('notify.hello')} <span className={styles.textBold}>{showData(data?.account?.name)}</span>
        ,
      </p>
      <p className={styles.block}>
        {t('notify.exclusively-for-customer')}
        <span className={styles.textBold}>
          {' '}
          {showData(isVN() ? data?.campaign?.descriptionVi : data?.campaign?.descriptionEn)}
        </span>
      </p>

      <p className={styles.block}>
        {t('notify.enter-code')}{' '}
        <span className={`${styles.textBold} ${styles.haveSelect}`}>
          {showData(data?.coupon?.code)}
        </span>{' '}
        {t('notify.to-receive-offer')}
      </p>
      <p className={styles.block}>{t('notify.see-you-again')}</p>
    </div>
  );
}
