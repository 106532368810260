import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { showData } from 'utils';

export function PtCancelBooking({ data }: { data: any }) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <p>
        {t('notify.hello')} <span className={styles.textBold}>{showData(data?.account?.name)}</span>
        ,
      </p>
      <p className={styles.block}>
        {t('notify.pt-cancel-session-1')}{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {showData(data?.pt?.name)}
        </span>{' '}
        {t('notify.pt-cancel-session-2')}
      </p>
      <p className={styles.block}>{t('notify.contact-pt-receptionist')}</p>
    </div>
  );
}
