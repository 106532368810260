import { useParams } from 'react-router-dom';
import { THEME_COLOR } from 'constant';
import { SupportReportContainer } from 'containers/SupportContainer/SupportReportContainer';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { SecondaryLayout } from 'layouts';

type Props = {};

export default function SupportReportPage({}: Props) {
  const { reportType } = useParams<{ reportType: string }>();
  usePWATemplateColor(THEME_COLOR.WHITE);

  return (
    <SecondaryLayout
      title={`title.${reportType}`}
      titleColor="var(--primary-color)"
      arrowColor="var(--primary-color)"
      headerBackground="var(--blue-bg-color)"
      isHideFooter
      isHideHome
      isHideBottomSpace
      isWhiteBg
    >
      <SupportReportContainer />
    </SecondaryLayout>
  );
}
