import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { showData } from 'utils';

export function PtCancelBookingByUser({ data }: { data?: any }) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <p>
        {t('notify.hello')} <span className={styles.textBold}>{showData(data?.account?.name)}</span>
        ,
      </p>
      <p className={styles.block}>{t('notify.you-cancel-booking')} </p>
      <p className={styles.block}>{t('notify.contact-if-need-help')}</p>
    </div>
  );
}
