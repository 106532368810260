import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { BottomSpace, EmptyCard, TransactionHistoryCard } from 'components';
import { ContractType } from 'types';
import { UserService } from 'services';
import { delayNavigate, formatDate, formatTime, isSuccessCode, isVN } from 'utils';
import { E_CONTRACT_STATUS, E_PAYMENT_TYPE } from 'enum';
import { keyBy } from 'lodash';
import { useDirection } from 'hooks';
import { PLAN_NAME_MAPPING, ROUTES } from 'constant';
import { useTranslation } from 'react-i18next';
import LoadingDot from 'components/shared/LoadingDot';

type Props = {};

export default function MembershipTab({}: Props) {
  const { t } = useTranslation();
  const userService = new UserService();
  const [contracts, setContracts] = useState<ContractType[]>([]);
  const { goTo } = useDirection();
  const [isLoading, setIsLoading] = useState(false);

  const getListContract = async () => {
    setIsLoading(true);

    const response = await userService.getContracts({});
    const { data, code } = response;
    if (isSuccessCode(code)) {
      setContracts(data?.data || []);
    }
    delayNavigate(() => {
      setIsLoading(false);
    });
  };

  const parseInfo = (contract: ContractType) => {
    const paymentTransaction = keyBy(contract.contractTransactions, 'paymentType')[
      E_PAYMENT_TYPE.MEMBERSHIP_FEE
    ];

    const transactions = contract.contractTransactions;
    let amount = 0;
    transactions?.forEach((transaction) => {
      amount += Number(transaction.transactionAmount);
    });

    return {
      id: contract?.id,
      isSuccess: contract?.status !== E_CONTRACT_STATUS.WAIT_FOR_PAYMENT,
      totalPayment: Number(amount),
      planName:
        PLAN_NAME_MAPPING[contract?.paymentPlan?.type as keyof typeof PLAN_NAME_MAPPING] ||
        (isVN() ? contract?.paymentPlan?.nameVi : contract?.paymentPlan?.nameEn),
      paymentDate: paymentTransaction.purchaseDate,
    };
  };

  useEffect(() => {
    getListContract();
  }, []);

  return (
    <div className={styles.tabWrapper}>
      <div className={styles.listContract}>
        {isLoading ? (
          <LoadingDot />
        ) : contracts?.length === 0 ? (
          <EmptyCard text={t('paragraph.no-contract-found')} />
        ) : (
          contracts.map((contract) => {
            const info = parseInfo(contract);

            return (
              <TransactionHistoryCard
                key={info?.id}
                isSuccess={info?.isSuccess}
                price={info.totalPayment}
                name={info?.planName}
                createdDate={info?.paymentDate && formatDate(info?.paymentDate)}
                createdTime={info?.paymentDate && formatTime(info?.paymentDate)}
                onClick={goTo(
                  ROUTES.HISTORY_MEMBERSHIP_DETAIL.replace(':contractId', String(info?.id)),
                )}
              />
            );
          })
        )}
        <BottomSpace />
      </div>
    </div>
  );
}
