/* eslint-disable lines-around-comment */
import React from 'react';
import styles from './styles.module.scss';
import { Divider, Input } from 'antd-mobile';
import { useTranslation } from 'react-i18next';
import SvgIcon from 'components/shared/SVGIcon';

interface Props {
  value?: string;
  onChange?: (newValue: string) => void;
  errorMessage?: string;
}

function InputPhoneNumber({ value, onChange, errorMessage }: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <div
        className={styles.inputWrapper}
        style={{
          borderColor: errorMessage ? 'var(--high-light-color)' : 'var(--primary-text-color)',
        }}
      >
        <div className={styles.international}>
          <SvgIcon src="/images/icon/vn-flag.svg" />
          {/* <p className="font-sm color-black font-light">+84</p> */}
        </div>
        <Divider
          direction="vertical"
          style={{
            margin: '0 8px',
            borderColor: 'var(--secondary-text-color',
            height: '30px',
          }}
        />
        <Input
          clearable
          type="tel"
          value={value}
          onChange={onChange}
          style={{
            fontSize: '14px',
            color: 'var(--secondary-text-color)',
          }}
        />
      </div>
      {errorMessage && <p className="error-message">{t(`validate-message.${errorMessage}`)}</p>}
    </div>
  );
}

export default React.memo(InputPhoneNumber);
