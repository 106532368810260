import React from 'react';
import styles from './styles.module.scss';
import { SecondaryButton } from 'components/button';
import { SpaceProps } from 'antd-mobile';
import { useDirection } from 'hooks';
import { ROUTES } from 'constant';
import { getAccountType, showData } from 'utils';
import { useTranslation } from 'react-i18next';

type Props = {};

function HomeEmployeeCard({ ...rest }: Props & SpaceProps) {
  const { goTo } = useDirection();
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper} {...rest}>
      <p
        className="font-xl font-bold color-black"
        style={{
          textAlign: 'center',
          whiteSpace: 'pre-wrap',
        }}
      >
        {showData(getAccountType() ? t(`account-type.${getAccountType()}`) : '')}
      </p>
      <SecondaryButton
        text="Check-in"
        variant="high-light-blue"
        iconUrl="/images/icon/scan.svg"
        style={{
          width: '100%',
          maxWidth: '269px',
        }}
        onClick={goTo(ROUTES.CHECK_IN)}
      />
    </div>
  );
}

export default React.memo(HomeEmployeeCard);
