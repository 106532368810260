export enum STORAGE_KEY {
  ACCESS_TOKEN = 'access-token',
  ACCOUNT_ID = 'account-id',
  ACCOUNT_TYPE = 'account-type',
  NOTIFY_TOKEN = 'notify-token',
  LANGUAGE = 'language',
  APP_VERSION = 'app-version',
  IS_APP_OPENED = 'is-app-opened',
  MEMBERSHIP_TAB = 'membership-tab',
  PT_TAB = 'pt-tab',
  HISTORY_TAB = 'history-tab',
  SAVED_COUPON = 'saved-coupon',
  APP_HEIGHT = 'app-height',
  PHONE_NUMBER = 'phone-number',
}

const pathTabs = [STORAGE_KEY.HISTORY_TAB];

export class LocalStorage {
  setStorageItem(key: STORAGE_KEY, data: any) {
    try {
      localStorage.setItem(key, JSON.stringify(data));

      return true;
    } catch {
      return false;
    }
  }

  getStorageItem(key: STORAGE_KEY) {
    try {
      const data = localStorage.getItem(key);
      if (data) return JSON.parse(data);
      else return null;
    } catch {
      return null;
    }
  }

  clearStorageItem(key: STORAGE_KEY) {
    try {
      localStorage.removeItem(key);

      return true;
    } catch {
      return false;
    }
  }

  clearTabPath() {
    for (let i = 0; i < pathTabs.length; i++) localStorage.removeItem(pathTabs[i]);
  }

  clearAllStorage() {
    const clearKey = [
      STORAGE_KEY.ACCESS_TOKEN,
      STORAGE_KEY.ACCOUNT_ID,
      STORAGE_KEY.ACCOUNT_TYPE,
      STORAGE_KEY.IS_APP_OPENED,
      STORAGE_KEY.MEMBERSHIP_TAB,
      STORAGE_KEY.HISTORY_TAB,
      STORAGE_KEY.PT_TAB,
      STORAGE_KEY.SAVED_COUPON,
      STORAGE_KEY.PHONE_NUMBER,
    ];
    try {
      for (let i = 0; i < clearKey.length; i++) {
        localStorage.removeItem(clearKey[i]);
      }

      return true;
    } catch {
      return false;
    }
  }
}
