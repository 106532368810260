import {
  APIResponse,
  GetDetailPTReq,
  GetListPTReq,
  GetRegisteredPTContractReq,
  GetTransactionDetailReq,
} from 'types';
import { AxiosGW } from './axios.service';

export default class PTService {
  async getListPTInClub(payload: GetListPTReq) {
    const { clubId } = payload;

    return (await AxiosGW.get(`/api/v1/pt/club/${clubId}/all`)).data as APIResponse;
  }

  async getPTDetail(payload: GetDetailPTReq) {
    const { ptId } = payload;

    return (await AxiosGW.get(`/api/v1/pt/${ptId}/detail`)).data as APIResponse;
  }

  async getPTTransactionDetail(payload: GetTransactionDetailReq) {
    const { transactionId } = payload;

    return (await AxiosGW.get(`/api/v1/member/pt-contract/transaction/${transactionId}/detail`))
      .data as APIResponse;
  }

  async getPTContractDetail(payload: GetRegisteredPTContractReq) {
    const { ptContractId } = payload;

    return (await AxiosGW.get(`/api/v1/member/pt-contract/${ptContractId}/detail`))
      .data as APIResponse;
  }

  async getPTEContractPreview() {
    return (
      await AxiosGW.get(`/api/v1/member/pt-contract/e-contract/123/pdf`, {
        headers: {
          Accept: 'application/pdf',
        },
        responseType: 'blob',
      })
    ).data as Blob;
  }

  async signPTEContract(image: Blob) {
    const formData = new FormData();
    formData.append('image', image);

    return (await AxiosGW.put('/api/v1/member/pt-contract/e-contract/123/sign', formData))
      .data as APIResponse;
  }
}
