import React from 'react';
import styles from './styles.module.scss';
import { Skeleton } from 'antd-mobile';
import { showData } from 'utils';
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';

type Props = {
  pin?: string;
  clubName?: string;
  isBlackCard?: boolean;
  planName?: string;
  isSkeleton?: boolean;
};

function CheckinCard({
  isBlackCard = false,
  isSkeleton = false,
  pin = '',
  clubName = '',
  planName = '',
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.content}>
      <div className={styles.info}>
        {isSkeleton ? (
          <Skeleton
            animated
            style={{
              height: 'var(--font-md)',
              width: '200px',
            }}
          />
        ) : (
          <p
            className="font-lg font-medium"
            style={{
              color: isBlackCard ? 'var(--white-text-color)' : 'var(--primary-color)',
            }}
          >
            {showData(clubName)}
          </p>
        )}
      </div>

      <div
        className={styles.qrBlock}
        style={{
          position: 'relative',
          background: 'var(--white-text-color)',
          border: '6px solid',
          borderColor: isSkeleton
            ? 'transparent'
            : isBlackCard
            ? 'var(--black-color)'
            : ' var(--primary-color)',
        }}
      >
        {!isBlackCard && !isSkeleton && (
          <div
            style={{
              backgroundImage: 'url(/images/support-background/sp-bg-02.png)',
              backgroundSize: 'auto',
              backgroundRepeat: 'no-repeat',
              top: '-30px',
              left: '-36px',
              position: 'absolute',
              width: '350px',
              height: '350px',
              zIndex: '-1',
            }}
          />
        )}
        <div
          className={styles.wrapperQr}
          style={{
            background: 'transparent',
          }}
        >
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '12px',
              }}
            />
          ) : (
            <QRCode
              value={String(pin)}
              style={{ height: '100%', width: '100%' }}
              bgColor={'transparent'}
              fgColor={isBlackCard ? 'var(--black-color)' : 'var(--primary-color)'}
              viewBox={`0 0 80 80`}
              size={80}
            />
          )}
        </div>
      </div>
      <div className={styles.extendInfo}>
        <div className={styles.info}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-xl)',
                width: '150px',
              }}
            />
          ) : (
            <p
              className="font-xl font-medium"
              style={{
                color: isBlackCard ? 'var(--white-text-color)' : 'var(--primary-color)',
              }}
            >
              {showData(pin)}
            </p>
          )}
        </div>
        {isSkeleton ? (
          <Skeleton
            className={styles.membershipName}
            animated
            style={{
              height: '36px',
            }}
          />
        ) : (
          <p
            className={styles.membershipName}
            style={{
              color: isBlackCard ? 'var(--white-text-color)' : 'var(--primary-text-color)',
            }}
          >
            {t('title.membership-plan', { planName: showData(planName) })}
          </p>
        )}
      </div>
    </div>
  );
}

export default React.memo(CheckinCard);
