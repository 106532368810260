import React from 'react';
import styles from './styles.module.scss';
import { Image, Skeleton } from 'antd-mobile';
import { formatTime, showData } from 'utils';
import { useTranslation } from 'react-i18next';

type Props = {
  thumbnail?: string;
  name?: string;
  durationMinute?: number;
  begin?: string;
  isSkeleton?: boolean;
  onClick?: () => void;
};

function MiniClassCard2({ thumbnail, name, durationMinute = 0, begin, isSkeleton = false }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.cardWrapper}>
      {isSkeleton ? (
        <Skeleton className={styles.thumbnail} animated />
      ) : (
        <div className={styles.thumbnail}>
          <Image alt="" src={thumbnail} />
        </div>
      )}
      <div className={styles.content}>
        {isSkeleton ? (
          <Skeleton.Paragraph
            animated
            style={{
              width: '100%',
            }}
          />
        ) : (
          <>
            <p className="font-sm font-medium color-black">{showData(name)}</p>
            <p className="font-xs font-light color-black">{`${durationMinute} ${t(
              'title.minute',
            )} | ${showData(formatTime(begin))}`}</p>
          </>
        )}
      </div>
    </div>
  );
}

export default React.memo(MiniClassCard2);
