import { useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useLocation, useRoutes } from 'react-router';
import Router from './routes/Router';
import { ToastLoadingOverlay, ToastOverlay } from 'components';
import { useSelector } from 'react-redux';
import { commonSelector } from 'stores/common';
import { getVersion, LocalStorage, resetScroll, STORAGE_KEY } from 'utils';
import 'moment/locale/en-au';
import 'moment/locale/vi';
import { GeneralService } from 'services';
import { Dialog } from 'antd-mobile';
import { useTranslation } from 'react-i18next';

const Storage = new LocalStorage();

function App() {
  const { t } = useTranslation();
  const routing = useRoutes(Router);
  const { isShowToast, toastContent, isShowLoadingToast } = useSelector(commonSelector);
  const { pathname } = useLocation();
  const generalService = new GeneralService();

  const initCallDispatch = async () => {
    const response = await generalService.getAppVersion();
    const { data } = response;

    if (!getVersion()) {
      Storage.setStorageItem(STORAGE_KEY.APP_VERSION, data.settingValueEn);
    } else if (getVersion() != data.settingValueEn) {
      Dialog.alert({
        title: (
          <p className="font-md color-black font-bold">
            {t('paragraph.update-new-version')} {data.settingValueEn}
          </p>
        ),
        content: (
          <p
            className="font-sm color-black font-light"
            style={{
              textAlign: 'center',
            }}
          >
            {t('paragraph.reload-to-update')}
          </p>
        ),
        confirmText: t('button.update'),
        onConfirm: () => {
          Storage.setStorageItem(STORAGE_KEY.APP_VERSION, data.settingValueEn);
          window.location.reload();
        },
        maskStyle: {
          background: 'rgba(0, 159, 194, 0.20)',
        },
        bodyStyle: {
          boxShadow: 'var(--shadow-01)',
        },
      });
    }
  };

  useEffect(() => {
    resetScroll();
    initCallDispatch();
  }, [pathname]);

  return (
    <>
      <ToastLoadingOverlay isOpen={isShowLoadingToast} />
      <ToastOverlay isOpen={isShowToast} isError={toastContent.isError} code={toastContent.code} />
      <AnimatePresence exitBeforeEnter>{routing}</AnimatePresence>
    </>
  );
}

export default App;
