import React from 'react';
import styles from './styles.module.scss';
import { Image, Skeleton } from 'antd-mobile';
import { CustomSwitch } from 'components/shared';
import { useTranslation } from 'react-i18next';

type Props = {
  iconCard?: string;
  cardNumber?: string;
  isDefault?: boolean;
  isSkeleton?: boolean;
  onActiveDefault?: (active: boolean) => void;
};

export default function DetailCardInfo({
  iconCard,
  cardNumber,
  isDefault = false,
  isSkeleton = false,
  onActiveDefault,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.cardWrapper}>
      {isSkeleton ? (
        <Skeleton
          className={styles.iconCard}
          animated
          style={{
            height: '30px',
          }}
        />
      ) : (
        <Image className={styles.iconCard} src={iconCard} />
      )}
      {isSkeleton ? (
        <Skeleton
          animated
          style={{
            height: 'var(--font-md)',
          }}
        />
      ) : (
        <p className="font-md color-dark-black font-medium">{cardNumber}</p>
      )}
      {isSkeleton ? (
        <Skeleton
          animated
          style={{
            height: 'var(--font-lg)',
          }}
        />
      ) : (
        <div className={styles.cardAction}>
          <p className="font-sm color-dark-black font-light">{t('title.set-card-as-default')}</p>
          <CustomSwitch isChecked={isDefault} onChange={onActiveDefault} />
        </div>
      )}
    </div>
  );
}
