import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { generateClubName, isVN, showData } from 'utils';

export function ContractExpiredToday({ data }: { data?: any }) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <p>
        {t('notify.hello')} <span className={styles.textBold}>{showData(data?.account?.name)}</span>
        ,
      </p>
      <p className={styles.block}>
        {t('notify.your-membership-will-expired')}{' '}
        <span className={styles.textBold}>{t('notify.expired-today')}!</span>
      </p>

      <p className={styles.block}>
        {t('notify.you-need-to')}{' '}
        <span className={styles.textBold}>{t('notify.renew-to-day')}</span>{' '}
        {t('notify.to-avoid-joining-fee')}
      </p>

      <p className={styles.block}>{t('notify.login-into-app-to-extend')}</p>

      <p className={styles.block}>
        - {t('notify.package-name')}:{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {showData(isVN() ? data?.paymentPlan?.nameVi : data?.paymentPlan?.nameEn)}
        </span>
      </p>
      <p>
        - {t('notify.club')}:{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {generateClubName(isVN() ? data?.club?.nameVi : data?.club?.nameEn)}
        </span>
      </p>
      <p>
        - {t('notify.address')}:{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {showData(data?.club?.location)}
        </span>
      </p>

      <p className={styles.block}>{t('notify.see-you-again')}</p>
    </div>
  );
}
