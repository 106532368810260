import React from 'react';
import styles from './styles.module.scss';
import { IoChevronForward } from 'react-icons/io5';

type SupportItemProps = {
  text: string;
  icon: React.ReactNode;
  onClick?: () => void;
};

const SupportItem = ({ text, icon, onClick }: SupportItemProps) => {
  return (
    <div className={styles.item} onClick={onClick}>
      <div className={styles.icon}>{icon}</div>
      <p className="font-sm color-black">{text}</p>
      <IoChevronForward color="var(--primary-color)" fontSize="20px" />
    </div>
  );
};

export default SupportItem;
