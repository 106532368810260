import { THEME_COLOR } from 'constant';
import { VerifyPhoneNumberContainer } from 'containers';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { SecondaryLayout } from 'layouts';

type Props = {};

export default function VerifyPhoneNumberPage({}: Props) {
  usePWATemplateColor(THEME_COLOR.MAIN);

  return (
    <SecondaryLayout
      title="title.verify-otp"
      titleColor="var(--primary-color)"
      arrowColor="var(--primary-color)"
      headerBackground="var(--blue-bg-color)"
      isHideFooter
      isHideHome
      isHideBottomSpace
      isWhiteBg
      isHideSlogan
    >
      <VerifyPhoneNumberContainer />
    </SecondaryLayout>
  );
}
