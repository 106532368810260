import { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { ReactSVG } from 'react-svg';
import { Image, Skeleton } from 'antd-mobile';
import ConfirmModal from 'components/modal/ConfirmModal';
import PTPlanCard from 'components/card/PTPlanCard';
import { delayNavigate, getImageUrl, isSuccessCode, isVN, showData } from 'utils';
import { ROUTES } from 'constant';
import { E_PT_CONTRACT_STATUS } from 'enum';
import { getPTPackagesAction, globalSelector } from 'stores/global';
import { PTType } from 'types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { UserService } from 'services';
import PTService from 'services/pt.service';
import { useDirection, useDisclosure } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'stores';
import _ from 'lodash';
import LoadingDot from 'components/shared/LoadingDot';

type Props = {};

export function PTPlanContainer({}: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { goTo } = useDirection();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const ptService = new PTService();
  const userService = new UserService();
  const { ptId } = useParams();
  const { ptPackages } = useSelector(globalSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPT, setIsLoadingPT] = useState(false);
  const [isLoadingRegister, setIsLoadingRegister] = useState(false);
  const loadingIdRef = useRef('');
  const [ptDetail, setPtDetail] = useState<PTType>();
  const [confirmPackage, setConfirmPackage] = useState('');

  const getListPTPackage = async () => {
    if (_.isEmpty(ptPackages)) {
      setIsLoading(true);
      await dispatch(getPTPackagesAction());
      delayNavigate(() => setIsLoading(false));
    }
  };

  const getPTDetail = async () => {
    setIsLoadingPT(true);
    const response = await ptService.getPTDetail({
      ptId: String(ptId),
    });
    const { code, data } = response;
    if (isSuccessCode(code)) {
      setPtDetail(data);
    }
    delayNavigate(() => setIsLoadingPT(false));
  };

  const registerPTPackage = async () => {
    setIsLoadingRegister(true);
    const response = await userService.registerPTContract({
      ptId: String(ptId),
      ptPackageId: String(loadingIdRef.current),
    });
    const { code } = response;
    if (isSuccessCode(code)) {
      setConfirmPackage(String(loadingIdRef.current));
    }
    delayNavigate(() => setIsLoadingRegister(false));
  };

  const onOpenConfirm = (packageId: string) => () => {
    loadingIdRef.current = packageId;
    onOpen();
  };

  const getStatus = (ptPackageId: string) => {
    const contract = ptDetail?.ptContracts;
    if (confirmPackage === ptPackageId) return E_PT_CONTRACT_STATUS.WAITPTCONFIRM;
    if (!_.isEmpty(confirmPackage) && confirmPackage !== ptPackageId)
      return E_PT_CONTRACT_STATUS.REGISTER;
    if (
      ptPackageId === contract?.ptPackageId &&
      contract.status === E_PT_CONTRACT_STATUS.WAITPTCONFIRM
    ) {
      return E_PT_CONTRACT_STATUS.WAITPTCONFIRM;
    }
    if (
      ptPackageId === contract?.ptPackageId &&
      contract.status === E_PT_CONTRACT_STATUS.WAITFORPAYMENT
    ) {
      return E_PT_CONTRACT_STATUS.WAITFORPAYMENT;
    }

    return E_PT_CONTRACT_STATUS.REGISTER;
  };

  useEffect(() => {
    getListPTPackage();
    getPTDetail();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.infoWrapper}>
        <div className={styles.ptInfo}>
          <div className={styles.item}>
            <ReactSVG className={styles.icon} src="/images/icon/pin-blue.svg" />
            {isLoadingPT ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-sm)',
                }}
              />
            ) : (
              <p className="font-sm font-bold color-black">
                {showData(isVN() ? ptDetail?.club?.nameVi : ptDetail?.club?.nameEn)}
              </p>
            )}
          </div>
          <div className={styles.item}>
            <ReactSVG className={styles.icon} src="/images/icon/card.svg" />
            {isLoadingPT ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-sm)',
                }}
              />
            ) : (
              <p className="font-sm font-bold color-black">
                {showData(
                  [ptDetail?.accountDetail?.firstName, ptDetail?.accountDetail?.lastName].join(' '),
                )}
              </p>
            )}
          </div>
          <div className={styles.item}>
            <ReactSVG className={styles.icon} src="/images/icon/group.svg" />
            {isLoadingPT ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-sm)',
                }}
              />
            ) : (
              <p className="font-sm font-bold color-black">
                {showData(ptDetail?.ptGroup?.groupName)}
              </p>
            )}
          </div>
          <div className={styles.item}>
            <ReactSVG className={styles.icon} src="/images/icon/star-user.svg" />
            {isLoadingPT ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-sm)',
                }}
              />
            ) : (
              <p className="font-sm font-bold color-black">
                {showData(
                  ptDetail?.ratingAverage && Math.round(Number(ptDetail?.ratingAverage) * 10) / 10,
                )}
                /5
              </p>
            )}
          </div>
          <div className={styles.image}>
            {isLoadingPT ? (
              <Skeleton
                animated
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            ) : (
              <Image
                width="100%"
                height="100%"
                src={getImageUrl(String(ptDetail?.accountDetail?.avatar))}
              />
            )}
          </div>
        </div>
      </div>

      <div className={styles.planList}>
        {isLoading || isLoadingPT ? (
          <LoadingDot />
        ) : (
          ptPackages?.map((ptPackage) => {
            return (
              <PTPlanCard
                key={ptPackage?.id}
                name={isVN() ? ptPackage?.nameVi : ptPackage?.nameEn}
                status={getStatus(String(ptPackage?.id))}
                isLoadingRegister={isLoadingRegister && loadingIdRef.current === ptPackage?.id}
                isDisabledRegister={isLoadingRegister}
                isSkeleton={isLoadingPT}
                onRegister={onOpenConfirm(String(ptPackage?.id))}
                onPayment={goTo(
                  ROUTES.PT_PAYMENT_PLAN.replace(':ptId', String(ptId)).replace(
                    ':contractId',
                    String(ptDetail?.ptContracts?.id),
                  ),
                )}
              />
            );
          })
        )}
      </div>
      <ConfirmModal
        confirmTitle={t('title.confirm')}
        confirmContent={t('paragraph.register-pt-confirm')}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={registerPTPackage}
      />
    </div>
  );
}
