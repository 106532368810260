/* eslint-disable lines-around-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import styles from './styles.module.scss';
import { SecondaryButton } from 'components/button';
import { useTranslation } from 'react-i18next';
import { Image, ImageViewer, Skeleton } from 'antd-mobile';
import { useDisclosure } from 'hooks';
import { getImageUrl, showData } from 'utils';

type Props = {
  onPayment?: () => void;
  onRegister?: () => void;
  ptName?: string;
  groupName?: string;
  imageUrl?: string;
  isSkeleton?: boolean;
  isHaveContract?: boolean;
};

function PTCard({
  ptName,
  groupName,
  isSkeleton = false,
  isHaveContract,
  onRegister,
  onPayment,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.blockContent}>
        <div className={styles.info}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-md)',
                width: '100%',
              }}
            />
          ) : (
            <p className="font-md color-black font-bold">{showData(ptName)}</p>
          )}
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-sm)',
                width: '100%',
              }}
            />
          ) : (
            <p className="font-sm color-black">{showData(groupName)}</p>
          )}
        </div>
        {isHaveContract ? (
          <SecondaryButton variant="high-light" text={t('title.payment')} onClick={onPayment} />
        ) : (
          <SecondaryButton
            disabled={isSkeleton}
            text={t('title.register')}
            variant="high-light-blue"
            onClick={onRegister}
          />
        )}
      </div>
    </div>
  );
}

export default React.memo(PTCard);
