import { APIResponse, IdCardInfo, SendOTPLoginReq, SignUpReq, VerifyOTPLoginReq } from 'types';
import { AxiosGW } from './axios.service';

export default class AuthService {
  async sendOTPLogin(payload: SendOTPLoginReq) {
    return (await AxiosGW.post('/api/v1/auth/otp/send', payload)).data as APIResponse;
  }

  async sendOTPChangePhoneNumber(payload: SendOTPLoginReq) {
    return (await AxiosGW.post('/api/v1/auth/otp/send/phone-number/new', payload))
      .data as APIResponse;
  }

  async verifyOTPLogin(payload: VerifyOTPLoginReq) {
    return (await AxiosGW.post('/api/v1/auth/otp/login', payload)).data as APIResponse;
  }

  async verifyOTPChangePhoneNumber(payload: VerifyOTPLoginReq) {
    return (await AxiosGW.put('/api/v1/auth/account/phone-number/change', payload))
      .data as APIResponse;
  }

  async signUp(payload: SignUpReq) {
    return (await AxiosGW.post('/api/v1/auth/register', { ...payload, fullName: ' ' }))
      .data as APIResponse;
  }

  async getProfile() {
    return (await AxiosGW.get('/api/v1/auth/profile')).data as APIResponse;
  }

  async confirmProfile(payload: IdCardInfo) {
    return (await AxiosGW.put('/api/v1/auth/profile', payload)).data as APIResponse;
  }

  async updateAvatar(image: Blob) {
    const formData = new FormData();
    formData.append('image', image);

    return (await AxiosGW.put('/api/v1/auth/profile/avatar', formData)).data as APIResponse;
  }
}
