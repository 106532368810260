import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import {
  formatDateTime,
  formatPrice,
  generateClubName,
  isVN,
  joinAddressNotify,
  showData,
} from 'utils';

export function MembershipRegistration({ data }: { data: any }) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <p>
        {t('notify.hello')} <span className={styles.textBold}>{showData(data?.account?.name)}</span>
        ,
      </p>
      <p className={styles.block}>{t('notify.thank-you-for-register')}</p>
      <p className={styles.block}>{t('notify.membership-info')}:</p>
      <p>
        - {t('notify.package-name')}:{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {showData(isVN() ? data?.paymentPlan?.nameVi : data?.paymentPlan?.nameEn)}
        </span>
      </p>
      <p className={styles.block}>
        - {t('notify.club')}:{' '}
        <span
          className={`${styles.textBold} ${styles.textUppercase}`}
          style={{
            textTransform: 'uppercase',
          }}
        >
          {generateClubName(isVN() ? data?.club?.nameVi : data?.club?.nameEn)}
        </span>
      </p>
      <p>
        - {t('notify.address')}:{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {joinAddressNotify(data?.club)}
        </span>
      </p>
      <p className={styles.block}>
        - {t('notify.membership-fee')}:{' '}
        <span className={styles.textBold}>{formatPrice(Number(data?.memberShipFee), true)}</span>
      </p>
      <p>
        - {t('notify.joining-fee')}:{' '}
        <span className={styles.textBold}>
          {Number(data?.joiningFee) === 0
            ? t('title.free')
            : formatPrice(Number(data?.joiningFee), true)}
        </span>
      </p>
      <p className={styles.block}>
        - {t('notify.please-payment-before')}:{'\n'}
        <span className={styles.textBold}>{formatDateTime(data?.expiredAt)}</span>
      </p>
      <p className={styles.block}>{t('notify.welcome')}</p>
    </div>
  );
}
