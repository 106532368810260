import styles from './styles.module.scss';
import { useDirection } from 'hooks';
import { useTranslation } from 'react-i18next';
import { LocalStorage, showData } from 'utils';
import { ROUTES } from 'constant';
import { useLocation } from 'react-router';
import SvgIcon from 'components/shared/SVGIcon';

type Props = {
  title?: string;
  hideBack?: boolean;
  isWhiteBg: boolean;
  hideHome?: boolean;
  headerBackground?: string;
  titleColor?: string;
  arrowColor?: string;
  backgroundColor?: string;
  backUrl?: string;
};

const RESET_PATH = [ROUTES.MEMBERSHIP];

const Storage = new LocalStorage();

export default function Header({
  title = '',
  hideBack = false,
  hideHome = false,
  headerBackground = 'var(--blue-bg-color)',
  titleColor = 'var(--primary-color)',
  arrowColor = 'var(--white-text-color)',
  backUrl = '',
}: Props) {
  const { goBack, goToReplace } = useDirection();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const handleGoBack = () => {
    if (RESET_PATH.includes(pathname)) {
      Storage.clearTabPath();
    }
    if (backUrl) {
      goToReplace(backUrl);
    } else {
      goBack();
    }
  };

  const handleGoHome = () => {
    if (RESET_PATH.includes(pathname)) {
      Storage.clearTabPath();
    }
    goToReplace(ROUTES.HOME);
  };

  return (
    <div
      className={styles.headerWrapper}
      style={{
        background: headerBackground,
      }}
    >
      <div className={styles.title}>
        <p
          className="font-xl font-bold"
          style={{
            color: titleColor,
          }}
        >
          {title && t(showData(title))}
        </p>
      </div>
      {!hideBack && (
        <div
          style={{
            position: 'absolute',
            left: '24px',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: '100',
            width: '30px',
            marginTop: '2px',
          }}
          onClick={handleGoBack}
        >
          <SvgIcon
            src="/images/icon/left-arrow.svg"
            pathFill={arrowColor}
            style={{
              width: '15px',
              height: '23px',
            }}
          />
        </div>
      )}
      {!hideHome && (
        <div
          style={{
            position: 'absolute',
            right: '24px',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: '100',
          }}
          onClick={handleGoHome}
        >
          <SvgIcon
            src="/images/icon/home-blue.svg"
            pathFill={arrowColor}
            style={{
              width: '20px',
              height: '20px',
            }}
          />
        </div>
      )}
    </div>
  );
}
