import { APIResponse, CommonResType } from 'types';
import { AxiosGW } from './axios.service';

export default class NotifyService {
  async getNotificationsPaging(payload: CommonResType) {
    const { limit, page } = payload;

    return (
      await AxiosGW.get(`/api/v1/notifications`, {
        params: { limit, page },
        isDisableLoading: true,
        isDisableToast: true,
      })
    ).data as APIResponse;
  }

  async getNotificationDetail(payload: CommonResType) {
    const { notificationId } = payload;

    return (
      await AxiosGW.get(`/api/v1/notification/${notificationId}/detail`, {
        isDisableLoading: true,
        isDisableToast: true,
      })
    ).data as APIResponse;
  }

  async readNotify(payload: CommonResType) {
    const { notificationId } = payload;

    return (
      await AxiosGW.put(`/api/v1/notification/${notificationId}/read`, null, {
        isDisableLoading: true,
        isDisableToast: true,
      })
    ).data as APIResponse;
  }

  async registerFcmToken(payload: { fcmToken: string }) {
    const { fcmToken } = payload;

    return AxiosGW.post(
      `/api/v1/notification/fcm/register`,
      { fcmToken },
      {
        isDisableLoading: true,
        isDisableToast: true,
      },
    );
  }

  async unRegisterFcmToken(payload: { fcmToken: string }) {
    const { fcmToken } = payload;

    return AxiosGW.post(
      `/api/v1/notification/fcm/unregister`,
      { fcmToken },
      {
        isDisableLoading: true,
        isDisableToast: true,
      },
    );
  }

  async countUnreadNotify() {
    return (
      await AxiosGW.get(`/api/v1/notifications/unread/count`, {
        isDisableLoading: true,
        isDisableToast: true,
      })
    ).data as APIResponse;
  }

  async readAll() {
    return AxiosGW.post(`/api/v1/notifications/read/all`, {
      isDisableLoading: true,
    });
  }
}
