import {
  getCityHaveClubAction,
  getFreezeDurationAction,
  getFreezePriceAction,
  getListClubAction,
  getPaymentMethodAction,
} from './actions';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { APIResponse, ClubType } from 'types';
import { CityType, FreezeConfigType, PaymentMethodType } from 'types/general.type';
import { isSuccessCode } from 'utils';

interface State {
  listClub: ClubType[];
  location?: { lat: number; long: number };
  clubDistanceMapping: {
    [key: string]: {
      id: string;
      distance: number;
    };
  };
  cityHaveClub: CityType[];
  paymentMethods?: PaymentMethodType[];
  freezePriceConfig?: FreezeConfigType;
  freezeDurationConfig?: FreezeConfigType;
}

const initialState: State = {
  listClub: [],
  location: undefined,
  clubDistanceMapping: {},
  cityHaveClub: [],
  paymentMethods: [],
  freezePriceConfig: {},
  freezeDurationConfig: {},
};

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setLocation: (state: State, action) => {
      state.location = action.payload;
    },
    seDistanceMapping: (state: State, action) => {
      state.clubDistanceMapping = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCityHaveClubAction.fulfilled,
      (state, action: PayloadAction<APIResponse>) => {
        if (isSuccessCode(action.payload.code)) {
          state.cityHaveClub = action.payload.data;
        }
      },
    );
    builder.addCase(
      getPaymentMethodAction.fulfilled,
      (state, action: PayloadAction<APIResponse>) => {
        if (isSuccessCode(action.payload.code)) {
          state.paymentMethods = action.payload.data;
        }
      },
    );
    builder.addCase(getFreezePriceAction.fulfilled, (state, action: PayloadAction<APIResponse>) => {
      if (isSuccessCode(action.payload.code)) {
        state.freezePriceConfig = action.payload.data;
      }
    });
    builder.addCase(
      getFreezeDurationAction.fulfilled,
      (state, action: PayloadAction<APIResponse>) => {
        if (isSuccessCode(action.payload.code)) {
          state.freezeDurationConfig = action.payload.data;
        }
      },
    );

    builder.addCase(getListClubAction.fulfilled, (state, action: PayloadAction<APIResponse>) => {
      if (isSuccessCode(action.payload.code)) {
        state.listClub = action.payload.data;
      }
    });
  },
});

export const { setLocation, seDistanceMapping } = generalSlice.actions;
export default generalSlice.reducer;
