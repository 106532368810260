import { ROUTES, THEME_COLOR } from 'constant';
import { PaymentFreezeContainer } from 'containers';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { SecondaryLayout } from 'layouts';

type Props = {};

export default function PaymentFreezePage({}: Props) {
  usePWATemplateColor(THEME_COLOR.WHITE);

  return (
    <SecondaryLayout
      title="title.freeze"
      headerBackground="var(--blue-bg-color)"
      titleColor="var(--primary-color)"
      arrowColor="var(--primary-color)"
      isHideFooter
      isHideHome
      isWhiteBg
      isHideBottomSpace
      backUrl={ROUTES.MEMBERSHIP}
    >
      <PaymentFreezeContainer />
    </SecondaryLayout>
  );
}
