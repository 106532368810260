import { BottomSpace, EmptyCard, PTCard } from 'components';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { delayNavigate, isSuccessCode, isVN, resetScrollElement } from 'utils';
import _ from 'lodash';
import { useDirection } from 'hooks';
import PTService from 'services/pt.service';
import { ClubType, PTType } from 'types';
import { ROUTES } from 'constant';
import Selection from 'components/input/Selection';
import { E_PT_CONTRACT_STATUS } from 'enum';
import { UserService } from 'services';
import LoadingDot from 'components/shared/LoadingDot';

type Props = {};

export function ChoosePTContainer({}: Props) {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const ptService = new PTService();
  const userService = new UserService();
  const [listPT, setListPT] = useState<PTType[]>([]);
  const [listAccessClub, setListAccessClub] = useState<ClubType[]>([]);
  const [selectedClub, setSelectedClub] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPT, setIsLoadingPT] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  const initCallAPI = async () => {
    setIsLoading(true);
    await getListAccessClub();
    delayNavigate(() => setIsLoading(false));
  };

  const getListAccessClub = async () => {
    const response = await userService.getContractAccessClub();
    const { code, data } = response;
    if (isSuccessCode(code)) {
      if (data?.length > 0) {
        setListAccessClub(data);
        setSelectedClub(data[0]?.id || null);
      } else {
        setIsEmpty(true);
      }
    }
  };

  const getListPT = async (clubId: string) => {
    setIsLoadingPT(true);
    const response = await ptService.getListPTInClub({
      clubId: clubId,
    });
    const { code, data } = response;
    if (isSuccessCode(code)) {
      setListPT(data);
    } else {
      setListPT([]);
    }
    delayNavigate(() => setIsLoadingPT(false));
  };

  const handleChangeClub = (clubId: number | null) => {
    resetScrollElement('pt-list');
    setSelectedClub(clubId);
  };

  useEffect(() => {
    if (selectedClub) {
      getListPT(String(selectedClub));
    }
  }, [selectedClub]);

  useEffect(() => {
    initCallAPI();
  }, []);

  return (
    <div className={styles.container}>
      {!isLoading && isEmpty ? (
        <EmptyCard text={t('paragraph.no-membership-pt')} />
      ) : (
        <>
          <div className={styles.headerContainer}>
            <Selection
              options={(listAccessClub || [])?.map((club) => ({
                id: Number(club?.id),
                title: String(isVN() ? club?.nameVi : club?.nameEn),
              }))}
              isSkeleton={isLoading}
              selectedId={selectedClub}
              onChangeCity={handleChangeClub}
              clearable={false}
              iconUrl="/images/icon/building.svg"
            />
          </div>

          <div id="pt-list" className={styles.ptList}>
            {isLoadingPT ? (
              <LoadingDot />
            ) : (
              listPT.map((pt) => {
                return (
                  <PTCard
                    key={pt.id}
                    ptName={[pt.accountDetail?.firstName, pt.accountDetail?.lastName].join(' ')}
                    groupName={pt.ptGroup?.groupName}
                    isHaveContract={
                      !_.isEmpty(pt.ptContracts) &&
                      pt.ptContracts.status === E_PT_CONTRACT_STATUS.WAITFORPAYMENT
                    }
                    onRegister={goTo(ROUTES.PT_PLAN.replace(':ptId', String(pt.id)))}
                    onPayment={goTo(
                      ROUTES.PT_PAYMENT_PLAN.replace(':ptId', String(pt.id)).replace(
                        ':contractId',
                        String(pt?.ptContracts?.id),
                      ),
                    )}
                  />
                );
              })
            )}
            <BottomSpace />
          </div>
        </>
      )}
    </div>
  );
}
