import React from 'react';
import styles from './styles.module.scss';
import { showData } from 'utils';

type Props = {
  text?: string;
};

function EmptyCard({ text }: Props) {
  return (
    <div className={styles.cardWrapper}>
      <p
        className="font-sm color-black"
        style={{
          textAlign: 'center',
        }}
      >
        {showData(text)}
      </p>
    </div>
  );
}

export default React.memo(EmptyCard);
