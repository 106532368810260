import { PasscodeInput } from 'antd-mobile';
import styles from './styles.module.scss';

type Props = {
  onComplete: (newValue: string) => void;
  isError?: boolean;
};

export default function PinInput({ onComplete, isError = false }: Props) {
  return (
    <PasscodeInput
      className={`${styles.inputCode} ${isError ? styles.error : ''}`}
      onFill={onComplete}
      seperated
      plain
      length={4}
      style={{
        '--cell-gap': '19px',
      }}
    />
  );
}
