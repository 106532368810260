import { THEME_COLOR } from 'constant';
import { NotificationDetailContainer } from 'containers';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { SecondaryLayout } from 'layouts';

export default function NotificationDetailPage() {
  usePWATemplateColor(THEME_COLOR.WHITE);

  return (
    <SecondaryLayout
      title="title.notification"
      headerBackground="var(--blue-bg-color)"
      titleColor="var(--primary-color)"
      arrowColor="var(--primary-color)"
      backgroundColor="var(--primary-color)"
      isHideFooter
      isHideHeader
      isHideHome
      isWhiteBg
      isHideBottomSpace
    >
      <NotificationDetailContainer />
    </SecondaryLayout>
  );
}
