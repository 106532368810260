import React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { generateClubName, isVN, showData } from 'utils';

export function PtExperience({ data }: { data?: any }) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <p>
        {t('notify.hello')} <span className={styles.textBold}>{showData(data?.account?.name)}</span>
        ,
      </p>
      <p className={styles.block}>{t('notify.free-session-notice')}</p>
      <p className={styles.block}>{t('notify.contact-pt-receptionist-at')}:</p>
      <p>
        - {t('notify.club')}:{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {generateClubName(isVN() ? data?.club?.nameVi : data?.club?.nameEn)}
        </span>
      </p>
      <p>
        - {t('notify.address')}:{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {showData(data?.club?.location)}
        </span>
      </p>
      <p className={styles.block}>{t('notify.thank-you-hope')}</p>
    </div>
  );
}
