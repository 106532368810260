import { THEME_COLOR } from 'constant';
import { ChangePhoneNumberContainer } from 'containers';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { SecondaryLayout } from 'layouts';

type Props = {};

export default function ChangePhoneNumberPage({}: Props) {
  usePWATemplateColor(THEME_COLOR.MAIN);

  return (
    <SecondaryLayout
      title="title.change-phone-number"
      titleColor="var(--primary-color)"
      arrowColor="var(--primary-color)"
      headerBackground="var(--blue-bg-color)"
      isHideFooter
      isHideHome
      isHideBottomSpace
      isWhiteBg
      isHideSlogan
    >
      <ChangePhoneNumberContainer />
    </SecondaryLayout>
  );
}
