import React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { formatDate, generateClubName, isVN, showData } from 'utils';

export function MembershipReminder({ data }: { data: any }) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <p>
        {t('notify.hello')} <span className={styles.textBold}>{showData(data?.account?.name)}</span>
        ,
      </p>
      <p className={styles.block}>{t('notify.greeting-from-tng-team')}</p>
      <p className={styles.block}>
        {t('notify.membership-expired-today')}{' '}
        <span className={styles.textBold}>{formatDate(data?.contract?.endDate)}</span>
      </p>

      <p className={styles.block}>{t('notify.please-login-extend')}</p>

      <p className={styles.block}>
        - {t('notify.package-name')}:{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {showData(isVN() ? data?.paymentPlan?.nameVi : data?.paymentPlan?.nameEn)}
        </span>
      </p>
      <p>
        - {t('notify.club')}:{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {generateClubName(isVN() ? data?.club?.nameVi : data?.club?.nameEn)}
        </span>
      </p>
      <p>
        - {t('notify.address')}:{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {showData(data?.club?.location)}
        </span>
      </p>

      <p className={styles.block}>{t('notify.see-you-again')}</p>
    </div>
  );
}
