import React from 'react';
import styles from './styles.module.scss';
import { Image, Skeleton } from 'antd-mobile';

type Props = {
  thumbnail?: string;
  isSkeleton?: boolean;
  onClick?: () => void;
};

function MiniClassCard({ thumbnail, isSkeleton = false }: Props) {
  return (
    <div className={styles.cardWrapper}>
      {isSkeleton ? (
        <Skeleton
          animated
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      ) : (
        <Image alt="" src={thumbnail} />
      )}
    </div>
  );
}

export default React.memo(MiniClassCard);
