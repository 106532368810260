/* eslint-disable lines-around-comment */
import styles from './styles.module.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'constant';
import { useDirection } from 'hooks';
import { useLocation } from 'react-router';

type Props = {};

export default function Footer({}: Props) {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const location = useLocation();

  const currentParentPath = useMemo(() => {
    return `/${location.pathname.split('/')[1]}`;
  }, [location.pathname]);

  const FOOTER_ITEMS = [
    {
      id: 1,
      title: t('title.home'),
      src: '/images/icon/home-outline-gray.svg',
      srcActive: '/images/icon/home-blue.svg',
      path: ROUTES.HOME,
    },
    {
      id: 2,
      title: t('title.membership'),
      src: '/images/icon-png/star-outline.png',
      srcActive: '/images/icon/star.svg',
      path: ROUTES.MEMBERSHIP,
    },
    {
      id: 3,
      title: t('title.history'),
      src: '/images/icon/history.svg',
      srcActive: '/images/icon/history-blue.svg',
      path: ROUTES.HISTORY,
    },
  ];

  return (
    <div className={styles.footerWrapper}>
      {FOOTER_ITEMS.map((item) => {
        return (
          <div key={item.id} className={styles.item} onClick={goTo(item.path || '')}>
            {currentParentPath === item.path && <div className={styles.indicator} />}
            <div className={styles.icon}>
              <img
                src={currentParentPath === item.path ? item.srcActive : item.src}
                width="20px"
                height="20px"
              />
            </div>
            <p
              className="color-black font-xs font-medium"
              style={{
                color:
                  currentParentPath === item.path
                    ? 'var(--primary-color)'
                    : 'var(--primary-text-color)',
              }}
            >
              {item.title}
            </p>
          </div>
        );
      })}
    </div>
  );
}
