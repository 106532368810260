import React from 'react';
import Header from './Header';
import Footer from './Footer';
import styles from './styles.module.scss';
import { BottomSpace, PullToReload } from 'components';
import { useTranslation } from 'react-i18next';

type Props = {
  isHideFooter?: boolean;
  isHideSlogan?: boolean;
  isHideHeader?: boolean;
  isHaveUnreadNotify?: boolean;
  isHideBottomSpace?: boolean;
  children?: React.ReactNode;
};

function MainLayout({
  isHideFooter = false,
  isHideSlogan = false,
  isHideHeader = false,
  isHaveUnreadNotify = false,
  isHideBottomSpace = false,
  children,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapperLayout}>
      {!isHideSlogan && <p className={styles.slogan}>{t('paragraph.slogan')}</p>}
      {!isHideHeader && <Header isHaveUnreadNotify={isHaveUnreadNotify} />}
      <div id="main-body" className={styles.body}>
        <PullToReload>
          {children}
          {!isHideBottomSpace && <BottomSpace />}
        </PullToReload>
      </div>
      {!isHideFooter && <Footer />}
    </div>
  );
}

export default React.memo(MainLayout);
