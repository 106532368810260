import React from 'react';
import styles from './styles.module.scss';
import { Image, Skeleton } from 'antd-mobile';
import { showData } from 'utils';

type Props = {
  name?: string;
  thumbnail?: string;
  isSkeleton?: boolean;
  onClick?: () => void;
};

function GuidelineCard({ name, thumbnail, isSkeleton = false, onClick }: Props) {
  return (
    <div className={styles.cardWrapper} onClick={onClick}>
      {isSkeleton ? (
        <Skeleton className={styles.thumbnail} animated />
      ) : (
        <div className={styles.thumbnail}>
          <Image alt="" src={thumbnail} />
        </div>
      )}
      <div className={styles.content}>
        {isSkeleton ? (
          <Skeleton.Paragraph
            animated
            style={{
              width: '100%',
            }}
          />
        ) : (
          <p
            className="font-sm font-medium color-black"
            style={{
              whiteSpace: 'pre-wrap',
            }}
          >
            {showData(String(name))}
          </p>
        )}
      </div>
    </div>
  );
}

export default React.memo(GuidelineCard);
