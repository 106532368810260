import styles from './styles.module.scss';
import { useDirection } from 'hooks';
import { ROUTES } from 'constant';
import { useTranslation } from 'react-i18next';
import SvgIcon from 'components/shared/SVGIcon';
import SupportItem from 'components/shared/SupportItem';

type Props = {};

const reportItems = [
  {
    reportType: 'temperature',
    textKey: 'title.temperature',
    iconSrc: '/images/icon/temperature.svg',
  },
  { reportType: 'machine', textKey: 'title.machine', iconSrc: '/images/icon/machine.svg' },
  {
    reportType: 'employee-attitude',
    textKey: 'title.employee-attitude',
    iconSrc: '/images/icon/employee-attitude.svg',
  },
  {
    reportType: 'clean-quality',
    textKey: 'title.clean-quality',
    iconSrc: '/images/icon/clean-quality.svg',
  },
  {
    reportType: 'error-membership',
    textKey: 'title.error-membership',
    iconSrc: '/images/icon/error-membership.svg',
  },
  {
    reportType: 'others-report',
    textKey: 'title.others-report',
    iconSrc: '/images/icon/others.svg',
  },
];

export function ReportContainer({}: Props) {
  const { t } = useTranslation();
  const { goTo } = useDirection();

  return (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <p className="font-sm color-black">{t('title.how-can-I-help-you')}</p>
        </div>
        <div className={styles.content}>
          {reportItems.map((item) => (
            <SupportItem
              key={item.reportType}
              text={t(item.textKey)}
              icon={<SvgIcon pathFill="var(--primary-color)" src={item.iconSrc} />}
              onClick={goTo(ROUTES.SUPPORT_REPORT_TYPE.replace(':reportType', item.reportType))}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
