import { TabWrapper } from 'components';
import styles from './styles.module.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalStorage, STORAGE_KEY, resetScroll } from 'utils';
import { MainPageTransition } from 'components/animation';
import MembershipTab from './MembershipTab';
import PTTab from './PTTab';
import FreezeTab from './FreezeTab';

type Props = {};

const TABS = {
  MEMBERSHIP: 'MEMBERSHIP',
  PT: 'PT',
  FREEZE: 'FREEZE',
};

const Storage = new LocalStorage();

export function HistoryContainer({}: Props) {
  const previousTab = Storage.getStorageItem(STORAGE_KEY.HISTORY_TAB);
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<string | number>(
    previousTab ? previousTab : TABS.MEMBERSHIP,
  );
  const TAB_ITEMS = [
    {
      key: TABS.MEMBERSHIP,
      title: t('title.membership'),
    },
    {
      key: TABS.PT,
      title: t('title.hlv'),
    },
    {
      key: TABS.FREEZE,
      title: t('title.freeze'),
    },
  ];

  const handleChangeTab = (key: string | number) => {
    setSelectedTab(key);
    Storage.setStorageItem(STORAGE_KEY.HISTORY_TAB, key);
    resetScroll();
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <TabWrapper activeKey={selectedTab} items={TAB_ITEMS} onChangeTab={handleChangeTab} />
      </div>
      <MainPageTransition>
        <div className={styles.body}>
          {selectedTab === TABS.MEMBERSHIP && <MembershipTab />}
          {selectedTab === TABS.PT && <PTTab />}
          {selectedTab === TABS.FREEZE && <FreezeTab />}
        </div>
      </MainPageTransition>
    </div>
  );
}
