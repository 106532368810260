import React, { useMemo } from 'react';
import { MembershipRegistration } from './template/MembershipRegistration';
import { NotificationActionType } from 'enum';
import { MembershipPayment } from './template/MembershipPayment';
import { MembershipReminder } from './template/MembershipReminder';
import { MembershipFreeze } from './template/MembershipFreeze';
import { PtRegistration } from './template/PtRegistration';
import { PtPayment } from './template/PtPayment';
import { PtConfirmBooking } from './template/PtConfirmBooking';
import { PtCancelBooking } from './template/PtCancelBooking';
import { PtExperience } from './template/PtExperience';
import { PaymentMethodLinkCard } from './template/PaymentMethodLinkCard';
import { MembershipUnfreeze } from './template/MembershipUnfreeze';
import { PtNotConfirmBooking } from './template/PtNotConfirmBooking';
import { PtCancelBookingByUser } from './template/PtCancelBookingByUser';
import { PaymentMethodChangeCard } from './template/PaymentMethodChangeCard';
import { PaymentMethodDeleteCard } from './template/PaymentMethodDeleteCard';
import { ContractExpiredAfter7Day } from './template/ContractExpiredAfter7Day';
import { ContractExpiredToday } from './template/ContractExpiredToday';
import { DiscountLimited } from './template/DiscountLimited';
import { DiscountExpiredSoon } from './template/DiscountExpiredSoon';
import { DiscountLastChance } from './template/DiscountLastChance';

type Props = {
  action?: NotificationActionType;
  data?: any;
};

function NotifyContent({ action, data }: Props) {
  const notifyContent = useMemo(() => {
    switch (action) {
      // MEMBERSHIP
      case NotificationActionType.MOBILE_MEMBERSHIP_REGISTRATION:
        return <MembershipRegistration data={data} />;
      case NotificationActionType.MOBILE_MEMBERSHIP_PAYMENT:
        return <MembershipPayment data={data} />;
      case NotificationActionType.MOBILE_MEMBERSHIP_REMINDER:
        return <MembershipReminder data={data} />;
      case NotificationActionType.MOBILE_MEMBERSHIP_FREEZE:
        return <MembershipFreeze data={data} />;
      case NotificationActionType.MOBILE_UNFREEZE_FREEZE_CONTRACT:
        return <MembershipUnfreeze data={data} />;

      // PT
      case NotificationActionType.MOBILE_PT_REGISTRATION:
        return <PtRegistration data={data} />;
      case NotificationActionType.MOBILE_PT_PAYMENT:
        return <PtPayment data={data} />;
      case NotificationActionType.MOBILE_PT_BOOKING:
        return <PtConfirmBooking data={data} />;
      case NotificationActionType.MOBILE_PT_CANCELLATION:
        return <PtCancelBooking data={data} />;
      case NotificationActionType.MOBILE_PT_NOT_CONFIRM_BOOKING:
        return <PtNotConfirmBooking data={data} />;
      case NotificationActionType.MOBILE_PT_CANCELLATION_BY_USER:
        return <PtCancelBookingByUser data={data} />;
      case NotificationActionType.MOBILE_PT_EXPERIENCE:
        return <PtExperience data={data} />;

      //CLASS
      case NotificationActionType.MOBILE_CLASS_REGISTRATION:
        return <></>;
      case NotificationActionType.MOBILE_CLASS_CANCELLATION:
        return <></>;

      //PAYMENT METHOD
      case NotificationActionType.MOBILE_PAYMENT_METHOD_LINK_CARD:
        return <PaymentMethodLinkCard data={data} />;
      case NotificationActionType.MOBILE_PAYMENT_METHOD_CHANGE_CARD:
        return <PaymentMethodChangeCard data={data} />;
      case NotificationActionType.MOBILE_PAYMENT_METHOD_DELETE_CARD:
        return <PaymentMethodDeleteCard data={data} />;

      //MEMBERSHIP REMINDER
      case NotificationActionType.MOBILE_EXPIRING_CONTRACT_AFTER_7_DAY:
        return <ContractExpiredAfter7Day data={data} />;
      case NotificationActionType.MOBILE_EXPIRING_CONTRACT_TODAY:
        return <ContractExpiredToday data={data} />;

      //GIFT
      case NotificationActionType.MOBILE_RETURN_CONTRACT_T3_COUPON:
        return <DiscountLimited data={data} />;
      case NotificationActionType.MOBILE_RETURN_CONTRACT_T7_COUPON:
        return <DiscountExpiredSoon data={data} />;
      case NotificationActionType.MOBILE_RETURN_CONTRACT_T21_COUPON:
        return <DiscountLastChance data={data} />;

      default:
        return <></>;
    }
  }, [action, data]);

  return notifyContent;
}

export default React.memo(NotifyContent);
