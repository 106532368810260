import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { PrimaryButton } from 'components';
import { useDirection, useFormData, useValidation } from 'hooks';
import { ROUTES } from 'constant';
import { AuthService } from 'services';
import { JOI, delayNavigate, isSuccessCode } from 'utils';
import { useSelector } from 'react-redux';
import { commonSelector } from 'stores/common';
import InputPhoneNumber from 'components/input/InputPhoneNumber';

type FormData = {
  phoneNumber: string;
};

const initForm: FormData = {
  phoneNumber: '',
};

export function ChangePhoneNumberContainer() {
  const authService = new AuthService();
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const { isLoading } = useSelector(commonSelector);
  const { formData, KEY, onChangeForm } = useFormData<FormData>(initForm);
  const { errorMessage, onValidate } = useValidation<FormData>({
    phoneNumber: JOI.PHONE_REQUIRE,
  });

  const sendOTPCode = async () => {
    const isError = onValidate(formData);
    if (!isError) {
      const response = await authService.sendOTPChangePhoneNumber(formData);
      const { code } = response;

      if (isSuccessCode(code)) {
        delayNavigate(
          goTo(ROUTES.ACCOUNT_VERIFY_PHONE_NUMBER, {
            replace: true,
            state: {
              phoneNumber: formData.phoneNumber,
            },
          }),
        );
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <p className="font-xl color-black font-bold">{t('title.what-is-your-new-phone')}</p>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            sendOTPCode();
          }}
        >
          <div className={styles.input}>
            <InputPhoneNumber
              value={formData.phoneNumber}
              onChange={onChangeForm(KEY.PHONE_NUMBER)}
              errorMessage={errorMessage.phoneNumber}
            />
          </div>
          <div className={styles.controller}>
            <PrimaryButton
              text={t('button.continue')}
              onClick={sendOTPCode}
              style={{
                width: '100%',
              }}
              loading={isLoading}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
