import { WorkoutWithUsContainer } from 'containers';
import { SecondaryLayout } from 'layouts';

type Props = {};

export default function WorkoutWithUsPage({}: Props) {
  return (
    <SecondaryLayout
      title="title.workout-with-us"
      headerBackground="var(--blue-bg-color)"
      titleColor="var(--primary-color)"
      arrowColor="var(--primary-color)"
      isHideFooter
      isHideHome
      isWhiteBg
      isHideSlogan
    >
      <WorkoutWithUsContainer />
    </SecondaryLayout>
  );
}
