import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { useDirection } from 'hooks';
import { ROUTES } from 'constant';

type Props = {};

export function PTContainer({}: Props) {
  const { t } = useTranslation();
  const { goTo } = useDirection();

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.item}
        style={{
          backgroundImage: 'url(/images/background/bg5.png)',
        }}
        onClick={goTo(ROUTES.PT_SELECT)}
      >
        <div className={styles.content}>
          <p
            className="font-xl font-bold color-white"
            style={{
              whiteSpace: 'pre-wrap',
              textAlign: 'center',
              lineHeight: '32px',
            }}
          >
            {t('paragraph.choose-buddy')}
          </p>
        </div>
      </div>
      <div
        className={styles.item}
        style={{
          backgroundImage: 'url(/images/background/bg12.png)',
        }}
        onClick={goTo(ROUTES.PT_BOOKING)}
      >
        <div className={styles.content}>
          <p
            className="font-xl font-bold color-white"
            style={{
              whiteSpace: 'pre-wrap',
              textAlign: 'center',
              lineHeight: '32px',
            }}
          >
            {t('paragraph.booking')}
          </p>
        </div>
      </div>
      {/* <div
        className={styles.item}
        style={{
          backgroundImage: 'url(/images/background/bg6.png)',
        }}
        onClick={goTo(ROUTES.PT_CONTRACT)}
      >
        <div className={styles.content}>
          <p
            className="font-xl font-bold color-white"
            style={{
              whiteSpace: 'pre-wrap',
              textAlign: 'center',
              lineHeight: '32px',
            }}
          >
            {t('paragraph.contract-history')}
          </p>
        </div>
      </div> */}
    </div>
  );
}
