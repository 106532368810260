import { ROUTES, THEME_COLOR } from 'constant';
import { NotificationContainer } from 'containers';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { SecondaryLayout } from 'layouts';

export default function NotificationPage() {
  usePWATemplateColor(THEME_COLOR.WHITE);

  return (
    <SecondaryLayout
      title="title.notification"
      headerBackground="var(--blue-bg-color)"
      titleColor="var(--primary-color)"
      arrowColor="var(--primary-color)"
      isHideFooter
      isHideHome
      isWhiteBg
      isHideBottomSpace
      backUrl={ROUTES.HOME}
    >
      <NotificationContainer />
    </SecondaryLayout>
  );
}
