import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { BottomSpace, ChipSelector, ClassCard, EmptyCard } from 'components';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { LANGUAGE } from 'enum';
import GlobalService from 'services/global.service';
import { ClassType } from 'types/global.type';
import { delayNavigate, isSuccessCode, isVN, resetScrollElement } from 'utils';
import { useDirection } from 'hooks';
import { ROUTES } from 'constant';
import Selection from 'components/input/Selection';
import { isEmpty } from 'lodash';
import LoadingDot from 'components/shared/LoadingDot';
import { UserService } from 'services';
import { ClubType } from 'types';

export function ClassContainer() {
  const { t, i18n } = useTranslation();
  const { goTo } = useDirection();
  const globalService = new GlobalService();
  const userService = new UserService();
  const [listAccessClub, setListAccessClub] = useState<ClubType[]>([]);
  const [selectedClub, setSelectedClub] = useState<number | null>(null);
  const [classes, setClasses] = useState<ClassType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingClub, setIsLoadingClub] = useState(true);
  const [activeDate, setActiveDate] = useState('');

  const initCallAPI = async () => {
    setIsLoadingClub(true);
    await getListAccessClub();
    delayNavigate(() => setIsLoadingClub(false));
  };

  const getListAccessClub = async () => {
    const response = await userService.getContractAccessClub();
    const { code, data } = response;
    if (isSuccessCode(code)) {
      if (data?.length > 0) {
        setListAccessClub(data);
        setSelectedClub(data[0]?.id || null);
      }
    }
  };

  const getClasses = async () => {
    setIsLoading(true);
    if (selectedClub && activeDate) {
      const response = await globalService.getClasses({
        clubId: Number(selectedClub),
        beginDate: activeDate,
      });
      const { code, data } = response;
      if (isSuccessCode(code)) {
        setClasses(data);
      }
    }
    delayNavigate(() => setIsLoading(false));
  };

  const listAvailableDate = useMemo(() => {
    const result = [];

    for (let i = 0; i < 7; i++) {
      const now = moment();
      const nextDate = now.add(i, 'day');
      if (i18n.language === LANGUAGE.VN) nextDate.locale('vi');
      else nextDate.locale('en-us');
      result.push({ title: nextDate.format('ddd-DD/MM'), value: nextDate.toISOString() });
    }

    setActiveDate(result[0]?.value);

    return result;
  }, []);

  const handleChangeActiveDate = (newDate: string) => {
    resetScrollElement('class-list');
    setActiveDate(newDate);
  };

  const handleChangeContract = (clubId: number | null) => {
    resetScrollElement('class-list');
    setSelectedClub(clubId);
  };

  useEffect(() => {
    initCallAPI();
  }, []);

  useEffect(() => {
    getClasses();
  }, [selectedClub, activeDate]);

  return (
    <div className={styles.wrapper}>
      {isLoadingClub ? (
        <LoadingDot />
      ) : !isLoading && isEmpty(listAccessClub) ? (
        <EmptyCard text={t('paragraph.no-contract')} />
      ) : (
        <>
          <div className={styles.selectClub}>
            <Selection
              options={(listAccessClub || [])?.map((club) => ({
                id: Number(club?.id),
                title: String(isVN() ? club?.nameVi : club?.nameEn),
              }))}
              selectedId={Number(selectedClub)}
              onChangeCity={handleChangeContract}
              clearable={false}
              iconUrl="/images/icon/building.svg"
            />
          </div>
          <div className={styles.date}>
            <ChipSelector
              activeChipKey={activeDate}
              chipList={listAvailableDate.map((date) => {
                return {
                  key: date.value,
                  title: date.title,
                };
              })}
              onChangeChip={handleChangeActiveDate}
            />
          </div>
          <div className={styles.content}>
            <p className="font-xl font-bold color-blue">{t('title.today-we-have')}</p>
            <div id="class-list" className={styles.listClass}>
              {isLoading ? (
                <LoadingDot />
              ) : classes.length > 0 ? (
                classes?.map((classItem) => {
                  return (
                    <ClassCard
                      key={classItem.id}
                      beginAt={classItem.classCalendar?.beginningDateTime}
                      duration={classItem.classCalendar?.durationInMins}
                      imageUrl={classItem.imageId}
                      onClick={goTo(ROUTES.CLASS_DETAIL)}
                    />
                  );
                })
              ) : (
                <EmptyCard text={t('paragraph.no-class')} />
              )}
              <BottomSpace />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
