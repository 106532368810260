import { getUserContractsAction, getUserProfileAction } from './actions';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { APIResponse, UserProfile } from 'types';
import { ContractType } from 'types/user.type';
import { isSuccessCode } from 'utils';

interface State {
  userProfile?: UserProfile;
  userContracts?: ContractType[];
  selectedContract?: ContractType;
}

const initialState: State = {
  userProfile: {},
  userContracts: [],
  selectedContract: {},
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    changeSelectedContract: (state: State, action: PayloadAction<ContractType>) => {
      state.selectedContract = action.payload;
    },
    clearUserProfile: (state: State) => {
      state.userProfile = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserProfileAction.fulfilled, (state, action: PayloadAction<APIResponse>) => {
      if (isSuccessCode(action.payload.code)) {
        state.userProfile = action.payload.data;
      }
    });
    builder.addCase(
      getUserContractsAction.fulfilled,
      (state, action: PayloadAction<APIResponse>) => {
        const data = action.payload.data?.data as ContractType[];
        if (isSuccessCode(action.payload.code)) {
          state.userContracts = data;
          if (data?.length > 0) {
            state.selectedContract = data[0];
          }
        } else {
          state.selectedContract = {};
        }
      },
    );
  },
});

export const { changeSelectedContract, clearUserProfile } = userSlice.actions;
export default userSlice.reducer;
