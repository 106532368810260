import React from 'react';
import styles from './styles.module.scss';
import { SvgIcon } from 'components/shared';
import { showData } from 'utils';
import { Skeleton } from 'antd-mobile';

type Props = {
  createdAt?: string;
  title?: string;
  content?: string;
  icon?: string;
  isRead?: boolean;
  isSkeleton?: boolean;
  onClick?: () => void;
};

function NotifyCard({
  createdAt,
  title,
  content,
  icon = '',
  isRead = false,
  isSkeleton = false,
  onClick,
}: Props) {
  return (
    <div className={styles.cardWrapper}>
      {isSkeleton ? (
        <Skeleton
          animated
          style={{
            width: '30%',
            height: 'var(--font-us)',
          }}
        />
      ) : (
        <p className="color-black font-light font-us">{showData(createdAt)}</p>
      )}
      <div className={styles.mainCard} onClick={onClick}>
        {isSkeleton ? (
          <Skeleton
            className={styles.icon}
            animated
            style={{
              borderRadius: '50%',
            }}
          />
        ) : (
          <div className={styles.icon}>
            <SvgIcon src={icon} width="100%" height="100%" />
          </div>
        )}
        <div className={styles.content}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                width: '100%',
                height: 'var(--font-xs)',
              }}
            />
          ) : (
            <p className="color-black font-medium font-xs">{showData(title)}</p>
          )}
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                width: '100%',
                height: 'var(--font-xs)',
              }}
            />
          ) : (
            <p className="color-black font-light font-xs">{showData(content)}</p>
          )}
        </div>
        {!isRead && !isSkeleton && <div className={styles.indicator} />}
      </div>
    </div>
  );
}

export default React.memo(NotifyCard);
