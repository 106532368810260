import React from 'react';
import styles from './styles.module.scss';
import { Image } from 'antd-mobile';
import { BottomSpace } from 'components/shared';
import { useTranslation } from 'react-i18next';

type Props = {};

export default function InstallIOS({}: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <p
        className="font-md color-black font-bold"
        style={{
          textAlign: 'center',
        }}
      >
        {t('paragraph.how-to-install-app')}
      </p>

      <div className={styles.steps}>
        <p className={styles.stepIndex}>{t('title.step-n', { step: 1 })}:</p>
        <div className={styles.imageStep}>
          <Image src="/images/tutorial/step-1.png" />
        </div>
        <p className={styles.stepIndex}>{t('title.step-n', { step: 2 })}:</p>
        <div className={styles.imageStep}>
          <Image src="/images/tutorial/step-2.png" />
        </div>
        <p className={styles.stepIndex}>{t('title.step-n', { step: 3 })}:</p>
        <div className={styles.imageStep}>
          <Image src="/images/tutorial/step-3.png" />
        </div>
      </div>
      <BottomSpace />
    </div>
  );
}
