import { THEME_COLOR } from 'constant';
import { ClassContainer } from 'containers';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { SecondaryLayout } from 'layouts';

type Props = {};

export default function ClassPage({}: Props) {
  usePWATemplateColor(THEME_COLOR.WHITE);

  return (
    <SecondaryLayout
      title={'title.class'}
      headerBackground="var(--blue-bg-color)"
      titleColor="var(--primary-color)"
      arrowColor="var(--primary-color)"
      isHideFooter
      isHideHome
      isWhiteBg
      isHideBottomSpace
    >
      <ClassContainer />
    </SecondaryLayout>
  );
}
