import { TodayClassesContainer } from 'containers';
import { SecondaryLayout } from 'layouts';
import React from 'react';

type Props = {};

export default function TodayClassesPage({}: Props) {
  return (
    <SecondaryLayout
      title="title.today-classes"
      headerBackground="var(--blue-bg-color)"
      titleColor="var(--primary-color)"
      arrowColor="var(--primary-color)"
      isHideFooter
      isHideHome
      isWhiteBg
      isHideSlogan
    >
      <TodayClassesContainer />
    </SecondaryLayout>
  );
}
