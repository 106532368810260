import { CapsuleTabs } from 'antd-mobile';
import React from 'react';
import styles from './styles.module.scss';

type Prop = {
  items?: {
    key: string | number;
    title: string;
  }[];
  customItemClass?: string;
  activeKey?: string | number;
  onChangeTab?: (key: string | number) => void;
};

function TabWrapper({ items = [], customItemClass, activeKey = '', onChangeTab }: Prop) {
  return (
    <CapsuleTabs className={styles.tabWrapper} activeKey={String(activeKey)} onChange={onChangeTab}>
      {items.map((item) => {
        return (
          <CapsuleTabs.Tab
            className={customItemClass}
            title={item.title}
            key={item.key}
            style={{ width: `calc(100% / ${items.length})` }}
          />
        );
      })}
    </CapsuleTabs>
  );
}

export default React.memo(TabWrapper);
