import { THEME_COLOR } from 'constant';
import PTPaymentResultContainer from 'containers/PTContainer/PTPaymentResultContainer';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { SecondaryLayout } from 'layouts';

type Props = {};

export default function PTPaymentResultPage({}: Props) {
  usePWATemplateColor(THEME_COLOR.WHITE);

  return (
    <SecondaryLayout
      title="title.pt"
      headerBackground="var(--blue-bg-color)"
      titleColor="var(--primary-color)"
      arrowColor="var(--primary-color)"
      isHideFooter
      isHideHome
      isWhiteBg
      isHideBack
    >
      <PTPaymentResultContainer />
    </SecondaryLayout>
  );
}
