import { MiniClassCard2 } from 'components';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserContractsAction, userSelector } from 'stores/user';
import GlobalService from 'services/global.service';
import { delayNavigate, getImageUrl, isSuccessCode, isVN } from 'utils';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { ROUTES } from 'constant';
import { ClassType } from 'types/global.type';
import { isEmpty } from 'lodash';
import { useDispatch } from 'stores';
import { useDirection } from 'hooks';
import LoadingDot from 'components/shared/LoadingDot';

export function TodayClassesContainer() {
  const globalService = new GlobalService();
  const { goTo } = useDirection();
  const { t } = useTranslation();
  const { selectedContract, userContracts } = useSelector(userSelector);
  const [listClass, setListClass] = useState<ClassType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const getClasses = async () => {
    if (selectedContract?.club?.id) {
      const response = await globalService.getClasses({
        clubId: Number(selectedContract?.club?.id),
        beginDate: moment().toISOString(),
      });
      const { code, data } = response;
      if (isSuccessCode(code)) {
        setListClass(data);
      }
      delayNavigate(() => setIsLoading(false));
    }
  };

  const initCallAPI = async () => {
    if (isEmpty(userContracts)) {
      await dispatch(getUserContractsAction());
    }
  };

  useEffect(() => {
    getClasses();
  }, [selectedContract]);

  useEffect(() => {
    initCallAPI();
  }, []);

  return (
    <div className={styles.container}>
      <p className="font-black font-xl font-bold">{t('title.find-out-your-classes')}</p>
      <div className={styles.classes}>
        {isLoading ? (
          <LoadingDot />
        ) : (
          listClass?.map((classObj) => {
            return (
              <div key={classObj.id} className={styles.class}>
                <MiniClassCard2
                  thumbnail={getImageUrl(String(classObj.imageId))}
                  name={isVN() ? classObj.nameVi : classObj.nameEn}
                  durationMinute={classObj.classCalendar?.durationInMins}
                  begin={classObj.classCalendar?.beginningDateTime}
                  onClick={goTo(ROUTES.CLASS_DETAIL.replace(':classId', String(classObj?.id)))}
                />
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}
