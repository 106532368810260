import { THEME_COLOR } from 'constant';
import PaymentFreezeResultContainer from 'containers/MembershipContainer/FreezeTab/PaymentFreezeResultContainer';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { SecondaryLayout } from 'layouts';

type Props = {};

export default function FreezeContractDetailPage({}: Props) {
  usePWATemplateColor(THEME_COLOR.WHITE);

  return (
    <SecondaryLayout
      title="title.freeze-transaction"
      headerBackground="var(--blue-bg-color)"
      titleColor="var(--primary-color)"
      arrowColor="var(--primary-color)"
      isHideFooter
      isHideHome
      isWhiteBg
    >
      <PaymentFreezeResultContainer isHistoryPage />
    </SecondaryLayout>
  );
}
