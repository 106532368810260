/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import CreditCard from 'components/card/CreditCard';
import { Button } from 'antd-mobile';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from 'components';
import { useDirection } from 'hooks';
import { ROUTES } from 'constant';
import PaymentService from 'services/payment.service';
import { delayNavigate, formatFullVPPayReturnCard, isSuccessCode, isVN } from 'utils';
import { SavedCardType } from 'types/payment.type';
import { BANK_INFO_MAPPING } from 'constant/bank';
import { CARD_TYPE } from 'enum';
import LoadingDot from 'components/shared/LoadingDot';

type Props = {};

export default function PaymentMethodContainer({}: Props) {
  const paymentService = new PaymentService();
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const [listCard, setListCard] = useState<SavedCardType[]>([]);
  const [isLoadingCard, setIsLoadingCard] = useState(true);

  const getListCard = async () => {
    const response = await paymentService.getExistCards();
    const { data, code } = response;
    if (isSuccessCode(code)) {
      setListCard(data);
    }
    delayNavigate(() => setIsLoadingCard(false));
  };

  const handleAddCard = async () => {
    const currentURl = [window.location.origin, window.location.pathname].join('');

    const response = await paymentService.addVNPayAddCardUrl({
      locale: isVN() ? 'vn' : 'en',
      cancelUrl: currentURl,
      cardType: CARD_TYPE.INTERNATIONAL,
      returnUrl: currentURl,
    });
    const { data, code } = response;
    if (isSuccessCode(code)) {
      window.location.href = data?.vnpayUrl;
    }
  };

  useEffect(() => {
    getListCard();
  }, []);

  return (
    <div className={styles.wrapper}>
      <p>{t('title.current-linked-card')}</p>
      <div className={styles.listCard}>
        {isLoadingCard ? (
          <LoadingDot />
        ) : (
          listCard.map((card) => {
            return (
              <CreditCard
                key={card.id}
                isDefault={card?.isDefault}
                cardIcon={BANK_INFO_MAPPING[card.bankCode]?.logo}
                cardNumber={formatFullVPPayReturnCard(card.cardNumber)}
                onClick={goTo(
                  ROUTES.ACCOUNT_PAYMENT_METHOD_CARD_DETAIL.replace(':cardId', String(card.id)),
                )}
              />
            );
          })
        )}
      </div>
      <Button className={styles.addCard} onClick={handleAddCard}>
        <SvgIcon src="/images/icon/dashed-plus-icon.svg" /> {t('button.add-new-card')}
      </Button>
    </div>
  );
}
