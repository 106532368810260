import { Swiper, SwiperRef } from 'antd-mobile';
import styles from './styles.module.scss';
import React, { useRef } from 'react';

type Props = {
  isBlackCard?: boolean;
  activeIdx?: number;
  items?: React.ReactNode[];
  emptyElement?: React.ReactNode;
  showIndicator?: boolean;
  onChangeIdx?: (idx: number) => void;
};

function CardSwiper({
  activeIdx = 0,
  items = [],
  emptyElement,
  showIndicator = false,
  isBlackCard = false,
  onChangeIdx,
}: Props) {
  const swiperRef = useRef<SwiperRef>(null);

  return items.length > 0 ? (
    <Swiper
      ref={swiperRef}
      defaultIndex={activeIdx}
      className={`${styles.listItem} ${isBlackCard ? styles.isBlackCard : ''}`}
      style={{
        '--track-padding': '0 0 0 0',
        paddingBottom: showIndicator ? '24px' : '0',
      }}
      indicatorProps={{
        style: {
          '--dot-color': 'transparent',
          '--active-dot-color': isBlackCard ? 'var(--white-text-color)' : 'var(--primary-color)',
          '--dot-size': '10px',
          '--dot-border-radius': '10px',
          '--active-dot-size': '10px',
        },
      }}
      indicator={showIndicator ? undefined : false}
      onIndexChange={onChangeIdx}
    >
      {items?.map((item, idx) => {
        return (
          <Swiper.Item key={idx}>
            <div className={styles.inner}>{item}</div>
          </Swiper.Item>
        );
      })}
    </Swiper>
  ) : (
    <div
      style={{
        padding: '0 0 16px',
      }}
    >
      {emptyElement}
    </div>
  );
}

export default React.memo(CardSwiper);
