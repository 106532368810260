/* eslint-disable @typescript-eslint/no-unused-vars */
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import styles from './styles.module.scss';
import { BottomSpace, SecondaryButton } from 'components';
import { useDirection } from 'hooks';
import { ROUTES } from 'constant';
import PTService from 'services/pt.service';
import { useEffect, useState } from 'react';
import { blobToDataUrl } from 'utils';
import { FaFileDownload } from 'react-icons/fa';
import { FloatingBubble, SpinLoading } from 'antd-mobile';
import { useDispatch } from 'stores';
import { setLoadingToast } from 'stores/common';
import { useTranslation } from 'react-i18next';

type Props = {};

export default function ViewContractPdfContainer({}: Props) {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const dispatch = useDispatch();
  const ptService = new PTService();
  const [eContract, setEContract] = useState<any>('');

  const getEContractPreview = async () => {
    const response = await ptService.getPTEContractPreview();
    const blob = response;
    const dataUrl = await blobToDataUrl(blob);

    setEContract(dataUrl);
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.download = 'e-contract.pdf';
    link.href = eContract;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    getEContractPreview();
    dispatch(setLoadingToast(true));
  }, []);

  return (
    <div className={styles.wrapper}>
      {/* <Worker workerUrl={'/pdf.worker.min.js'}>
        {eContract && (
          <Viewer
            fileUrl={eContract}
            initialPage={0}
            defaultScale={SpecialZoomLevel.PageWidth}
            onDocumentLoad={() => {
              dispatch(setLoadingToast(false));
            }}
          />
        )}
      </Worker> */}
      <BottomSpace />
      <div className={styles.signButton}>
        <SecondaryButton
          text={t('button.sign-to-contract')}
          variant="high-light-blue"
          onClick={goTo(ROUTES.PT_CONTRACT_SIGN_PDF.replace(':eContractId', '123'))}
        />
      </div>
      <FloatingBubble
        style={{
          '--initial-position-bottom': '24px',
          '--initial-position-right': '24px',
          '--edge-distance': '24px',
          '--background': 'var(--primary-color)',
          '--size': '42px',
        }}
        onClick={handleDownload}
      >
        <FaFileDownload fontSize="20px" />
      </FloatingBubble>
    </div>
  );
}
