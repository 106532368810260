import { useDirection, useDisclosure } from 'hooks';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'constant';
import { useDispatch } from 'stores';
import { useEffect, useState } from 'react';
import { delayNavigate, formatDate, formatPrice, isSuccessCode, showData } from 'utils';
import { FreezeContractType } from 'types';
import FreezeService from 'services/freeze.service';
import { isEmpty } from 'lodash';
import ConfirmModal from 'components/modal/ConfirmModal';
import { setLoadingToast } from 'stores/common';
import { GeneralService } from 'services';
import { EmptyCard } from 'components';
import { useSelector } from 'react-redux';
import { getUserContractsAction, userSelector } from 'stores/user';
import { Skeleton } from 'antd-mobile';
import LoadingDot from 'components/shared/LoadingDot';

type Props = {};

export function FreezeTab({}: Props) {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { selectedContract, userContracts } = useSelector(userSelector);
  const freezeService = new FreezeService();
  const generalService = new GeneralService();
  const [isLoadingPrice, setIsLoadingPrice] = useState(true);
  const [freezeContract, setFreezeContract] = useState<FreezeContractType>({});
  const [freezePrice, setFreezePrice] = useState(null);
  const dispatch = useDispatch();

  const getCurrentFreezePrice = async () => {
    const response = await generalService.getFreezePrice();
    const { code, data } = response;
    if (isSuccessCode(code)) {
      setFreezePrice(data?.settingValueEn?.value || 0);
    }
  };

  const getCurrentFreezeContract = async () => {
    const response = await freezeService.getCurrentFreezeContract();
    const { code, data } = response;
    if (isSuccessCode(code)) {
      setFreezeContract(data);
    }
  };

  const handleUnfreeze = async () => {
    dispatch(setLoadingToast(true));
    const response = await freezeService.UnfreezeContract();
    const { code } = response;
    if (isSuccessCode(code)) {
      await getCurrentFreezeContract();
    }
    dispatch(setLoadingToast(false));
  };

  const initCallAPI = async () => {
    setIsLoadingPrice(true);
    if (isEmpty(userContracts)) {
      await dispatch(getUserContractsAction());
    }
    await getCurrentFreezePrice();
    await getCurrentFreezeContract();
    delayNavigate(() => setIsLoadingPrice(false));
  };

  useEffect(() => {
    initCallAPI();
  }, []);

  return (
    <div className={styles.wrapper}>
      {isLoadingPrice ? (
        <LoadingDot />
      ) : isEmpty(selectedContract) ? (
        <EmptyCard text={t('paragraph.no-contract')} />
      ) : (
        <>
          <div className={styles.content}>
            {isEmpty(freezeContract) ? (
              <p
                className="font-xl color-blue font-bold"
                style={{
                  whiteSpace: 'pre-wrap',
                  textAlign: 'center',
                  lineHeight: '24px',
                }}
              >
                {t('paragraph.to-busy')}
              </p>
            ) : (
              <p
                className="font-sm color-grey font-light"
                style={{
                  whiteSpace: 'pre-wrap',
                  textAlign: 'center',
                  lineHeight: '24px',
                }}
              >
                {t('title.freeze-until', { date: formatDate(freezeContract.freezeEndDate) })}
              </p>
            )}
          </div>
          {isEmpty(freezeContract) ? (
            <div className={styles.freezeNow} onClick={goTo(ROUTES.MEMBERSHIP_FREEZE_PAYMENT)}>
              <p className="font-xl color-blue font-bold">{t('title.freeze-now')}</p>
              {isLoadingPrice ? (
                <Skeleton
                  animated
                  style={{
                    height: 'var(--font-lg)',
                    width: '35%',
                  }}
                />
              ) : (
                <p className="font-lg color-black font-bold">
                  {freezePrice ? showData(formatPrice(freezePrice)) : t('title.free')}
                </p>
              )}
            </div>
          ) : (
            <div
              className={styles.freezeNow}
              onClick={onOpen}
              style={{
                justifyContent: 'center',
              }}
            >
              <p className="font-xl color-black font-bold">{t('title.cancel-freeze')}</p>
            </div>
          )}
        </>
      )}

      <ConfirmModal
        confirmTitle={t('title.unfreeze')}
        confirmContent={t('paragraph.sure-unfreeze')}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleUnfreeze}
      />
    </div>
  );
}
