import { HomeContainer } from 'containers';
import { getMessagingToken, requestNotifyPermission } from 'externals/firebase';
import { MainLayout } from 'layouts';
import { useEffect, useState } from 'react';
import NotifyService from 'services/notify.service';
import { isSuccessCode, LocalStorage, STORAGE_KEY } from 'utils';

const Storage = new LocalStorage();

export default function HomePage() {
  const notifyService = new NotifyService();
  const [haveUnreadNotify, setHaveUnreadNotify] = useState(false);

  const grandNotifyPermission = async () => {
    const permission = await requestNotifyPermission();

    if (permission === 'granted') {
      const fcmToken = await getMessagingToken();
      if (fcmToken) {
        Storage.setStorageItem(STORAGE_KEY.NOTIFY_TOKEN, fcmToken);
        notifyService.registerFcmToken({
          fcmToken,
        });
      }
    }
  };

  const getCountUnreadNotify = async () => {
    const response = await notifyService.countUnreadNotify();
    const { code, data } = response;
    if (isSuccessCode(code)) {
      if (data?.numberOfUnreadNotifications > 0) setHaveUnreadNotify(true);
    }
  };

  useEffect(() => {
    Storage.setStorageItem(STORAGE_KEY.IS_APP_OPENED, 'true');
    grandNotifyPermission();
    getCountUnreadNotify();
  }, []);

  return (
    <MainLayout isHideSlogan isHaveUnreadNotify={haveUnreadNotify}>
      <HomeContainer />
    </MainLayout>
  );
}
