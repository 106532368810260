import { Button, Divider, Skeleton } from 'antd-mobile';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'stores';
import { getUserProfileAction, userSelector } from 'stores/user';
import { useEffect, useState } from 'react';
import {
  base64ToBlob,
  delayNavigate,
  formatDate,
  getImageUrl,
  isSuccessCode,
  showData,
} from 'utils';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import AvatarUpload from 'components/input/AvatarUpload';
import { AuthService } from 'services';
import { useDirection } from 'hooks';
import { ROUTES } from 'constant';
import Resizer from 'react-image-file-resizer';

type Props = {};

export function MyInformationContainer({}: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authService = new AuthService();
  const { userProfile } = useSelector(userSelector);
  const [isLoading, setIsLoading] = useState(false);
  const { goTo } = useDirection();

  const handleUpdateLoadAvatar = async (base64: string) => {
    await Resizer.imageFileResizer(
      base64ToBlob(base64),
      200,
      200,
      'JPEG',
      100,
      0,
      async (value) => {
        const imgBlog = base64ToBlob(value.toString());
        const response = await authService.updateAvatar(imgBlog);

        const { code } = response;
        if (isSuccessCode(code)) {
          await dispatch(getUserProfileAction());
        }
      },
      'JPEG',
      110,
      110,
    );

    return true;
  };

  const initCallAPI = async () => {
    if (isEmpty(userProfile)) {
      setIsLoading(true);
      await dispatch(getUserProfileAction());
      delayNavigate(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    initCallAPI();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.information}>
        <AvatarUpload
          initAvatar={userProfile?.avatar ? getImageUrl(String(userProfile?.avatar)) : ''}
          onUpload={handleUpdateLoadAvatar}
        />
      </div>

      <div className={styles.content}>
        <div className={styles.formItem}>
          <p className="font-xs color-black font-light">{t('title.full-name')}</p>
          {isLoading ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-sm)',
              }}
            />
          ) : (
            <p className="font-sm color-black font-medium">{showData(userProfile?.fullName)}</p>
          )}
        </div>

        <Divider className={styles.divider} />
        <div className={styles.formItem}>
          <p className="font-xs color-black font-light">{t('title.phone-number')}</p>
          {isLoading ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-xs)',
              }}
            />
          ) : (
            <div className={styles.phoneNumberGroup}>
              <p className="font-sm color-black">{showData(userProfile?.phoneNumber)}</p>
              <Button className={styles.button} onClick={goTo(ROUTES.ACCOUNT_CHANGE_PHONE_NUMBER)}>
                {t('button.change-phone')}
              </Button>
            </div>
          )}
        </div>
        <Divider className={styles.divider} />
        <div className={styles.wrapper}>
          <div className={styles.formItem}>
            <p className="font-xs color-black font-light">{t('title.birthday')}</p>
            {isLoading ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-sm)',
                }}
              />
            ) : (
              <p className="font-sm color-black">{showData(formatDate(userProfile?.birthday))}</p>
            )}
          </div>
          <div className={styles.formItem}>
            <p className="font-xs color-black font-light">{t('title.gender')}</p>
            {isLoading ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-sm)',
                }}
              />
            ) : (
              <p className="font-sm color-black">
                {userProfile?.gender ? t('title.male') : t('title.female')}{' '}
              </p>
            )}
          </div>
        </div>
        <Divider className={styles.divider} />
        <div className={styles.formItem}>
          <p className="font-xs color-black font-light">CMND/CCCD/Passport</p>
          {isLoading ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-sm)',
              }}
            />
          ) : (
            <p className="font-sm color-black">{showData(userProfile?.identityNumber)}</p>
          )}
        </div>
        <Divider className={styles.divider} />
        <div className={styles.formItem}>
          <p className="font-xs color-black font-light">{t('title.address')}</p>
          {isLoading ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-sm)',
              }}
            />
          ) : (
            <p className="font-sm color-black">{showData(userProfile?.address)}</p>
          )}
        </div>
      </div>
    </div>
  );
}
