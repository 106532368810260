import { Navigate, RouteObject } from 'react-router-dom';
import {
  AccountPage,
  HomePage,
  MembershipPage,
  SignUpPage,
  LoginPage,
  VerifyOTPPage,
  CheckInPage,
  SelectPlansPage,
  PaymentMembershipPage,
  PTPage,
  ChoosePTPage,
  PTPlanPage,
  PTBookingPage,
  PaymentFreezePage,
  TermsAndConditionPage,
  ContactPage,
  AboutPage,
  MyInformationPage,
  VerifyIdentityCardPage,
  VerifyIdentitySuccessPage,
  ClassPage,
  ClassDetailPage,
  PTContractPage,
  ViewPTContractPage,
  PTContractSignPage,
  LandingPage,
  ReferralPage,
  PaymentResultPage,
  ClubDetailPage,
  NotificationPage,
  NotificationDetailPage,
  WorkoutWithUsPage,
  TodayClassesPage,
  HistoryPage,
  PaymentFreezeResultPage,
  PTPaymentResultPage,
  MembershipContractDetailPage,
  PTContractDetailPage,
  FreezeContractDetailPage,
  ChangePhoneNumberPage,
  VerifyPhoneNumberPage,
  PaymentMethodPage,
  CardDetailPage,
  YourPlanPage,
} from 'pages';
import { BlankLayout } from 'layouts';
import { ROUTES } from 'constant';
import PTPaymentPage from 'pages/pt/choose/PTPaymentPage';
import SupportPage from 'pages/support';
import ReportPage from 'pages/support/ReportPage';
import SupportReportPage from 'pages/support/ReportPage/SupportReportPage';

const Router: RouteObject[] = [
  /** HOME */
  {
    path: '/',
    children: [
      { path: '/', element: <Navigate to={ROUTES.HOME} /> },
      { path: ROUTES.HOME, element: <HomePage /> },
      { path: ROUTES.CHECK_IN, element: <CheckInPage /> },
      { path: ROUTES.YOUR_PLAN, element: <YourPlanPage /> },
      { path: ROUTES.REFERRAL, element: <ReferralPage /> },
      { path: ROUTES.WORKOUT_WITH_US, element: <WorkoutWithUsPage /> },
      { path: ROUTES.TODAY_CLASSES, element: <TodayClassesPage /> },
    ],
  },

  /** HISTORY */
  {
    path: '/',
    children: [
      { path: '/', element: <Navigate to={ROUTES.HISTORY} /> },
      { path: ROUTES.HISTORY, element: <HistoryPage /> },
      { path: ROUTES.HISTORY_MEMBERSHIP_DETAIL, element: <MembershipContractDetailPage /> },
      { path: ROUTES.HISTORY_PT_DETAIL, element: <PTContractDetailPage /> },
      { path: ROUTES.HISTORY_FREEZE_DETAIL, element: <FreezeContractDetailPage /> },
    ],
  },

  /** ACCOUNT */
  {
    path: '/',
    children: [
      { path: ROUTES.ACCOUNT, element: <AccountPage /> },
      { path: ROUTES.ACCOUNT_TERM_CONDITION, element: <TermsAndConditionPage /> },
      { path: ROUTES.ACCOUNT_CONTACT, element: <ContactPage /> },
      { path: ROUTES.ACCOUNT_ABOUT, element: <AboutPage /> },
      { path: ROUTES.ACCOUNT_INFORMATION, element: <MyInformationPage /> },
      { path: ROUTES.ACCOUNT_VERIFICATION, element: <VerifyIdentityCardPage /> },
      { path: ROUTES.ACCOUNT_VERIFICATION_SUCCESS, element: <VerifyIdentitySuccessPage /> },
      { path: ROUTES.ACCOUNT_CHANGE_PHONE_NUMBER, element: <ChangePhoneNumberPage /> },
      { path: ROUTES.ACCOUNT_VERIFY_PHONE_NUMBER, element: <VerifyPhoneNumberPage /> },
      { path: ROUTES.ACCOUNT_PAYMENT_METHOD, element: <PaymentMethodPage /> },
      { path: ROUTES.ACCOUNT_PAYMENT_METHOD_CARD_DETAIL, element: <CardDetailPage /> },
    ],
  },

  /** MEMBERSHIP */
  {
    path: '/',
    children: [
      {
        path: ROUTES.MEMBERSHIP,
        element: <MembershipPage />,
      },
      {
        path: ROUTES.MEMBERSHIP_SELECT,
        element: <SelectPlansPage />,
      },
      {
        path: ROUTES.MEMBERSHIP_PAYMENT,
        element: <PaymentMembershipPage />,
      },
      {
        path: ROUTES.MEMBERSHIP_PAYMENT_RESULT,
        element: <PaymentResultPage />,
      },
      {
        path: ROUTES.MEMBERSHIP_FREEZE_PAYMENT,
        element: <PaymentFreezePage />,
      },
      {
        path: ROUTES.MEMBERSHIP_CLUB_DETAIL,
        element: <ClubDetailPage />,
      },
      {
        path: ROUTES.MEMBERSHIP_FREEZE_PAYMENT_RESULT,
        element: <PaymentFreezeResultPage />,
      },
    ],
  },

  /** PT */
  {
    path: '/',
    children: [
      { path: ROUTES.PT, element: <PTPage />, children: [] },
      { path: ROUTES.PT_SELECT, element: <ChoosePTPage /> },
      { path: ROUTES.PT_PLAN, element: <PTPlanPage /> },
      { path: ROUTES.PT_PAYMENT_PLAN, element: <PTPaymentPage /> },
      { path: ROUTES.PT_BOOKING, element: <PTBookingPage /> },
      { path: ROUTES.PT_CONTRACT, element: <PTContractPage /> },
      { path: ROUTES.PT_CONTRACT_PREVIEW_PDF, element: <ViewPTContractPage /> },
      { path: ROUTES.PT_CONTRACT_SIGN_PDF, element: <PTContractSignPage /> },
      {
        path: ROUTES.PT_PAYMENT_RESULT,
        element: <PTPaymentResultPage />,
      },
    ],
  },

  /** CLASS */
  {
    path: '/',
    children: [
      {
        path: ROUTES.CLASS,
        element: <ClassPage />,
      },
      {
        path: ROUTES.CLASS_DETAIL,
        element: <ClassDetailPage />,
      },
    ],
  },

  /** NOTIFICATION */
  {
    path: '/',
    children: [
      { path: ROUTES.NOTIFICATION, element: <NotificationPage /> },
      { path: ROUTES.NOTIFICATION_DETAIL, element: <NotificationDetailPage /> },
    ],
  },

  /** AUTH */
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: ROUTES.LADING, element: <LandingPage /> },
      { path: ROUTES.LOGIN, element: <LoginPage /> },
      { path: ROUTES.VERIFY_OTP, element: <VerifyOTPPage /> },
      { path: ROUTES.SIGN_UP, element: <SignUpPage /> },
    ],
  },

  {
    path: '/',
    children: [
      {
        path: ROUTES.SUPPORT,
        element: <SupportPage />,
      },
      {
        path: ROUTES.SUPPORT_REPORT,
        element: <ReportPage />,
      },
      {
        path: ROUTES.SUPPORT_REPORT_TYPE,
        element: <SupportReportPage />,
      },
    ],
  },
];

export default Router;
