import React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { formatDate, formatTime, generateClubName, isVN, showData } from 'utils';

export function PtConfirmBooking({ data }: { data: any }) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <p>
        {t('notify.hello')} <span className={styles.textBold}>{showData(data?.account?.name)}</span>
        ,
      </p>
      <p className={styles.block}>{t('notify.pt-confirm-session')}</p>

      <p className={styles.block}>{t('notify.session-is-confirmed')}:</p>
      <p>
        - {t('notify.pt-name')}:{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {showData(data?.pt?.name)}
        </span>
      </p>

      <p className={styles.block}>
        - {t('notify.start-at')}:{' '}
        <span className={styles.textBold}>{formatTime(data?.booking?.startDate)}</span>
      </p>
      <p>
        - {t('notify.date')}:{' '}
        <span className={styles.textBold}>{formatDate(data?.booking?.startDate)}</span>
      </p>

      <p className={styles.block}>
        - {t('notify.club')}:{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {generateClubName(isVN() ? data?.club?.nameVi : data?.club?.nameEn)}
        </span>
      </p>
      <p>
        - {t('notify.address')}:{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {showData(data?.club?.location)}
        </span>
      </p>
      <p className={styles.block}>{t('notify.please-keep-time')}</p>
    </div>
  );
}
