import styles from './styles.module.scss';
import NotifyService from 'services/notify.service';
import { formatDateTimeNow, isSuccessCode } from 'utils';
import { useEffect, useRef, useState } from 'react';
import { NotifyType } from 'types/notify.type';
import { useTranslation } from 'react-i18next';
import { useDirection } from 'hooks';
import { ROUTES } from 'constant';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NOTIFY_CONTENT } from 'constant/notify';
import { NotifyCard } from 'components';
import { Divider } from 'antd';
import LoadingDot from 'components/shared/LoadingDot';

export function NotificationContainer() {
  const { goTo } = useDirection();
  const { t } = useTranslation();
  const notifyService = new NotifyService();
  const [notifyList, setListNotify] = useState<NotifyType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const totalRecord = useRef(0);
  const page = useRef(1);
  const initNumberOfCard = Math.ceil((window.innerHeight - 150) / 78);

  const getListNotify = async () => {
    if (isLoadingMore) {
      return;
    }
    setIsLoadingMore(true);
    const response = await notifyService.getNotificationsPaging({
      page: page.current,
      limit: initNumberOfCard,
    });
    page.current = page.current + 1;
    const { code, data } = response;
    if (isSuccessCode(code)) {
      if (data?.data?.length > 0) {
        setListNotify(notifyList.concat(data?.data));
        totalRecord.current = data.total;
      }
    }
    setIsLoadingMore(false);
  };

  const initCallAPI = async () => {
    await getListNotify();
    setIsLoading(false);
  };

  useEffect(() => {
    initCallAPI();
  }, []);

  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <LoadingDot />
      ) : (
        <div id="scroll-target" className={styles.listWrapper}>
          <InfiniteScroll
            className={styles.notifyList}
            dataLength={notifyList.length}
            next={getListNotify}
            hasMore={notifyList.length < totalRecord.current}
            loader={<NotifyCard isSkeleton />}
            endMessage={<Divider plain>{t('title.no-more')}</Divider>}
            scrollableTarget="scroll-target"
          >
            {notifyList.map((notify) => {
              return (
                <NotifyCard
                  key={notify.id}
                  title={t(NOTIFY_CONTENT[notify.template.action]?.title)}
                  content={t(NOTIFY_CONTENT[notify.template.action]?.content)}
                  icon={NOTIFY_CONTENT[notify.template.action]?.icon}
                  createdAt={formatDateTimeNow(notify.createdAt)}
                  isRead={notify.isRead}
                  onClick={goTo(ROUTES.NOTIFICATION_DETAIL.replace(':notifyId', String(notify.id)))}
                />
              );
            })}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
}
