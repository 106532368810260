import React from 'react';
import styles from './styles.module.scss';
import { SecondaryButton } from 'components/button';
import { showData } from 'utils';
import { useTranslation } from 'react-i18next';

type Props = {
  text?: string;
  backgroundUrl?: string;
  onClickBtn?: () => void;
};

function DiscoverCard({ text = '', backgroundUrl = '', onClickBtn }: Props) {
  const { t } = useTranslation();

  return (
    <div
      className={styles.wrapper}
      style={{
        backgroundImage: `url(${backgroundUrl})`,
      }}
    >
      <div className={styles.cardWrapper}>
        <p className="color-white font-bold">{showData(text)}</p>
        <div className={styles.button}>
          <SecondaryButton
            text={t('button.discover')}
            variant="solid"
            iconUrl="/images/icon/play-media.svg"
            style={{
              color: 'var(--primary-color)',
            }}
            onClick={onClickBtn}
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(DiscoverCard);
