import { render } from 'react-dom';
import App from './App';
import './styles/global.scss';
import './utils/i18n';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from 'stores';
import { initPWAConfig, isPWA, saveAppHeight } from 'utils';
import { InstallOverlay } from 'components';

const rootElement = document.getElementById('root');
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}
saveAppHeight();
initPWAConfig();
render(
  <Provider store={store}>
    <BrowserRouter>{isPWA() ? <App /> : <InstallOverlay />}</BrowserRouter>
  </Provider>,
  rootElement,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
