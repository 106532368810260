import React, { useMemo } from 'react';
import styles from './styles.module.scss';
import { SecondaryButton } from 'components';
import { useTranslation } from 'react-i18next';
import { formatDate, formatTime } from 'utils';
import { COMMON_STATUS } from 'types';
import { CHIP_STATUS } from 'enum';
import { Skeleton } from 'antd-mobile/es/components/skeleton/skeleton';
import { ReactSVG } from 'react-svg';

type Props = {
  status?: COMMON_STATUS;
  date?: string;
  startTime?: string;
  endTime?: string;
  isSkeleton?: boolean;
  isExperience?: boolean;
  onCancel?: () => void;
  onReject?: () => void;
  onAccept?: () => void;
};

function HistoryBookingCard({
  date,
  endTime,
  startTime,
  status,
  onCancel,
  onReject,
  onAccept,
  isSkeleton = false,
}: Props) {
  const { t } = useTranslation();

  const icon = useMemo(() => {
    switch (status) {
      case CHIP_STATUS.WAITCUSCONFIRM:
        return '/images/icon/circle-menu.svg';
      case CHIP_STATUS.WAITPTCONFIRM:
        return '/images/icon/circle-menu.svg';
      case CHIP_STATUS.CANCELLED:
        return '/images/icon/cancel.svg';
      case CHIP_STATUS.COMPLETED:
        return '/images/icon/tick-circle.svg';
      case CHIP_STATUS.BOOKED:
        return '/images/icon/calendar.svg';
      case CHIP_STATUS.REJECTED:
        return '/images/icon/cancel.svg';
      default:
        return '/images/icon/circle-menu.svg';
    }
  }, [status]);

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.icon}>
        <ReactSVG src={icon} />
      </div>
      <div className={styles.information}>
        {isSkeleton ? (
          <Skeleton
            animated
            style={{
              height: 'var(--font-md)',
            }}
          />
        ) : (
          <p className="color-back font-bold font-md">{formatDate(date)}</p>
        )}
        <div className={styles.rangeTime}>
          <div className={styles.rangeItem}>
            <p className="color-back font-sm">{t('title.start-time')}</p>
            {isSkeleton ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-md)',
                }}
              />
            ) : (
              <p className="color-back font-bold font-md">{formatTime(startTime)}</p>
            )}
          </div>
          <div className={styles.rangeItem}>
            <p className="color-back font-sm">{t('title.end-time')}</p>
            {isSkeleton ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-md)',
                }}
              />
            ) : (
              <p className="color-back font-bold font-md">{formatTime(endTime)}</p>
            )}
          </div>
        </div>
      </div>

      <div className={styles.button}>
        {status === CHIP_STATUS.WAITPTCONFIRM && (
          <SecondaryButton
            text={t('button.cancel')}
            variant="outline-orange"
            onClick={onCancel}
            style={{
              width: '100px',
            }}
          />
        )}
        {status === CHIP_STATUS.WAITCUSCONFIRM && (
          <>
            <SecondaryButton
              text={t('button.accept')}
              variant="high-light-blue"
              onClick={onAccept}
              style={{
                width: '100px',
              }}
            />
            <SecondaryButton
              text={t('button.reject')}
              variant="outline-orange"
              onClick={onReject}
              style={{
                width: '100px',
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default React.memo(HistoryBookingCard);
