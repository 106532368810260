import { BottomSpace } from 'components';
import styles from './styles.module.scss';
import { Divider } from 'antd-mobile';

type Props = {};

export function ClassDetailContainer({}: Props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.videoWrapper}>
        <iframe
          width="100%"
          height="300"
          src="https://www.youtube.com/embed/bEirHkNcGQY?si=YWXOiqhGumHja3i9?autoplay=1&mute=0&loop=1"
        ></iframe>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <p className="color-black font-lg font-bold">Monday 3 July at 06:30</p>
          <p className="color-black font-sm">(30 minutes)</p>
        </div>
        <div className={styles.moreInfo}>
          <div className={styles.block}>
            <p className="color-grey font-sm font-light">Instructor</p>
            <p className="color-black font-md font-bold">Misa Le</p>
          </div>
          <div className={styles.block}>
            <p className="color-grey font-sm font-light">Available Slot</p>
            <p className="color-black font-md font-bold">10/12</p>
          </div>
          <div className={styles.block}>
            <p className="color-grey font-sm font-light">Studio</p>
            <p className="color-black font-md font-bold">Studio 3</p>
          </div>
          <div className={styles.block}>
            <p className="color-grey font-sm font-light">Type</p>
            <p className="color-black font-md font-bold">Mobility & Flexibility</p>
          </div>
        </div>
        <Divider className={styles.divider} />
        <div className={styles.term}>
          <div className={styles.block}>
            <p className="color-grey font-sm font-bold">Description</p>
            <p className="color-black font-xs font-light">
              Get ready to SWEAT with full body blasting exercises designed to challenge and change
              you! The exercises and work/rest periods vary from class to class but the intensity
              always challenges you to give your best that day!
            </p>
          </div>
          <div className={styles.block}>
            <p className="color-grey font-sm font-bold">Cancellations</p>
            <p className="color-black font-xs font-light">
              You are free to cancel this booking up to 4 hours before it starts. If you cancel
              within 4 hours this will be recorded as a no-show.
            </p>
          </div>
          <div className={styles.block}>
            <p className="color-grey font-sm font-bold">No-show Policy</p>
            <p className="color-black font-xs font-light">
              The New Gym operates with a “three strikes” non-attendance rule. Three no-shows within
              a 28 day rolling period will result in an 8 day booking ban. Any future classes you
              have booked will also be cancelled.
            </p>
          </div>
        </div>
      </div>
      <BottomSpace />
    </div>
  );
}
