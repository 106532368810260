/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  DATE_FORMAT_LOCALE_EN,
  DATE_FORMAT_LOCALE_VN,
  DEFAULT_EMPTY,
  DELAY_TRANSITION,
  TIME_FORMAT,
  UNIT_PRICE,
} from 'constant';
import { isNull, isUndefined } from 'lodash';
import moment from 'moment';
import i18n from './i18n';
import { LocalStorage, STORAGE_KEY } from './localStorage';
import { LANGUAGE } from 'enum';
import { ClubType } from 'types';
import { isIOS, isMobileOnly } from 'react-device-detect';

const Storage = new LocalStorage();

export const showData = (data: any) => {
  if (isNull(data) || isUndefined(data) || data.length === 0 || data === ' ') return DEFAULT_EMPTY;
  else return data;
};

export const formatPrice = (price: number, isUnit?: boolean) => {
  return (
    price
      .toLocaleString('it-IT', { style: 'currency', currency: 'vnd' })
      .replace(/[^0-9\.-]+/g, '') + (isUnit ? `${UNIT_PRICE}` : '')
  );
};

export const isSuccessCode = (code?: number) => {
  return code ? String(code).length === 4 : false;
};

export const delayNavigate = (callback: () => any, customDelay = DELAY_TRANSITION) => {
  // setTimeout(() => {
  //   callback();
  // }, customDelay);
  return callback(); //TODO: Temp fix
};

export const formatDate = (date?: string) => {
  return date ? moment(date).format(DATE_FORMAT) : '';
};

export const formatDateLocale = (date?: string) => {
  const inputDate = moment(date);
  if (i18n.language === 'vi') {
    inputDate.locale('vi');

    return date ? inputDate.format(DATE_FORMAT_LOCALE_VN) : '';
  } else {
    inputDate.locale('en-us');

    return date ? inputDate.format(DATE_FORMAT_LOCALE_EN) : '';
  }
};

export const formatTime = (date?: string) => {
  return date ? moment(date).format(TIME_FORMAT) : '';
};

export const formatDateTime = (date?: string) => {
  return date ? moment(date).format(DATETIME_FORMAT) : '';
};

export const formatDateTimeNow = (date?: string) => {
  const now = moment();
  const inputDate = moment(date);
  if (now.get('day') - inputDate.get('day') === 0) {
    if (i18n.language === 'vi') inputDate.locale('vi');
    else {
      inputDate.locale('en-us');
    }

    return date ? inputDate.fromNow() : '';
  } else return date ? moment(date).format(DATETIME_FORMAT) : '';
};

export const isNowDay = (date?: string) => {
  const now = moment();
  const inputDate = moment(date);

  return now.get('day') - inputDate.get('day') === 0;
};

export const getVersion = () => Storage.getStorageItem(STORAGE_KEY.APP_VERSION);

export const isPWA = () =>
  process.env.REACT_APP_DEV_MODE === 'true'
    ? true
    : window.matchMedia('(display-mode: standalone)').matches;

export const isVN = () => {
  return i18n.language === LANGUAGE.VN;
};

export const resetScroll = () => {
  const body = document.getElementById('main-body');
  if (body) {
    body.scrollTop = 0;
  }
};

export const resetScrollElement = (id: string) => {
  const body = document.getElementById(id);
  if (body) {
    body.scrollTop = 0;
  }
};

export const getImageUrl = (url: string) => {
  return `${process.env.REACT_APP_IMAGE_URL}?id=${url}`;
};

export const getVideoUrl = (url: string) => {
  return `${process.env.REACT_APP_IMAGE_URL}stream?id=${url}`;
};

export const getDistanceFromLatLonInKm = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km

  return d;
};

export const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180);
};

export const getLocation = (
  onInit: () => void,
  onSuccess: (position: GeolocationPosition) => void,
  onFail: (positionError: GeolocationPositionError) => void,
  onTimeOut: () => void,
) => {
  if (navigator.geolocation) {
    onInit && onInit();
    navigator.geolocation.getCurrentPosition(onSuccess, onFail);

    onTimeOut &&
      setTimeout(() => {
        onTimeOut();
      }, 500);
  } else {
    alert("This device doesn't support location!");
  }
};

export const dataURLtoFile = (dataUrl: string, filename: string) => {
  const arr = dataUrl.split(',');
  const mime = (arr[0].match(/:(.*?);/) || [])[1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const getMimeTypeFromBase64 = (base64: string) => {
  // RegExp to extract the MIME type part of the Base64 string
  const result = base64.match(/^data:(.*?);base64,/);

  if (result && result.length > 1) {
    return result[1];
  } else {
    return null; // or assume a default type, or throw an error, etc.
  }
};

export const base64ToBlob = (base64: string) => {
  const byteCharacters = atob(base64.split(',')[1]);
  const mineType = getMimeTypeFromBase64(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mineType || 'image/jpeg' });
};

export const blobToDataUrl = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(String(reader.result));
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const joinAddress = (club: ClubType | undefined) => {
  return club ? [club.address, club.district?.districtName, club.city?.cityName].join(', ') : '';
};

export const joinAddressNotify = (club: ClubType | undefined) => {
  return club ? [club.address, club.district, club.city].join(', ') : '';
};

export const formatVPPayReturnCard = (cardMask: string) => {
  return cardMask.slice(cardMask.length - 5).replaceAll('x', '*');
};

export const formatFullVPPayReturnCard = (cardMask: string) => {
  return cardMask.replaceAll('x', '*');
};

export const scrollToId = (id: string, offset = 0) => {
  const element = document.getElementById(id);
  const body = document.getElementById('main-body');

  if (element) {
    body?.scrollTo({
      top: element.offsetTop - offset,
      behavior: 'smooth',
    });
  }
};

export const generateClubName = (name: string | undefined) => {
  return `TNG ${showData(name)}`;
};

export const saveAppHeight = () => {
  if (isIOS && isMobileOnly) {
    const appHeight = Storage.getStorageItem(STORAGE_KEY.APP_HEIGHT);
    if (!appHeight) {
      Storage.setStorageItem(STORAGE_KEY.APP_HEIGHT, window.visualViewport?.height);
      document.documentElement.style.setProperty(
        '--app-height',
        `${window.visualViewport?.height}px`,
      );
    } else {
      document.documentElement.style.setProperty('--app-height', `${appHeight}px`);
    }
  } else {
    document.documentElement.style.setProperty('--app-height', `100vh`);
  }
};

export const initPWAConfig = () => {
  const appIcon =
    window.location.host === 'app.thenewgym.vn'
      ? '/images/logo/pwa-app-logo.jpg'
      : '/images/logo/pwa-app-logo-stg.png';
  const myDynamicManifest = {
    name: 'The New Gym',
    short_name: 'The New Gym',
    description: 'The New Gym',
    start_url: window.location.origin + '/landing',
    scope: window.location.origin + '/',
    display: 'standalone',
    theme_color: '#F2FAFC',
    background_color: '#F2FAFC',
    icons: [
      {
        src: appIcon,
        type: 'image/jpg',
        sizes: '32x32',
      },
    ],
  };
  const stringManifest = JSON.stringify(myDynamicManifest);
  const blob = new Blob([stringManifest], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(blob);
  const manifest = document.querySelector('#manifest-placeholder');
  const iconLink = document.getElementById('link-icon');
  const iconAppleLink = document.getElementById('link-apple-icon');
  if (manifest) {
    manifest.setAttribute('href', manifestURL);
  }
  iconLink?.setAttribute('href', appIcon);
  iconAppleLink?.setAttribute('href', appIcon);
};
