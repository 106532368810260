import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { BottomSpace, EmptyCard } from 'components';
import { PTContractType } from 'types';
import { UserService } from 'services';
import { delayNavigate, isSuccessCode, isVN } from 'utils';
import { isEmpty } from 'lodash';
import { useDirection } from 'hooks';
import { ROUTES } from 'constant';
import { useTranslation } from 'react-i18next';
import LoadingDot from 'components/shared/LoadingDot';
import PTTransactionHistoryCard from 'components/card/PTTransactionHistoryCard';

type Props = {};

export default function PTTab({}: Props) {
  const { t } = useTranslation();
  const userService = new UserService();
  const [contracts, setContracts] = useState<PTContractType[]>([]);
  const { goTo } = useDirection();
  const [isLoading, setIsLoading] = useState(false);

  const getListContract = async () => {
    setIsLoading(true);
    const response = await userService.getPTContracts({});
    const { data, code } = response;
    if (isSuccessCode(code)) {
      setContracts(data?.data);
    }
    delayNavigate(() => {
      setIsLoading(false);
    });
  };

  const parseInfo = (contract: PTContractType) => {
    return {
      id: contract?.id,
      isSuccess: !isEmpty(contract?.paymentCompletionDate),
      planName: isVN() ? contract?.ptPackage?.nameVi : contract?.ptPackage?.nameEn,
      endDate: contract?.endDate,
      totalSession: contract?.totalSessions,
      remainingSession: contract?.remainingSessions,
    };
  };

  useEffect(() => {
    getListContract();
  }, []);

  return (
    <div className={styles.tabWrapper}>
      <div className={styles.listContract}>
        {isLoading ? (
          <LoadingDot />
        ) : contracts?.length === 0 ? (
          <EmptyCard text={t('paragraph.no-contract-found')} />
        ) : (
          contracts.map((contract) => {
            const info = parseInfo(contract);

            return (
              <PTTransactionHistoryCard
                key={info?.id}
                isSuccess={info?.isSuccess}
                name={info?.planName}
                endDate={info?.endDate}
                totalSession={info?.totalSession}
                remainingSession={info?.remainingSession}
                status={contract.status}
                onClick={goTo(ROUTES.HISTORY_PT_DETAIL.replace(':ptContractId', String(info?.id)))}
              />
            );
          })
        )}

        <BottomSpace />
      </div>
    </div>
  );
}
